import Utils from "../../services/utils";
import { UsuarioResult, Usuario, UsuarioDispatch } from "./types";
import sessaoProvider from "../../provider/start";
import { ApiGestor } from "../../services/api"
import { async } from "@firebase/util";

class UsuarioProvider extends Utils implements UsuarioDispatch {
    public tipo: string = "cliente";
    public row: Usuario = {} as Usuario;
    public rows: Usuario[] = [];
    private msgError: string = "Ocorreu uma falha no modulo de usuários. Detalhes: ";
    public readonly dataCliente: Usuario = {
        tipo: this.tipo,
        _id: undefined, _rev: undefined,
        nome: "",
        sobrenome:"",
        apelido: "",
        data_cad: "",
        dia_pref: "",
        email: "",
        senha: "",
        whatsapp: "",
        pagamento_pref: "",
        endereco: {
            bairro: '',
            complemento: '',
            cidade: 'Conceição do Coité',
            latitude: -12.579738,
            longitude: -41.7007272,
            numero: '',
            rua: '',
        },
        resetsenhadt:"",
    }
    createUser = (): UsuarioResult => {
        let dt = this.formaDateIsoValue(Date().toString());
        return { status: "ok", row: { ...this.dataCliente, data_cad: dt } }
    }

    readUser = async (): Promise<UsuarioResult> => {
        try {
            let sessao = await sessaoProvider.getSessao();
            if (!this.isEmpty(sessao.row.user._id)) {
                return { status: "ok", row: sessao.row.user }
            } else {
                return { status: "error", mensagem: "Se identifique com o n° telefone ou e-mail cadastrado." }
            }
        } catch (error) {
            return { status: "error", mensagem: "Falha geral no read async do usuário. Detalhes: " + JSON.stringify(error) };
        }
    }

    getUser = async (id):Promise<UsuarioResult> => {
        try {
            let resp = await this.getDocRemote(id);
            if(resp.status === "ok" && resp.doc?._id){
                return {status:"ok", row:resp.doc as Usuario}
            } else {
                return {status:"error", mensagem:"Usuário não encontrado"}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral na função getUser em provider, detalhes: "+error}
        }
    }

    login = async (prmLogin: string): Promise<UsuarioResult> => {
        console.log("chamou login: " + prmLogin);
        try {
            if(prmLogin){
                let email = prmLogin.includes("@");
                let params, login:string = "";
    
                if(email){
                    params = prmLogin.toLowerCase();
                    login = "clientes/login_email";
                } else {
                    //let foneNumber = prmLogin.substring(prmLogin.length, prmLogin.length - 8)
                    
                    if(!isNaN(Number(prmLogin))) {
                        params = prmLogin;
                        login = "clientes/login_fone";
                    } else {
                        throw("Numero inválido! Use somente numeros.");
                    }
                }
                
    /*             let opt = {
                    params:params,
                    login:login,
                }; */
                //console.log("chamou changeRemote");
                try {
    /*                 let userFind = await this.changeRemote({
                        filter:"clientes/login",
                        include_docs:true,
                        live:false,
                        query_params:opt,
                        since:0
                    });                    
     */                
                    let findCli  = await this.queryRemote(login,{key:params,include_docs:true})
                    console.log("userFind", findCli);
                    if (findCli.rows.length > 0) {
                        //passar a copia do dataCliente usando ...
                        let docUser = this.alterRow(findCli.rows[0].doc,{...this.dataCliente});
                        return { status: "ok", row: docUser as Usuario };
                    } else {
                        return { status: "error", mensagem: "Não foi encontrado nenhum registro com esse login." }
                    }
        
                } catch (error) {
                    console.log("Error no changeRemote", error)
                    return { status: "error", mensagem: "Falha no login, Detalhes: " + JSON.stringify(error) }
                }
            } else {
                return { status: "error", mensagem: "Login inválido!" }    
            }            
        } catch (error) {
            return { status: "error", mensagem: "Falha no login, Detalhes: " + JSON.stringify(error) }
        }
    }

    salvar = async (row: Usuario): Promise<UsuarioResult> => {
        try {
            let result = await this.salvarRemoto(row);
            if (result.status) {
                return { status: "ok", row: result.row }
            } else {
                return { status: "error", mensagem: result.erro as string }
            }
        } catch (error) {
            return { status: "error", mensagem: "Falha ao salvar usuário, detalhes: " + JSON.stringify(error) }
        }
    }

    incluirNovoUsuario = async (row: Usuario): Promise<UsuarioResult> => {
        try {
            //verificar se ja existe 
            let existe = await this.login(row.whatsapp);
            if (existe.status === "error") {//se retornar error, não encontrou cadastro
                //verificar por email
                console.log("nao achou numero",row.whatsapp)
                existe = await this.login(row.email)
                if(existe.status === "error"){
                    console.log("nao achou email",row.email);
                    let result = await this.salvarRemoto(row);
                    if (result.status) {
                        return { status: "ok", row: result.row }
                    } else {
                        return { status: "error", mensagem: result.erro as string }
                    }    
                } else { //ja existe login com esse email
                    console.log("achou email",row.email);
                    return { status: "ok", row: existe.row }
                }
            } else { // ja existe login com esse whatsapp
                console.log("achou fone",row.whatsapp);
                return { status: "ok", row: existe.row }
            }
        } catch (error) {
            return { status: "error", mensagem: "Falha ao incluir novo usuário, detalhes: " + JSON.stringify(error) }
        }
    }

    loginValidate = (loginPw:string, row:Usuario) => {
        if(this.isEmpty(row.senha)){
            return true;
        } else {
            let senha = this.getHashSha256(loginPw);
            if(senha === row.senha){
                return true;
            } else {
                return false;
            }
        }
    }

    logOff = () => {
        //console.log("Method not implemented.");
    }

    sendEmail = async ({email,assunto,msg}):Promise<any> => {
        try {
            let opt = {
                email:email,
                assunto:assunto,
                mensagem:msg,
                head_from:"akisuafeira@akisuafeira.com.br"
            }
            let resp = await ApiGestor.post("/enviarEmail",opt);
            console.log("resp send email",resp)
            return resp.data
        } catch (error) {
            return {status:"error", msg:"Falha na função sendEmail, detalhes"+JSON.stringify(error)}
        }
    }

    resetSenha = async (row:Usuario):Promise<UsuarioResult> => {
        try {
            let dtNow:any = new Date();
            if(!this.isEmpty(row.resetsenhadt)){
                let dtReset:any = new Date(row.resetsenhadt);
                let difDt:any = Math.abs(dtNow - dtReset);//diff em milesegundos
                console.log("diff entre datas milesegundos",difDt);
                if(difDt < 3600000){//se for menor que 1hr
                    row.senha = "";
                    row.resetsenhadt ="";
                    const resp = await this.salvar(row);
                    if(resp.status === "ok"){
                        return {status:"ok", row:resp.row}
                    } else {
                        return {status:"error", mensagem:resp.mensagem }
                    }
                } else {
                    return {status:"error", mensagem:"O link não está mais válido, realize uma nova recuperação de senha."}
                }
            } else {
                return {status:"error", mensagem:"O link é Inválido! o usuario não está com o status para fazer o reset na senha. Realize uma nova recuperação de senha."}
            }           
        } catch (error) {
            return {status:"error", mensagem:"falha geral na função resetSenha em provider. Detalhes: "+error}
        }
    }

    setPassword(){};
    sendEmailRecSenha(email: string): void {
        
    }

    setValueRow(){};

    updateUser(cliente: Usuario): void {
        throw new Error("Method not implemented.");
    }
    setRowUser(value: any): void {
        throw new Error("Method not implemented.");
    }
    setShowFormUser(): void {
        throw new Error("Method not implemented.");
    }

    clearError(): void {
        
    }



}

const usuarioProvider = new UsuarioProvider()
export default usuarioProvider