import UtilsService from "../../services/utils";
import { PedidoItensDispatch,PedidoItensResult, ShowDataPedidoItens } from "./types";
import { Item } from "../item/types";
import itemProvider from "../item/index";
import itemCarProvider from "../itemCart/index";
import { showDataItemCart } from "../itemCart/types";

class PedidoItensProvider extends UtilsService implements PedidoItensDispatch {
    readPedidoItens = async (mov_id: string): Promise<PedidoItensResult> => {
        try {
            const rows: ShowDataPedidoItens[] = [];
            let qryName = "item-mov/by_mov_id"
            let opt = {
                key: mov_id,
                include_docs: true,
            };
            let result = await this.queryRemote(qryName, opt); 
            //console.log("result readPedidoItens", result);
            if (result.total_rows>0) {
                result.rows.map(item=>{
                    let row:ShowDataPedidoItens = {
                        itemCartData:item.value.item_mov,
                        itemData:item.doc as Item
                    }
                    rows.push(row);
                });
                return {status:"ok", rows:rows};
            } else {
                return {status:"error", mensagem:"Não encontrado itens para esse pedido."};
            }       
        } catch (error) {
            return {status:"error",mensagem:"Falha geral em PedidoItensProvider no readPedidoItens, detalhes: "+JSON.stringify(error)  }
        }
    }

    repeatItensPedido = async (itensSel:ShowDataPedidoItens[],manterPreco:boolean):Promise<PedidoItensResult> => {
        try {
            //buscando os itens no servidor
            console.log("itens sel repeatItensPedido: ",itensSel)
            let respItens = await itemProvider.readAll(true);
            if (respItens.status === "ok") {
                //cria a const para armazenar os itemCart 
                const newItensCart:showDataItemCart[] = [];
                const catalogo = respItens.rows;
                //iteraçao nos itens do pedido a ser adicionado no carrinho
                itensSel.map(item => {
                    //busca o item no catalogo que tenha estoque
                    let rowFind = catalogo?.find(row=>row._id === item.itemData._id);
                    //verifica se esta disponivel
                    if(rowFind !== undefined){
                        let respCreateItem = itemCarProvider.createItemCart(rowFind);
                        if(respCreateItem.status === "ok"){
                            //cria um modelo de itemCart
                            let itemCart = respCreateItem.row as showDataItemCart;
                            //altera de acordo com o pedido anterior
                            itemCart.itemCartData.qt = item.itemCartData.qt;
                            if(manterPreco){
                                itemCart.itemCartData.preco = item.itemCartData.preco;
                                itemCart.itemCartData.subtotal = item.itemCartData.subtotal;
                            } else {
                                itemCart.itemCartData.subtotal = item.itemCartData.qt * itemCart.itemCartData.preco;
                            }
                            itemCart.itemCartData.obs = item.itemCartData.obs;
                            
                            //adiciona na nova lista
                            newItensCart.push(itemCart);
                        }
                    }
                });
                return {status:"ok", rows:newItensCart}
            } else {
                return {status:"error", mensagem:respItens.mensagem}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no addItensPedidoToCart. Detalhes: "+JSON.stringify(error)};
        }
    }

    selectPedidoItem(_id:string){}

    selectAllItens(){}

    setSendToCart(parms: boolean): void {
        
    }
    
}

const pedidoItensProvider = new PedidoItensProvider();
export default pedidoItensProvider;