import { all, takeLatest } from "redux-saga/effects";

import { ItemActionsTypes } from "../../provider/item/types";
import { readAllItem, readItem, filtrar } from "../data/catalogo/async";

import { ItemCartActionsType } from "../../provider/itemCart/types";
import { createItemCart, addItemCartRow, readAllItensCart, remItemCart, updateCart, addMultItemCart, confirmarCart} from "../data/itemCart/async"

import { UsuarioActiosTypes } from "../../provider/usuario/types";
import { createUser, login, readUser, salvar, loginValidate,sendEmailRecSenha,getUser } from "../data/user/async";

import { SessaoActionsTypes } from "../../provider/start/types";
import { createSessao, getSessao, delSessao, clearCartSessao, setUserSessao, clearUserSessao, checkSessaoStarted, updateSessao, setInstallAppSessao, setUninstallAppSessao, createRegistroLGPD } from "../data/start/async";

import { PedidoActionsTypes } from "../../provider/pedido/types";
import { createPedido, sendPedido, readAllPedidoAndamento, readAllPedidos } from "../data/pedido/async";

import { PedidoItensActionsType } from "../../provider/pedidoItens/types";
import { readPedidoItens,repeatItensPedido } from "../data/pedidoItens/async";

import { MeioPagActionsTypes } from "../../modulos/meioPag/provider/types";
import { readAllMeioPag } from "../../modulos/meioPag/store/async";

import { CategoriaActionsTypes } from "../../modulos/categorias/provider/types"
import { readAllCategoria } from "../../modulos/categorias/store/async"

import { EstoqueActionsType } from "../../modulos/estoque/provider/types"
import { readAllEstoque } from "../../modulos/estoque/store/async"

import { KitPromocaoActionsType } from "../../modulos/kitpromocao/provider/types"
import { readAllKitPromocao } from "../../modulos/kitpromocao/store/async"

import { tipoEntregaEffects } from "../../modulos/tipoEntregas/store/async"

export default function* rootSaga() {
    return yield all([
        //CATALOGO
        takeLatest(ItemActionsTypes.READ_ITEM, readItem),
        takeLatest(ItemActionsTypes.READ_ALL_ITENS, readAllItem),
        takeLatest(ItemActionsTypes.FILTRAR_ITENS, filtrar),

        //ITEM_CART
        takeLatest(ItemCartActionsType.CREATE_ITEMCART, createItemCart),
        takeLatest(ItemCartActionsType.ADD_ITEMCART_ROWS, addItemCartRow),
        takeLatest(ItemCartActionsType.READ_ALL_ITENSCART, readAllItensCart),
        takeLatest(ItemCartActionsType.REM_ITEMCART_ROWS, remItemCart),
        takeLatest(ItemCartActionsType.UPDATE_CART, updateCart),
        takeLatest(ItemCartActionsType.ADD_MULT_ITEMCART_ROWS, addMultItemCart),
        takeLatest(ItemCartActionsType.CONFIRMA_CART, confirmarCart),
        
        //USUARIO
        takeLatest(UsuarioActiosTypes.CREATE_USUARIO, createUser),
        takeLatest(UsuarioActiosTypes.LOGIN, login),
        takeLatest(UsuarioActiosTypes.LOGIN_VALIDATE, loginValidate),
        takeLatest(UsuarioActiosTypes.READ_USUARIO, readUser),
        takeLatest(UsuarioActiosTypes.SALVAR, salvar),
        takeLatest(UsuarioActiosTypes.SEND_EMAIL, sendEmailRecSenha),
        takeLatest(UsuarioActiosTypes.GET_USER, getUser),

        //START
        takeLatest(SessaoActionsTypes.SESSAO_STARTED, checkSessaoStarted),
        takeLatest(SessaoActionsTypes.CREATE_SESSAO, createSessao),
        takeLatest(SessaoActionsTypes.GET_SESSAO, getSessao),
        takeLatest(SessaoActionsTypes.DEL_SESSAO, delSessao),
        takeLatest(SessaoActionsTypes.SET_USER_SESSAO, setUserSessao),
        takeLatest(SessaoActionsTypes.CLEAR_USER_SESSAO, clearUserSessao),
        takeLatest(SessaoActionsTypes.CLEAR_CART_SESSAO, clearCartSessao),
        takeLatest(SessaoActionsTypes.UPDATE_SESSAO, updateSessao),
        takeLatest(SessaoActionsTypes.SET_INSTALL_APP_SESSAO, setInstallAppSessao),
        takeLatest(SessaoActionsTypes.SET_UNINSTALL_APP_SESSAO, setUninstallAppSessao),
        takeLatest(SessaoActionsTypes.CREATE_LGPD, createRegistroLGPD),

        //PEDIDO
        takeLatest(PedidoActionsTypes.CREATE_PEDIDO, createPedido),
        takeLatest(PedidoActionsTypes.SEND_PEDIDO, sendPedido),
        takeLatest(PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO, readAllPedidoAndamento),
        takeLatest(PedidoActionsTypes.READ_ALL_PEDIDOS, readAllPedidos),

        //PEDIDO_ITENS
        takeLatest(PedidoItensActionsType.READ_PEDIDO_ITENS, readPedidoItens),
        takeLatest(PedidoItensActionsType.REPEAT_ITENS_PEDIDO, repeatItensPedido),

        //MEIOS DE PAGAMENTO
        takeLatest(MeioPagActionsTypes.READ_ALL_MEIO_PAG, readAllMeioPag),

        //CATEGORIA
        takeLatest(CategoriaActionsTypes.READ_ALL_CATEGORIA, readAllCategoria),        

        //ESTOQUE
        takeLatest(EstoqueActionsType.READ_ALL, readAllEstoque),        

        //KIT PROMOCAO
        takeLatest(KitPromocaoActionsType.READ_ALL, readAllKitPromocao),        

        ...tipoEntregaEffects
    ]);
}