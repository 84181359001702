import {GlobalResult,GlobalStates,ColumnTable } from "../../../services/globalTypes";

export interface Categoria {
    readonly _id:any;
    readonly _rev:any;
    tipo:string;//tipo doc
    descricao:string;
    imagem:string;
    categoria_pai:{
        _id:any,
        descricao:string
    }
}

export interface CategoriaResult extends GlobalResult {
    row?:Categoria;
    rows?:Categoria[];
}

export interface CategoriaState extends GlobalStates<Categoria> {
    readonly row:Categoria;
    readonly rows:Categoria[];
    readonly rowsSelected:Categoria[];
}

export interface CategoriaColumnsType extends ColumnTable{}

export interface CategoriaDispatch {
    createCategoria: () => void
    readAllCategorias():void;
    updateCategoria: (row:Categoria) => void
    delCategoria:(row:Categoria) => void    
    addCatSelected:(row:Categoria) => void
    delSelected:(idx:number) => void 
    clearErroCategoria: ()=> void
    setRowCategoria: (row:Categoria)=>void
}

export enum CategoriaActionsTypes {
    //CREATE
    CREATE_CATEGORIA = "@CreateCategoria/CREATE_CATEGORIA",
    CREATE_CATEGORIA_SUCCESS = "@CreateCategoriaSuccess/CREATE_CATEGORIA_SUCCESS",
    CREATE_CATEGORIA_FAILURE = "@CreateCategoriaFailure/CREATE_CATEGORIA_FAILURE",

    //READ
    READ_ALL_CATEGORIA = "@ReadAllCategoria/READ_ALL_CATEGORIA",
    READ_ALL_CATEGORIA_SUCCESS = "@ReadAllCategoriaSuccess/READ_ALL_CATEGORIA_SUCCESS",
    READ_ALL_CATEGORIA_FAILURE = "@ReadAllCategoriaFailure/READ_ALL_CATEGORIA_FAILURE",

    //UPDATE
    UPDATE_CATEGORIA = "@UpdateCategoria/UPDATE_CATEGORIA",
    UPDATE_CATEGORIA_SUCCESS = "@UpdateCategoriaSuccess/UPDATE_CATEGORIA_SUCCESS",
    UPDATE_CATEGORIA_FAILURE = "@UpdateCategoriaFailure/UPDATE_CATEGORIA_FAILURE",

    //DELETE
    DELETE_CATEGORIA = "@DeleteCategoria/DELETE_CATEGORIA",
    DELETE_CATEGORIA_SUCCESS = "@DeleteCategoriaSuccess/DELETE_CATEGORIA_SUCCESS",
    DELETE_CATEGORIA_FAILURE = "@DeleteCategoriaFailureDELETE_CATEGORIA_FAILURE",

    //ADD ROW SELECTED
    ADD_CAT_SELECTED = "@addCatSelected/ADD_CAT_SELECTED",

    //DELETE Selected
    DELETE_CAT_SELECTED = "@DeleteCatSelected/DELETE_CAT_SELECTED",


    SET_ROW_CATEGORIA = "@setRowCategoria/SET_ROW_CATEGORIA",

    SET_SHOW_FORM = "@setShowForm/SET_SHOW_FORM",

    CLEAR_ERROR_CATEGORIA = "@clearErrorCategoria/CLEAR_ERROR_CATEGORIA"

}
