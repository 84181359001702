import { GlobalResult,ColumnTable,ReadAllGlobalInterface } from "./globalTypes";

import Utils from "./utils";


export default class Provider<T> extends Utils{
    protected nameModulo:string;
    protected rows: T[];
    private newData:T;
    protected colunsDesktop:ColumnTable[];


    constructor(
        nameModulo:string,
        newData:T,
        colunsDesktop:ColumnTable[],
        ){
        super();
        this.nameModulo = nameModulo
        this.rows = []
        this.newData = newData;
        this.colunsDesktop = colunsDesktop;
    }

    getColunsDesktop = () => {
        return this.colunsDesktop;
    }


    create = (prm?:T):GlobalResult => {

        const row:T = {
            ...this.newData,
            ...prm,
            tipo:this.nameModulo,
        }
        return {status:"ok",row:row}
    }

    readAll = async (
        prm:ReadAllGlobalInterface
    ):Promise<GlobalResult> => {

        try {
            if(prm !== undefined){
                switch (prm.type) {
                    case "selector":
                        let result = await this.findRemote(prm.prmSelector as any);
                        this.rows = result.docs as unknown as T[];
                        return {status:"ok", rows:this.rows}                    
                    case "view":
                        let resp = await this.queryRemote(prm.view?.qryName, prm.view?.opt);
                        this.rows = resp.rows as unknown as T[];
                        return {status:"ok", rows:this.rows}                    
                }    
            } else {
                let opt = {
                    selector:{
                        tipo:this.nameModulo
                    }
                }
                let result = await this.findRemote(opt);
                this.rows = result.docs as unknown as T[];
                return {status:"ok", rows:this.rows}
            }
        } catch (error) {
            return {status:"error", mensagem:"Ocorreu uma falha na estrutura da função readAll no modulo "+this.nameModulo+". Detalhes "+ JSON.stringify(error)}
        }
    }

    update = async (row: T): Promise<GlobalResult> => {

        try {
            let result = await this.salvarRemoto(row);
            if (result.status) {
                return {status:"ok",row:result.row as unknown as T};
            } else {
                return {status:"error", mensagem:result.erro as string}
            }
        } catch (error) {
            console.log("error update provider",error)
            return {status:"error", mensagem:"Ouve uma falha na função update no modulo "+this.nameModulo+". Detalhes:"+JSON.stringify(error)}
        }
    }

    del = async(row: T): Promise<GlobalResult>   => {
        try {
            let result = await this.deleteDataRemote(row);
            if (result.ok) {
                return {status:"ok"};
            } else {
                return {status:"error", mensagem:"Ocorreu uma falha, o registro do modulo "+this.nameModulo+" não foi deletado."};
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função del no modulo "+this.nameModulo+". Detalhes: "+JSON.stringify(error)}
        }
    }

    filtrar = (value:any) : GlobalResult => {
        try {
            const rowsFilter = this.filterAllArray(this.rows,value);
            if (rowsFilter.ok) {
                return { status:"ok", rows:rowsFilter.data }
            } else {
                return { status:"error", mensagem:"Ocorreu uma falha na consulta no modulo "+this.nameModulo+". Detalhes: "+rowsFilter.mgs }
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função filtrar no modulo "+this.nameModulo+". Detalhes:"+JSON.stringify(error)}
        }
    }   
    setRow = (row:T) => {
        try {
            const alter:T = {
                ...this.newData,
                ...row,
            }

/*             const validFields = {...this.newData};//copiar o objeto, e não referenciar
            for (const key in row) {
                if (Object.prototype.hasOwnProperty.call(row, key)) {
                    validFields[key] = row[key];
                }
            }
 */            
            return alter
        } catch (error) {
            console.log("Erro ao setRow no modulo "+this.nameModulo+":"+error)
            return row
        }

    }

}
