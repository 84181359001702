import React from "react"

import {
    Container,
    Typography,
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    createStyles,
    Theme,
    TextField as TextFieldMaterial,
    Button,
    Divider,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";

import Modal from "../../components/Modal";

import { ShowDataPedido } from "../../provider/pedido/types";
import { MdArrowBack, MdSave } from "react-icons/md";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            height: "100%",
        },
        content: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
        },
        title: {
            flexGrow: 1,
        },
        container:{
            display:"flex",
            flex:1,
            marginTop:10,
            marginBottom:10,
        },
        form:{
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"space-between",
        }    
    })
);

interface PropsViewObsPedido{
    onClose:()=>void;
    rowPedido:ShowDataPedido;
    setValue:(row:ShowDataPedido)=>void;
}
function ViewObsPedido(props:PropsViewObsPedido){
    const { onClose,rowPedido,setValue } = props;
    const classes = useStyles();

    const salvarObs = (values:ShowDataPedido) => {
        setValue(values);
        onClose();
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={onClose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Informar Observações do Pedido
                        </Typography>
                    </Toolbar>
                </AppBar> 
                <Container className={classes.container}>
                    <Form
                        onSubmit={salvarObs}
                        initialValues={rowPedido}
                    >
                        {({
                            handleSubmit,
                            pristine,
                            submitting,
                            values,
                            form,
                            errors,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className={classes.form}
                            >
                                <Field
                                    name="pedido.obs"
                                >
                                    {props => (
                                        <TextFieldMaterial
                                            fullWidth
                                            label='Observações'
                                            name={props.input.name}
                                            value={props.input.value}
                                            multiline
                                            minRows={5}
                                            variant="outlined"
                                            onChange={props.input.onChange}
                                        />
                                    )}
                                </Field>     

                                <Button
                                    variant={
                                        "contained"
                                    }
                                    startIcon
                                    color={"primary"}
                                    fullWidth={true}
                                    type="submit"
                                    disabled={
                                        submitting || pristine || !errors
                                    }
                                >
                                    <MdSave
                                        size={24}
                                    />
                                    Salvar
                                </Button>                                


                            </form>
                        )}
                    </Form> 
                </Container>                                   
            </div>
        </div>
    )
}

export default ViewObsPedido

interface PropsViewObsPedidoModal {
    handleClose:()=>void;
    open:boolean;
    setValue:(values:ShowDataPedido)=>void;
    rowPedido:ShowDataPedido;
}

export function ViewObsPedidoModal (props: PropsViewObsPedidoModal) {
    const { handleClose,open,setValue,rowPedido } = props;
  
    return (
      <Modal
        component={
            <ViewObsPedido 
                onClose={handleClose} 
                setValue={setValue}
                rowPedido={rowPedido}
            />
        }
        handleClose={handleClose}
        open={open}
      />
    );
}