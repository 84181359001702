import React, {useEffect} from "react"

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../store";
import * as CategoriaActions from "../store/actions";
import { Categoria, CategoriaDispatch, CategoriaState } from "../provider/types"

import { createStyles, makeStyles, Theme, Avatar, Typography, Chip } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainerCategoria:{
        display: "flex",
        flexDirection: "column",
        margin: 5,
    },
    containerCategorialist:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        overflowX:"scroll",
        padding:5,
    },
    containerCategoria:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "3px",
        alignItems: "center",
        cursor:'pointer',
    },
    imgCategoria:{
        height:"60px",
        width:"60px",
        borderRadius:30,
        borderStyle:"solid",
        border:1,
        borderColor:"#333",
    },

    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },    

    containerFilterCatetgoria:{
        display:'flex',
        flex:1,
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
        width:"100%",
    },    
  })
);
interface CategoriaProps{
    row:Categoria;
    onClick:(row:Categoria)=>void
}
const PATH_IMG = "https://akisuafeira.com.br/ws/App/Upload/";
function CategoriaView(props:CategoriaProps){
    const { _id,descricao,imagem } = props.row; 
    const classes = useStyles();
    return(
        <div className={classes.containerCategoria} onClick={()=>props.onClick(props.row)}>
            <Avatar src={PATH_IMG + imagem} alt={descricao} className={classes.large} />
            <Typography variant="subtitle2" color="primary">{descricao}</Typography>
        </div>
    )
}
interface CategoriaContainerProps{
    categoriaState:CategoriaState;
    categoriaActions:CategoriaDispatch;
    handleFilterItemCatAdd:(_idCat:string)=>void
    handleFilterItemCatRem:(idx:number)=>void
}
function ContainerCategoria(props:CategoriaContainerProps){
    const { rows, loading, error, total_rows, rowsSelected } = props.categoriaState;
    const { readAllCategorias, addCatSelected,delSelected } = props.categoriaActions
    const classes = useStyles();

    useEffect(()=>{
        readAllCategorias()
    },[total_rows])

    const handleAddCategoria = (cat:Categoria) => { 
        addCatSelected(cat)
        props.handleFilterItemCatAdd(cat._id);
    }

    const handleRemCategoria = (idx:number) => {
        delSelected(idx);
        props.handleFilterItemCatRem(idx);
    }
    return(
        <div className={classes.mainContainerCategoria}>
            <div className={classes.containerCategorialist}>
                {rows.map((cat,i)=>(
                    <CategoriaView 
                        key={cat._id}
                        row={cat}
                        onClick={handleAddCategoria}
                    />
                ))}

            </div>
            <div className={classes.containerFilterCatetgoria}>
            {
                rowsSelected.map((cat,i)=>(
                <Chip
                    key={cat._id}
                    variant="outlined"
                    size="medium"
                    avatar={<Avatar alt={cat.descricao} src={PATH_IMG + cat.imagem} />}
                    label={cat.descricao}
                    onDelete={()=>handleRemCategoria(i)}
                />
                ))
            }
            </div>    
        </div>    
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    categoriaState: state.categoria
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    categoriaActions: bindActionCreators(CategoriaActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerCategoria);

