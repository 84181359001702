import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import * as PedidoActions from "../../store/data/pedido/actions";
import * as SessaoActions from "../../store/data/start/actions";
import { makeStyles, createStyles, Toolbar, AppBar, IconButton, Typography, Container, Card, CardHeader, Badge, Avatar, LinearProgress, Chip, CardContent, Slide, Slider, CardActions, Button } from "@material-ui/core";
import { FaClipboardList, FaShoppingBag, FaHome, FaClipboardCheck } from "react-icons/fa";
import {
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import { PedidoProps, PedidoState, PedidoDispatch } from "../../provider/pedido/types";
import pedidoProvider from "../../provider/pedido";
import { SessaoState, SessaoDispatch } from "../../provider/start/types";
const dataAgora = new Date()
const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    rootCard: {
        maxWidth: "100%",
        marginTop: 3,
    },
    contentFechamento: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexGrow: 1,

    }
}));
interface Props {
    pedidoState: PedidoState,
    pedidoActions: PedidoDispatch,
    sessaoState: SessaoState,
    sessaoActions: SessaoDispatch,
}

const PROGRESS = [
    { value: 0, label: "inicio" },
    { value: 20, label: "confirmado" },
    { value: 50, label: "preparando" },
    { value: 80, label: "entrega" },
    { value: 100, label: "Fim" },
]

function PedidosAtuais(props: Props) {
    const { error, loading, rows, total_rows, msg } = props.pedidoState;
    const { sessaoStarted } = props.sessaoState;
    const { checkSessaoStarted } = props.sessaoActions
    const [hora, setHora] = useState(dataAgora.getHours());
    const classes = useStyles();
    const routerHistory = useHistory();
    const { url } = useRouteMatch();

    useEffect(() => {
        if (!sessaoStarted) {
            checkSessaoStarted()
        }
    }, [total_rows])

    const getProgressValue = (status: string) => {
        switch (status) {
            case "iniciado":
                return 0
            case "confirmado":
                return 20
            case "preparando":
                return 50
            case "entrega":
                return 80
            case "finalizado":
                return 100
            default:
                return 0
        }
    }
    const getValueText = (value: number) => {
        return PROGRESS[value].label
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position={"sticky"} >
                    <Toolbar>
                        <FaClipboardCheck size={25} />
                        <Typography variant="h6" className={classes.title}>
                            Situação do seu pedido
                        </Typography>
                    </Toolbar>
                </AppBar>
                {loading && (
                    <LinearProgress
                        style={{ margin: 3, minWidth: "max-content" }}
                        color="primary"
                    ></LinearProgress>
                )}
                <Container>
                    <Typography variant={'body1'} color={'primary'}>
                        {sessaoStarted&&(
                            props.sessaoState.row.user.nome
                        )}
                    </Typography>
                    {rows.length === 0 && !loading &&(
                        <Card>
                            <CardContent>
                                <Typography variant={'h6'} color={'textSecondary'}>
                                    Sem pedidos em andamento.
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    {rows.map(row => {
                        return (
                            <Card className={classes.rootCard} key={row.pedido._id}>
                                <CardHeader
                                    avatar={<Chip icon={<FaShoppingBag />} label={row.pedido.codigo} color="primary" />}
                                    title={
                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                            <Typography>{row.pedido.status}</Typography>
                                            <Typography>{!row.pedido.fechamento ? 0 : pedidoProvider.formatMoney(row.pedido.fechamento.total)}</Typography>
                                        </div>
                                    }
                                    subheader={pedidoProvider.getDataText(row.pedido.data_cad)}
                                />
                                <CardContent style={{ width: "90%" }}>
                                    <Typography variant="subtitle2">Andamento do pedido:</Typography>
                                    <Slider
                                        marks={PROGRESS}
                                        step={null}
                                        getAriaLabel={getValueText}
                                        value={getProgressValue(row.pedido.status)}
                                    />
                                </CardContent>
                                <CardActions disableSpacing>
                                    {row.pedido.entrega&&(
                                    <div className={classes.contentFechamento}>
                                        <Typography variant="caption">
                                            Previsão para entrega, {row.pedido.entrega.dia}  a partir das: 
                                        </Typography>
                                        <Typography variant={"h6"}>
                                            {row.pedido.entrega.hora}
                                        </Typography>
                                    </div>
                                    )}
                                </CardActions>
                            </Card>

                        )
                    }

                    )}
                    <Button
                        onClick={() => routerHistory.push(`/catalogo/itens`)}
                        variant="contained"
                        color={"primary"}
                        fullWidth
                        startIcon
                        style={{ marginTop: 10 }}
                    >
                        <FaHome />
                        Ver Catalogo
                    </Button>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    pedidoState: state.pedido,
    sessaoState: state.start,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    pedidoActions: bindActionCreators(PedidoActions, dispatch),
    sessaoActions: bindActionCreators(SessaoActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(PedidosAtuais);
