import { combineReducers } from "redux";
import catalogo from "./catalogo";
import itemCart from "./itemCart";
import user from "./user";
import start from "./start";
import pedido from "./pedido";
import pedidoItens from "./pedidoItens";
import meioPag from "../../modulos/meioPag/store"
import categoria from "../../modulos/categorias/store"
import estoque from "../../modulos/estoque/store"
import kitpromocao from "../../modulos/kitpromocao/store"
import tipoentrega from "../../modulos/tipoEntregas/store"


export default combineReducers({
    catalogo,
    itemCart,
    user,
    start,
    pedido,
    pedidoItens,
    meioPag,
    categoria,
    estoque,
    kitpromocao,
    tipoentrega
});