import { action } from "typesafe-actions";
import { TipoEntregaActionsType, EntregaPedido } from "../provider/types";

export const create = (prm?:any)=>{};
export const update = (row:any)=>{} ;
export const del = (row:any)=>{};

export const readAll = (prm?:any) => action(TipoEntregaActionsType.READ_ALL, { prm })
export const readAllSuccess = (rows:EntregaPedido[]) => action(TipoEntregaActionsType.READ_ALL_SUCCESS, { rows })
export const readAllFailure = (msg:string) => action(TipoEntregaActionsType.READ_ALL_FAILURE, { msg })

export const read = (prm?:any) => {}


export const filtrar = (value:string) => action(TipoEntregaActionsType.FILTRAR, { value })
export const filtrarSuccess = (rows:EntregaPedido[]) => action(TipoEntregaActionsType.FILTRAR_SUCCESS, { rows })

export const setRow = (row:EntregaPedido)=>action(TipoEntregaActionsType.SET_ROW, {row});

export const clearError = ()=>action(TipoEntregaActionsType.CLEAR_ERROR);

export const setShowForm = () => action(TipoEntregaActionsType.SET_SHOW_FORM)