import { call, put } from "redux-saga/effects";
import * as PedidoActions from "./actions";
import * as SessaoActions from "../start/actions"
import { PedidoResult, ShowDataPedido } from "../../../provider/pedido/types";
import pedidoProvider from "../../../provider/pedido";
import { showDataItemCart } from "../../../provider/itemCart/types";

export function* createPedido(actions){
    try {
        let resp:PedidoResult = yield call(pedidoProvider.createPedido, actions.payload.cliente, actions.payload.itens);
        if (resp.status === "ok") {
            yield put(PedidoActions.createPedidoSuccess(resp.row as ShowDataPedido));
            let itensCart:showDataItemCart[] = actions.payload.itens;
            let subtotal = itensCart.map(item=>item.itemCartData.subtotal).reduce((acc,cur)=>acc+cur);
            yield put(PedidoActions.setFechamentoPedido(0,0,subtotal,subtotal));

        } else {
            yield put(PedidoActions.createPedidoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(PedidoActions.createPedidoFailure("Falha geral no async createPedido. Detalhes: "+JSON.stringify(error)));
    }
}

export function* sendPedido(actions){
    try {
        let resp:PedidoResult = yield call(pedidoProvider.sendPedido, actions.payload.row);
        if (resp.status === "ok") {
            yield put(SessaoActions.clearCartSessao());
            yield put(PedidoActions.sendPedidoSuccess(resp.row as ShowDataPedido));
        } else {
            yield put(PedidoActions.sendPedidoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(PedidoActions.sendPedidoFailure("Falha geral no async sendPedido. Detalhes: "+JSON.stringify(error)));
    }
}

export function* readAllPedidoAndamento(actions){
    try {
        let resp:PedidoResult = yield call(pedidoProvider.readAllPedidoAndamento, actions.payload.cliente);
        if (resp.status === "ok") {
            yield put(PedidoActions.readAllPedidoAndamentoSuccess(resp.rows as ShowDataPedido[]));
            return true;
        } else {
            yield put(PedidoActions.readAllPedidoAndamentoFailure(resp.mensagem as string));
            return false;
        }
    } catch (error) {
        yield put(PedidoActions.readAllPedidoAndamentoFailure("Falha geral no async readAllPedidoAndamento. Detalhes: "+JSON.stringify(error)));
        return false;
    }
}

export function* readAllPedidos(actions){
    try {
        //console.log("call readAllPedidos");
        let resp:PedidoResult = yield call(pedidoProvider.readAllPedidos, actions.payload.cliente, actions.payload.data_ini, actions.payload.data_fim);
        if (resp.status === "ok") {
            //console.log("readAllPedidos success");
            yield put(PedidoActions.readAllPedidosSuccess(resp.rows as ShowDataPedido[]))
        } else {
            //console.log("readAllPedidos failure");
            yield put(PedidoActions.readAllPedidoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(PedidoActions.readAllPedidoFailure("Falha geral no async readAllPedidos. Detalhes: "+JSON.stringify(error)));
    }
}