import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, createStyles, List, ListItem, ListItemText, Typography, Divider, ListSubheader, Toolbar, Button, Container, ListItemSecondaryAction, AppBar, IconButton, TextField, ListItemAvatar, ListItemIcon } from "@material-ui/core";
import { MdArrowBack, MdEdit, MdMonetizationOn,MdContentCopy } from "react-icons/md";
import NumberFormat from 'react-number-format';
import { ShowDataPedido, MeioPagPedido } from "../../provider/pedido/types";
import { MeioPag } from "../../modulos/meioPag/provider/types";
import pedidoProvider from "../../provider/pedido/index";
import SelectMeiosPag from "../../modulos/meioPag/view/selecionar";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { IoMdArrowForward, IoMdSend, IoMdTrash } from "react-icons/io";
const useStyles = makeStyles(()=>createStyles({
    root:{
        display:"flex",
        /*flexGrow:1,*/
        height:"100%",
    },
    content:{
        display:"flex",
        flexDirection:"column",
        flexGrow:1,
    },
    title: {
        flexGrow: 1,
    },    
    container:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        flex:1,
        marginTop:10,
        marginBottom:10,
    },      
}));

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: number } }) => void;
    name: string;
  }
function NumberFormatCustom(props:NumberFormatCustomProps){
    const { inputRef, onChange, ...other } = props;
    return(
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values)=>{
                let value:number = values.value === '' || undefined ? 0 : values.floatValue as number;
                onChange({
                    target:{
                        name:props.name,
                        value: value,
                    }
                });
            }}
            thousandSeparator={'.'}
            decimalScale={2}
            decimalSeparator={','}
            allowNegative={false}
            allowedDecimalSeparators={['.',',']}
            isNumericString
            prefix="R$"
        />
    )
}



interface Props{
    pedido:ShowDataPedido;
    setValorPedido(valor:number, codigo:number, total:number):void;
    setFechamentoPedido(descontos:number,acrescimos:number,subtotal:number,total:number):void;
    addMeioPag(meioPag:MeioPagPedido):void;
    remMeioPag(idx:number):void;
    backView():void;
    nextView():void;
}
function Step2({pedido, setValorPedido, addMeioPag, remMeioPag, setFechamentoPedido,backView,nextView}:Props){
    const classes = useStyles();
    //const [totalPedido, setTotalPedido] = useState(0);
    const [subTotalPedido, setSubtotalPedido] = useState(pedido.itens.map(item=>item.itemCartData.subtotal).reduce((acc,cur)=>acc+cur));
    const [percDesc, setPercDesc] = useState(0);
    //const [descontoPedido, setDescontoPedido] = useState(0);
    const [openMeioPag, setOpenMeioPag] = useState(false);
    console.log("pedido",pedido);
    useEffect(()=>{
        setFechamentoPedido(
            pedido.pedido.fechamento.descontos,
            pedido.pedido.fechamento.acrescimos,
            pedido.pedido.fechamento.subtotal,
            Number((pedido.pedido.fechamento.subtotal-pedido.pedido.fechamento.descontos).toFixed(2))
        );

        if(pedido.pedido.fechamento.meios_pag.length === 0&& !openMeioPag)
            setOpenMeioPag(true);
    },[subTotalPedido])

    const calcDesc = (subTotal:number, desc:number) => {
        return desc>0? Number((subTotal * desc / 100).toFixed(2)) : 0;
    }
    const openSelectMeioPag = () => {
        setOpenMeioPag(true);
    };

    const handleChange = (valor:number, codigo:number) => {
        setValorPedido(valor, codigo, pedido.pedido.fechamento.total);
    };

    const onCloseSelectMeioPag =  (selected: MeioPag) => {
        if(selected.percDesc) {
            setPercDesc(selected.percDesc);
        } else {
            setPercDesc(0);
        }

        let desconto = calcDesc(pedido.pedido.fechamento.subtotal,selected.percDesc)
        let meiopag:MeioPagPedido = {...selected,
            valor:Number((pedido.pedido.fechamento.subtotal-desconto).toFixed(2)),
            troco:0,
        };
        setFechamentoPedido(desconto,0,pedido.pedido.fechamento.subtotal, Number((pedido.pedido.fechamento.subtotal - desconto).toFixed(2)))       
        addMeioPag(meiopag);
        setOpenMeioPag(false);
    };

    const removerPagamento = (idx:number) => {
        setPercDesc(0);
        setFechamentoPedido(0,0,pedido.pedido.fechamento.subtotal,pedido.pedido.fechamento.subtotal);
        remMeioPag(idx)
    }
    //console.log("pedido", pedido.pedido)
    return(
        <div className={classes.root}>
            <div className={classes.content}>
                <SelectMeiosPag open={openMeioPag} onClose={onCloseSelectMeioPag} />
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={backView}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Informe o Meio de Pagamento
                        </Typography>
                    </Toolbar>
                </AppBar>   
                <Container className={classes.container}>              
                    <div>
                        <List dense>
                            <>
                            {pedido.pedido.fechamento.descontos>0&&(
                            <ListItem dense>
                                <ListItemText 
                                    primary={<Typography variant={"caption"} color={"textSecondary"}>SubTotal Pedido:</Typography>}
                                    secondary={<Typography variant={"h6"} color={"textPrimary"}>{pedidoProvider.formatMoney(pedido.pedido.fechamento.subtotal)}</Typography>}
                                />
                                
                                <ListItemSecondaryAction>
                                    <Typography variant="caption" color="textSecondary">{percDesc}% de Desconto</Typography>
                                    <Typography variant="h6" color="secondary">-{pedidoProvider.formatMoney(pedido.pedido.fechamento.descontos)}</Typography>
                                </ListItemSecondaryAction>                    
                                
                            </ListItem>  
                                            
                            )}
                            <Divider/>
                            <ListItem dense>
                                <ListItemText 
                                    primary={<Typography variant={"caption"} color={"textSecondary"}>Total Pedido:</Typography>}
                                    secondary={<Typography variant={"h5"} color={"primary"}>{pedidoProvider.formatMoney(pedido.pedido.fechamento.total)}</Typography>}
                                />
                            </ListItem>  
                            {pedido.pedido.fechamento.meios_pag.length === 0&&(
                                <ListItem 
                                    button 
                                    onClick={()=>openSelectMeioPag()}
                                >
                                    <ListItemIcon>
                                        <MdMonetizationOn size={25}/>
                                    </ListItemIcon>                        
                                    <ListItemText 
                                        primary={<Typography variant={"body1"} color={"textPrimary"}>Informar Meio de Pagamento</Typography>}
                                    />
                                    <ListItemSecondaryAction>
                                        <IoMdArrowForward size={25} />
                                    </ListItemSecondaryAction>                          
                                </ListItem>                      
                            )} 
                            </>                
                        </List>

                    
                        <List dense>
                            {pedido.pedido.fechamento.meios_pag.map((pay,idx)=>(
                                <>
                                <ListItem key={idx}>
                                    <ListItemAvatar>     
                                        <IconButton 
                                            onClick={() => removerPagamento(idx) }
                                            color="secondary"
                                            edge="start"
                                        >
                                            <IoMdTrash />
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText primary={pay.descricao} secondary={pay.forma.descricao}/>
                                    <ListItemSecondaryAction>
                                        {pay.codigo > 0 &&(
                                            <Typography variant={"h6"} color={"primary"}>
                                                {pedidoProvider.formatMoney(pay.valor as number)}
                                            </Typography>
                                        )}
                                        {pay.descricao === "PIX"&&(
                                            <CopyToClipboard
                                                text={pay.forma.descricao}
                                                onCopy={()=>console.log("Pix Copiado!")}
                                            >
                                                <IconButton 
                                                    color="default"
                                                    edge="end"
                                                    title="Copiar Pix"
                                                >
                                                    <MdContentCopy />
                                                </IconButton>    
                                            </CopyToClipboard>                                    

                                        )}
                                    </ListItemSecondaryAction>
                                    
                                </ListItem>

                                {pay.forma.permiteTroco && (
                                    <>
                                    <ListItem>
                                        <TextField
                                            fullWidth
                                            variant={"outlined"}
                                            label="Valor em dinheiro:"
                                            value={pay.valor}
                                            onChange={(event)=>handleChange(Number(event.target.value),pay.codigo)}
                                            name='dinheiro'
                                            id="text-dinheiro"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom as any
                                            }}
                                        />
                                    </ListItem>
                                    {pay.troco as number > 0 ?(
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant={"caption"} color={"textSecondary"}>Troco para {pedidoProvider.formatMoney(pay.valor)}</Typography>}
                                                secondary={<Typography variant={"h6"} color={"secondary"}>{pedidoProvider.formatMoney(pay.troco as number)}</Typography>}
                                            />
                                        </ListItem>
                                    ): (
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant={"caption"} color={"textSecondary"}>Sem troco. <small>(Caso precise de troco, informe o valor que irá pagar acima.)</small></Typography>}
                                            />
                                        </ListItem>

                                    )}        

                                    </>
                                )}
                                </>
                            ))}
                        </List>
                    </div>
                    <Button
                        variant={"contained"}
                        startIcon
                        color={"primary"}
                        fullWidth={true}
                        onClick={nextView}
                        disabled={pedido.pedido.fechamento.meios_pag.length === 0}
                    >
                        <IoMdSend
                            size={24}
                        />
                            Concluir e Enviar
                    </Button>                      
                </Container>

            </div>
        </div>
    )
}

export default Step2;