import { createStyles, makeStyles, Theme, fade } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerButtons:{
      display:'flex',
      flexGrow:1,
      justifyContent:'flex-end',
    },
    [theme.breakpoints.down("md")]:{
      title: {
        display:'none',
        flexGrow: 1,
        marginLeft: theme.spacing(3)
      },  
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(3)
    },    
    brand:{
      flexGrow: 1,
      maxWidth:50,
      marginRight:10,
    },

    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },

  })
);

export default useStyles;