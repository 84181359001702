import { GlobalStates, GlobalResult } from "../../services/globalTypes";
import { Usuario } from "../usuario/types";
import { showDataItemCart } from "../itemCart/types";
import { MeioPag } from "../../modulos/meioPag/provider/types"
import { EntregaPedido } from "../../modulos/tipoEntregas/provider/types";
/**
 * Data types
 * 
 */
export interface Config{
    _id:any;
    _rev:any;
    dados_empresa:object;
    cod_mov_inc:number;
    qt_dig_cod:number;
    tipoMovPedidoDefault:TipoMovimentacao,
    tipoMovVendaDefault:TipoMovimentacao,
    config_entrega:{
        dias:[{day:number,dia:string}],
        horarios:[string]
    }
}

export enum TipoPedido {
    PEDIDO = "PEDIDO",
}
export enum StatusPedido {
    iniciado = "iniciado",
    confirmado = "confirmado",
    preparando = "preparando",
    entrega   = "entrega",
    finalizado = "finalizado",
}

export interface PedidoData{
    _id:any;
    _rev:any;
    tipo:string;
    tipo_mov:TipoPedido;//
    codigo:string;
    cliente_id:string;
    data_cad:string;
    status:StatusPedido;
    data_status:string;
    data_prev_ent:string;
    fechamento:FechamentoPedido;
    movimentacao:TipoMovimentacao;
    entrega:{
        descricao:string,
        detalhes:string,
        dia:string,
        hora:string,
        custo:number,
        valor_min:number,
    };
    obs:string;
    local:'loja'|'app';
}

interface FechamentoPedido {
    descontos:number;
    acrescimos:number;
    subtotal:number;
    total:number;
    meios_pag:MeioPagPedido[];
}

export interface MeioPagPedido extends MeioPag{
    valor:number;
    troco?:number;
}

export interface TipoMovimentacao {
    _id:any;
    _rev:any;    
    tipo:'tipo-mov';
    descricao:TipoMovDesc;
    mov_estoque:boolean;//informa se o tipo movimenta estoque
    detalhes:string;
    direcao_estoque:"E"|"S";//se é entrada ou saida de estoque
    gera_mov_fin:boolean;//se gera movimentacao financeira
    alterar_item:boolean;//se altera o cadastro do item
}

export enum TipoMovDesc {
    PEDIDO = "PEDIDO",
    VENDA = "VENDA",
    COMPRA = "COMPRA",
    PERDA = "PERDA",
    CANCELADO = "CANCELADO",
}



export interface ShowDataPedido {
    pedido:PedidoData;
    cliente:Usuario;
    itens:showDataItemCart[];
}


export interface PedidoResult extends GlobalResult {
    rows?:ShowDataPedido[];
    row?:ShowDataPedido;
}



 /**
  * State Types
  */

 export interface PedidoState extends GlobalStates<ShowDataPedido> {
    row:ShowDataPedido,
    rows:ShowDataPedido[],
    sended:boolean,
}

/**
 * Actions
 */

export interface PedidoDispatch {
    createPedido(cliente:Usuario,itens:showDataItemCart[]):void;
    readAllPedidos(cliente:Usuario,data_ini:string, data_fim:string): void;
    addMeioPag(meioPag:MeioPag):void;
    setValorFechamento(valor:number, codigo:number, total:number): void;
    setFechamentoPedido(descontos:number,acrescimos:number,subtotal:number, total:number):void;
    remMeioPag(idx:number):void;
    sendPedido(row:ShowDataPedido):void;
    readAllPedidoAndamento(cliente:Usuario):void;
    setTipoEntrega(tipoEntrada:any):void;
    setObsPedido(obs:string):void;
    
}


export enum PedidoActionsTypes {
    //CREATE
    CREATE_PEDIDO = "@createPedido/CREATE_PEDIDO",
    CREATE_PEDIDO_SUCCESS = "@createMovSuccess/CREATE_PEDIDO_SUCCESS",
    CREATE_PEDIDO_FAILURE = "@createMovFailure/CREATE_PEDIDO_FAILURE",

    //READ ALL
    READ_ALL_PEDIDOS = "@readAllPedidos/READ_ALL_PEDIDOS",
    READ_ALL_PEDIDOS_SUCCESS = "@readAllPedidosSuccess/READ_ALL_PEDIDOS_SUCCESS",
    READ_ALL_PEDIDOS_FAILURE = "@readAllPedidosSuccessFailure/READ_ALL_PEDIDOS_FAILURE",

    ADD_MEIO_PAG = "@addMeioPag/ADD_MEIO_PAG",
    SET_VALOR_FECHAMENTO = "@setValorFechamento/SET_VALOR_FECHAMENTO",
    SET_FECHAMENTO_PEDIDO = "@setFechamentoPedido/SET_FECHAMENTO_PEDIDO",
    SET_TIPO_ENTREGA = "@setTipoEntrega/SET_TIPO_ENTREGA",
    SET_OBS_PEDIDO = "@setObsPedido/SET_OBS_PEDIDO",
    REM_MEIO_PAG = "@remMeioPag/REM_MEIO_PAG",

    SEND_PEDIDO = "@sendPedido/SEND_PEDIDO",
    SEND_PEDIDO_SUCCESS = "@sendPedidoSuccess/SEND_PEDIDO_SUCCESS",
    SEND_PEDIDO_FAILURE = "@sendPedidoFailure/SEND_PEDIDO_FAILURE",

    READ_PEDIDOS_ANDAMENTO = "@readPedidosAndamento/READ_PEDIDOS_ANDAMENTO",
    READ_PEDIDOS_ANDAMENTO_SUCCESS = "@readPedidosAndamentoSuccess/READ_PEDIDOS_ANDAMENTO_SUCCES",
    READ_PEDIDOS_ANDAMENTO_FAILURE = "@readPedidosAndamentoFailure/READ_PEDIDOS_ANDAMENTO_FAILURE",

}


/**
 * View Types
 */

export interface PedidoProps {
    pedidoState: PedidoState
    pedidoActions: PedidoDispatch
}

