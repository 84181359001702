import { action } from "typesafe-actions";
import { KitPromocaoActionsType,KitPromocao } from "../provider/types";

export const create = (prm?:any) => action(KitPromocaoActionsType.CREATE,{prm})
export const createSuccess = (row:KitPromocao) => action(KitPromocaoActionsType.CREATE_SUCCESS, {row})
export const createFailure = (msg:string) => action(KitPromocaoActionsType.CREATE_FAILURE, {msg})

export const readAll = (prm?:any) => action(KitPromocaoActionsType.READ_ALL, { prm })
export const readAllSuccess = (rows:KitPromocao[]) => action(KitPromocaoActionsType.READ_ALL_SUCCESS, { rows })
export const readAllFailure = (msg:string) => action(KitPromocaoActionsType.READ_ALL_FAILURE, { msg })

export const read = (prm?:any) => {}

//actions UPDATE
export const update = (row:KitPromocao)=> action(KitPromocaoActionsType.UPDATE, {row})
export const updateSuccess = (row:KitPromocao) => action(KitPromocaoActionsType.UPDATE_SUCCESS, { row })
export const updateFailure = (msg:string) => action(KitPromocaoActionsType.CREATE_FAILURE, {msg})

//actions DELETE
export const del = (row:KitPromocao)=>action(KitPromocaoActionsType.DELETE, {row});
export const delSuccess = (row:KitPromocao) => action(KitPromocaoActionsType.DELETE_SUCCESS, {row})
export const delFailure = (msg:string) => action(KitPromocaoActionsType.DELETE_FAILURE, {msg})

export const filtrar = (value:string) => action(KitPromocaoActionsType.FILTRAR, { value })
export const filtrarSuccess = (rows:KitPromocao[]) => action(KitPromocaoActionsType.FILTRAR_SUCCESS, { rows })

export const setRow = (row:KitPromocao)=>action(KitPromocaoActionsType.SET_ROW, {row});

export const clearError = ()=>action(KitPromocaoActionsType.CLEAR_ERROR);

export const setShowForm = () => action(KitPromocaoActionsType.SET_SHOW_FORM)