import { action } from "typesafe-actions";
import { ItemEstoque } from "../../../modulos/estoque/provider/types";
import { ItemActionsTypes, Item,FilterItem } from "../../../provider/item/types";

export const readAll = (reload:boolean) => action(ItemActionsTypes.READ_ALL_ITENS, { reload });
export const readAllSuccess = (rows:Item[]) => action(ItemActionsTypes.READ_ALL_ITENS_SUCCESS, { rows });
export const readAllFailure = (msg:string) => action(ItemActionsTypes.READ_ALL_ITENS_FAILURE, { msg });

export const read = (id:string) => action(ItemActionsTypes.READ_ITEM, { id });
export const readSucces = (row:Item) => action(ItemActionsTypes.READ_ITEM_SUCCESS, { row });
export const readFailure = (msg:string) => action(ItemActionsTypes.READ_ITEM_FAILURE, { msg });

export const filtrar = (value:FilterItem) => action(ItemActionsTypes.FILTRAR_ITENS, { value });
export const filtrarSuccess = (rows:Item[]) => action(ItemActionsTypes.FILTRAR_ITENS_SUCCESS, { rows });
export const filtrarFailure = (msg:string) => action(ItemActionsTypes.FILTRAR_ITENS_FAILURE, {msg});

export const setShowForm = () => action(ItemActionsTypes.SET_SHOW_FORM);