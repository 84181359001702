import { GlobalResult, GlobalStates } from "../../services/globalTypes";
/**
 * Data types
 */

export interface Usuario {
    readonly _id:any;
    readonly _rev:any;
    tipo:string;
    data_cad:string;
    nome:string;
    sobrenome:string;
    apelido:string;
    whatsapp:string;
    email:string;
    senha:string;
    dia_pref:string;
    pagamento_pref:string;
    endereco:Endereco;
    resetsenhadt:string;//data que foi setado para resetar a senha
}
interface Endereco {
    rua:string;
    numero:string;
    bairro:string;
    complemento:string;
    cidade:string;
    latitude:number;
    longitude:number;
}

export interface UsuarioResult extends GlobalResult {
    rows?:Usuario[];
    row?:Usuario
}

/**
 * Action types
 */

export enum UsuarioActiosTypes {
    //CREATE
    CREATE_USUARIO = "@createUsusario/CREATE_USUARIO",
    CREATE_USUARIO_SUCCESS = "@createUsusarioSuccess/CREATE_USUARIO_SUCCESS",
    CREATE_USUARIO_FAILURE = "@createUsusarioFailure/CREATE_USUARIO_FAILURE",

    //READ
    READ_USUARIO = "@readUsusario/READ_USUARIO",
    READ_USUARIO_SUCCESS = "@readUsusarioSuccess/READ_USUARIO_SUCCESS",
    READ_USUARIO_FAILURE = "@readUsusarioFailure/READ_USUARIO_FAILURE",

    GET_USER = "@usuario/GET_USER",
    GET_USER_SUCCESS = "@usuario/GET_USER_SUCCESS",
    GET_USER_FAILURE = "@usuario/GET_USER_FAILURE",
    //UPDATE
    UPDATE_USUARIO = "@updateUsusario/UPDATE_USUARIO",
    UPDATE_USUARIO_SUCCESS = "@updateUsusarioSuccess/UPDATE_USUARIO_SUCCESS",
    UPDATE_USUARIO_FAILURE = "@updateUsusarioFailure/UPDATE_USUARIO_FAILURE",

    LOGIN = "@login/LOGIN",
    LOGIN_FOUND = "@loginFound/LOGIN_FOUND",
    LOGIN_NOT_FOUND = "@loginNotFound/LOGIN_NOT_FOUND",
    LOGIN_VALIDATE = "@loginValidate/LOGIN_VALIDATE",
    LOGIN_SUCCESS = "@loginSuccess/LOGIN_SUCCESS",
    LOGIN_FAILURE = "@loginFailure/LOGIN_FAILURE",

    SEND_EMAIL = "@usuario/SEND_EMAIL",
    SEND_EMAIL_SUCCESS = "@usuario/SEND_EMAIL_SUCCESS",
    SEND_EMAIL_FAILURE = "@usuario/SEND_EMAIL_FAILURE",

    LOGOFF = "@logoff/LOGOFF",

    SALVAR = "@salvar/SALVAR",
    SALVAR_SUCCESS = "@salvarSuccess/SALVAR_SUCCESS",
    SALVAR_FAILURE = "@salvarFailure/SALVAR_FAILURE",    

    SET_ROW_USUARIO = "@setRowUsusario/SET_ROW_USUARIO",
    SHOW_FORM_USUARIO = "@showFormUsusario/SHOW_FORM_USUARIO",

    SET_PASSWORD = "@setPassword/SET_PASSWORD",

    SET_VALUE_ROW = "@setValueRow/SET_VALUE_ROW",

    CLEAR_ERROR = "@usuario/CLEAR_ERROR",

}

 /**
  * State Types
  */

 export interface UsuarioState extends GlobalStates<Usuario> {
    readonly row:Usuario,
    readonly rows:Usuario[],
    readonly rowFind:Usuario,
    readonly sentEmail:boolean;
}

/**
 * View Types
 */

export interface UsuarioDispatch {
    /**Funções Async */
    createUser():void
    readUser():void
    login(prmLogin:string):void
    loginValidate(pwLoing:string,row:Usuario):void
    logOff():void;
    salvar(row:Usuario):void;
    setPassword(pw:string):void;
    setValueRow(row:Usuario):void;
    /*updateUser(cliente:Usuario):void
    setRowUser(value:any):void
    setShowFormUser():void   */
    sendEmailRecSenha(email:string):void;
    getUser(id:string):void;
    clearError():void;
}

interface UsuarioProps {
    usuarioState: UsuarioState
    usuarioActions: UsuarioDispatch
}

export type Props = UsuarioProps