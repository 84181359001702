import { call, put } from "redux-saga/effects";
import { MeioPagResult, MeioPag } from "../provider/types";
import meioPagProvider from "../provider";
import * as MeioPagActions from "./actions";


//CREATE
export function* createMeioPag() {
    try {
        const resp:MeioPagResult = yield call(meioPagProvider.createMeioPag)
        if (resp.status === "ok") {
            yield put(MeioPagActions.createMeioPagSuccess(resp.row as MeioPag))    
        } else {
            yield put(MeioPagActions.createMeioPagFailure("Ouve uma falha ao criar um novo meio de pagamento. Detalhes: "+JSON.stringify(resp.mensagem)))
        }
    } catch (error) {
        yield put(MeioPagActions.createMeioPagFailure("Ouve uma falha na função createMeioPag no async. Detalhes: "+JSON.stringify(error)))
    }
}

//READ
export function* readAllMeioPag(actions:any){
    try {
        let resp:MeioPagResult = yield call(meioPagProvider.readAll)
        if(resp.status === "ok") {
            yield put(MeioPagActions.readAllSuccess(resp.rows as MeioPag[]));
        } else {
            yield put(MeioPagActions.readAllFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(MeioPagActions.readAllFailure("Ocorreu uma falha na estrutura da função readAllMeioPag em async. Detalhes: "+JSON.stringify(error)));
    }
}

//UPDATE
export function* updateMeioPag(actions:any) {
    try {
        const resp:MeioPagResult = yield call(meioPagProvider.updateMeioPag,actions.payload.row);
        if (resp.status === "ok") {
            yield put(MeioPagActions.updateMeioPagSuccess(resp.row as MeioPag))
            yield put(MeioPagActions.readAll());
        } else {
            yield put(MeioPagActions.updateMeioPagFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(MeioPagActions.updateMeioPagFailure("Ouve uma falha na função updateMeioPag em async. Detalhes: "+JSON.stringify(error)))
    }
}

//DELETE
export function* DeleteMeioPag(actions:any) {
    try {
        const resp:MeioPagResult = yield call(meioPagProvider.delMeioPag, actions.payload.row);
        if (resp.status === "ok") {
            yield put(MeioPagActions.delMeioPagSuccess());
            yield put(MeioPagActions.readAll());
        } else {
            yield put(MeioPagActions.delMeioPagFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(MeioPagActions.delMeioPagFailure("Ouve uma falha na função deleteMeioPag em async. Detalhes: "+JSON.stringify(error)))
    }
}