import React from 'react';
import { TextField, createStyles, makeStyles, Button } from "@material-ui/core";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
    },
    containerField: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    spaceUn: {
        paddingTop: 5,
        paddingBottom: 5,
    }
}))

interface OwnProps {
    unValue: number;
    setUnValue(value: any): void;
    qt_min:number;
}


function Unidade(props: OwnProps) {
    const { setUnValue, unValue, qt_min } = props;
    const QTMIN = qt_min <= 0 ? 1 : qt_min;
    const classes = useStyles();


    const incrementUni = () => {
        
        let qt = qt_min>0?qt_min:1;
        let un:number = unValue + QTMIN
        //console.log({unValue,qt_min,un})
        setUnValue(un);
    }

    const decrementUni = () => {
        if (unValue > 0) {
            let qt = qt_min>0?qt_min:1;
            let un:number = unValue - QTMIN
            setUnValue(un);    
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.containerField}>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={incrementUni}
                >
                    <AiOutlinePlus />
                </Button>
                <TextField
                    className={classes.spaceUn}
                    InputLabelProps={{ style: { paddingTop: 10 } }}
                    type="number"
                    name="unidade"
                    label="Unidade"
                    variant="outlined"
                    value={unValue}
                    size="small"
                    onChange={(result) => {
                        let value = Number(result.target.value)
                        let qt = QTMIN;
                        let isInteger = Number.isInteger(qt);
                        if (isInteger) {
                            if (Number.isInteger(value)) {
                                if (value > qt) {
                                    setUnValue(value);
                                }                                
                            } else {
                                setUnValue(qt)
                            }
                        } else {
                            if (value > qt) {
                                setUnValue(value);
                            }    
                        }
                    }}
                />
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={decrementUni}
                >
                    <AiOutlineMinus />
                </Button>
            </div>
        </div>

    )
}

export default Unidade;