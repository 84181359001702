import React, { useState, useEffect } from "react";
import {
    makeStyles,
    createStyles,
    AppBar,
    Toolbar,
    List,
    ListItemText,
    ListItem,
    Typography,
    ListItemSecondaryAction,
    ListSubheader,
    Container,
    Button,
    Divider,
    IconButton,
} from "@material-ui/core";
import { IoMdSend } from "react-icons/io";
import { PedidoProps } from "../../provider/pedido/types";
import pedidoProvider from "../../provider/pedido/index";
import Backdrop from "../../components/backdrop/backdrop";
import Alerta from "../../components/alerts/Alerts";
import {
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { MdArrowBack } from "react-icons/md";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
        },
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
        },
        title: {
            flexGrow: 1,
        },          
        container:{
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",
            flex:1,
            marginTop:10,
            marginBottom:10,
        },           
    })
);

interface PropsViewStep3 extends PedidoProps {
    backView:()=>void;
}

function Step3(props: PropsViewStep3) {
    const { backView }= props;
    const { row, loading, error, msg, sended, } = props.pedidoState;
    const { sendPedido } = props.pedidoActions;
    const classes = useStyles();
    const [showAlertError, setShowAlertError] = useState(error);
    const routerHistory = useHistory();
    //console.log("props",props.pedidoState);
    //console.log("altertErro",showAlertError);

    
    useEffect(()=>{
        /**effect é chamado quando o error state é alterado
         * fazendo com que o alert seja exibido ou não
        */
        setShowAlertError(error);
    },[error]);

    useEffect(() => {
        sendPedido(row)
    }, []);

    useEffect(() => {
        if(sended){
            ReactPixel.track('Purchase',{value:row.pedido.fechamento.total,currency:'BRL'});
            routerHistory.push(`/enviado`)
        }
    },[sended])
    return (
        <div className={classes.root}>

            <Alerta show={showAlertError} menssage={msg} tipoAlerta="error" handleClose={()=>setShowAlertError(false)}/>

            <div className={classes.content}>
                <Backdrop show={loading} />
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={backView}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Concluir e Enviar Pedido
                        </Typography>
                    </Toolbar>
                </AppBar>                  
                <Container className={classes.container}>
                    <List>
                    <ListSubheader>Concluir Pedido</ListSubheader>
                        <Divider></Divider>
                        <ListItem>
 

                            <ListItemText
                                primary={<Typography variant={"caption"} color={"textSecondary"}>Total Pedido:</Typography>}
                                secondary={
                                    <Typography
                                        variant={"h6"}
                                        color={"primary"}
                                    >
                                        {pedidoProvider.formatMoney(
                                            row.pedido.fechamento.total
                                        )}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction style={{display:"flex", flexDirection:"row", justifyContent:"space-arrow", alignItems:"center"}}>
                                <Typography
                                    variant={"h6"}
                                    color={"textPrimary"}
                                >
                                    {row.itens.length > 9
                                        ? "0" + row.itens.length
                                        : row.itens.length}
                                </Typography>
                                <Typography
                                    variant={"caption"}
                                    color={"textSecondary"}
                                >
                                    {row.itens.length > 1 ? " itens" : " item"}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider></Divider>
                        <ListSubheader>Forma de pagamento</ListSubheader>
                        {row.pedido.fechamento.meios_pag.map((pay,idx)=>(
                            <div key={idx}>
                            <ListItem >
                                <ListItemText primary={pay.descricao}/>
                            </ListItem>
                            {pay.troco as number > 0 && (
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant={"caption"}
                                            color={"textSecondary"}
                                        >
                                            Troco para {pedidoProvider.formatMoney(pay.valor)}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant={"body1"}
                                            color={"secondary"}
                                        >
                                            {pedidoProvider.formatMoney(pay.troco as number)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            )}
                            </div>
                        ))}

                    </List>
                    <Button 
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        endIcon
                        onClick={()=>sendPedido(row)}
                    >
                        Enviar Pedido
                        <IoMdSend size={25}/>
                    </Button>                    
                </Container>
            </div>
        </div>
    );
}

export default Step3;
