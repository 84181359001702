import moment from "moment";
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import database from "./db";
import validator from "validator";

class Utils extends database {
    
    public isEmail(prm:any){
        if(!this.isEmpty(prm)){
            let email = validator.isEmail(prm)
            //console.log("é email ",email);
            return email;    
        } else {
            return false;
        }
    }

    public alterRow(rowAlter:any,rowModel:any){
		let newRow = rowModel;
		for (var i in rowAlter) {
			newRow[i] = rowAlter[i];
        }
		return newRow;
    }

    public isEmpty(prm:any){
		switch (prm) {
			case null:
				return true;
			case undefined:
				return true;
			case '':
				return true		
			default:
				return false
		}
	}
    
    public getHashSha256(msg: any) {
		return sha256(msg).toString();
	}

    public getHashMd5(msg:any) {
        return md5(msg).toString()
    }

	public getNewUID() {
		const dt = new Date();
        let randomNumber = Math.floor((Math.random() * 1000) + 1);
		let idN = dt.getDate() +"-"+ dt.getTime() +"-"+ dt.getSeconds() +"-"+ dt.getMilliseconds() +"/"+ randomNumber;
		let idS = this.getHashMd5(idN);
		return idS;
    }     
	    
	public excluirLinhaArray(array: any[], index:number) {
		array.splice(index, 1);
	} 

    public formatMoney(value:number){
        if (!value) return value;
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          minimumFractionDigits: 2,
          currency: 'BRL'
        });
        let formated = formatter.format(value);
        return formated;
    };	

	public formaDateIsoValue(date:string) {
		let d = moment(date).format();
		return d;
	}

	public getDataText(date:string) {
		let d = moment(date).format("DD/MM/YYYY HH:mm");
		return d;
	}

    public formatDataAteAgora(date:string){
        return moment(date).fromNow();
    }    

    public soma(array:any[],rowQt:any,rowValue:any) {
        var total = 0;
        //console.log(array);
        array.map((row, key)=>{
            if (rowQt!='') {
                var valor = row[rowQt]*row[rowValue];
                total = total + valor;
            } else {
                //console.log(row[rowValue]);
                total = total + row[rowValue];
            }
        });
        return total.toFixed(2);
    }


	public async salvarRemoto(row:any)  {
		//console.log('chamou salvar', row);
		if (!this.isEmpty(row._id)) {
			//Update
			try {
				const result = await this.udateDataRemote(row);
				if (result.ok) {
					row._rev = result.rev;
					return { status:true, row };
				} else {
					return {status:false, erro:result}
				}
			} catch (erro) {
				//console.log(erro);
				return { status:false, erro };
			}
		} else {
			//new
			try {
				
				const result = await this.createDataRemote(row);
				if (result.ok) {
					row._id = result.id;
					row._rev = result.rev;
					return { status:true, row };
				} else {
					return {status:false, erro:result}
				}			
			} catch (erro) {
				//console.log(erro);
				return { status:false, erro };
			}
		}
	};
	
	filterAllArray = (arr:Array<any>=[], term:any="") => {
        try {
            let data = arr.filter((list:any) =>{
                const count = Object.keys(list);//verificar a quantidade de campos que o objeto tem
                let idx = 0;
                var result = false;
    
                //for nivel 1 raiz do objeto
                for (const key1 in list) {
                    if (list.hasOwnProperty(key1)) {
                        const elem1 = list[key1];
                        idx++;
                        //console.tron.log('elem1',elem1);
                        switch (typeof elem1) {
                            case 'object':
                                
                                //for nivel 2
                                for (const key2 in elem1) {
                                    if (elem1.hasOwnProperty(key2)) {
                                        const elem2 = elem1[key2];
                                        //console.tron.log('elem2',elem2);
                                        switch (typeof elem2) {
                                            case 'object':
    
                                                //for nivel 3
                                                for (const key3 in elem2) {
                                                    if (elem2.hasOwnProperty(key3)) {
                                                        const elem3 = elem2[key3];
                                                        //console.tron.log('elem3',elem3);
                                                        switch (typeof elem3) {
                                                            case 'object':
                                                                //for nivel 4
                                                                for (const key4 in elem3) {
                                                                    if (elem3.hasOwnProperty(key4)) {
                                                                        const elem4 = elem3[key4];
                                                                        //console.tron.log('elem4',elem4);
                                                                        switch (typeof elem4) {
                                                                            case 'object':
                                                                                result = false;
                                                                                break;
                                                                            case 'boolean':
                                                                                result = false;
                                                                                break;
                                                                            case 'number':
                                                                                result = elem4 === term;
                                                                                break   
                                                                            default:
                                                                                result = elem4.toLowerCase().indexOf(term.toLowerCase()) > -1
                                                                                break;
                                                                        }
                                                                    }
                                                                }
    
                                                                break;
                                                            case 'boolean':
                                                                result = false;
                                                                break; 
                                                            case 'number':
                                                                result = elem3 === term;
                                                                    break    
                                                            default:
                                                                result = elem3.toLowerCase().indexOf(term.toLowerCase()) > -1
                                                                break;
                                                        }
                                                    }
                                                }
                                                
                                                break;
                                            case 'boolean':
                                                result = false;
                                                break;    
                                            case 'number':
                                                result = elem2 === term;
                                                break    
                                            default:
                                                result = elem2.toLowerCase().indexOf(term.toLowerCase()) > -1  
                                                break;
                                        }
                                    }
                                }
                                break;
                            case 'boolean':
                                result = false;
                                break;
                            case 'number':
                                result = elem1 === term;
                                break   
                            default:
                                result = elem1.toLowerCase().indexOf(term.toLowerCase()) > -1    
                                break;
                        }
    
                        if (result) {
                            return result
                        } else if (!result && idx === count.length) {
                            return result
                        }
                    }
                }
            });
            return {ok:true,data:data};            

        } catch (error) {
            return {ok:false,mgs:error};
        }
    }
	
}

export default Utils;