import React, { useEffect, useState } from "react"

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "../../store";
import * as UserActions from "../../store/data/user/actions";
import Alerta from "../../components/alerts/Alerts";
import {
    Props,
    UsuarioState,
    UsuarioDispatch,
    Usuario
} from "../../provider/usuario/types";

import {
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import { makeStyles,createStyles, AppBar, Toolbar, IconButton, Typography, Container, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Grid, TextField, Button, Divider, CircularProgress } from "@material-ui/core";
import { MdArrowBack, MdSend } from "react-icons/md";

import { isEmail } from "../../utilsJs"
import usuarioProvider from "../../provider/usuario/index" 

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

interface SendEmailResetPwdProps {
    usuarioState:UsuarioState,
    usuarioActions:UsuarioDispatch,
}

function SendEmailView(props:SendEmailResetPwdProps){
    const { usuarioState, usuarioActions } = props;
    const {  loading,sentEmail,msg,error} = usuarioState;
    const classes = useStyles();
    const routerHistory = useHistory();
    const { url } = useRouteMatch();
    const [emailText, setEmailText] = useState("")
    console.log("props", props);

    useEffect(()=>{
        if(sentEmail){
            routerHistory.push("/sentEmailSucess")
        }
    },[sentEmail])

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Alerta show={error} menssage={msg} tipoAlerta="error" handleClose={()=>usuarioActions.clearError()} />
                <AppBar position={"sticky"} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={()=>routerHistory.goBack()}
                        >
                            <MdArrowBack />
                        </IconButton>

                        <Typography variant="h6" className={classes.title}>
                            Recuperar Senha
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Typography variant="h6">
                        Siga os passos abaixo para redefinição da sua senha:
                    </Typography>
                    <List >
                        <ListItem>
                            <ListItemText 
                                primary="1 - Informe o e-mail que você usou para se cadastrar no app."
                                secondary="No campo abaixo"
                                
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText 
                                primary="2 - Click no botão 'Enviar Link de Recuperação' abaixo."
                                secondary="Um link de recuperação de senha será enviado para o e-mail informado."
                            />
                        </ListItem>

                    </List>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Informe o e-mail cadastrado aqui"
                                type="email"
                                value={emailText}
                                variant="filled"
                                onChange={value=>setEmailText(value.target.value)}
                            />                            
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <Button
                                variant={
                                    "contained"
                                }
                                endIcon
                                color={"primary"}
                                fullWidth={true}
                                disabled={!isEmail(emailText)||loading}
                                onClick={()=>usuarioActions.sendEmailRecSenha(emailText)}
                            >
                                Enviar Link de Recuperação
                                {loading?(
                                    <CircularProgress size={24}></CircularProgress>
                                ):(
                                    <MdSend
                                        size={24}
                                    />
                                )}
                            </Button>
                        </Grid>

                    </Grid>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    usuarioState: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    usuarioActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailView);
