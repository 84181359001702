import Pouchdb from "pouchdb";
import PouchdbFind from "pouchdb-find";
import ApiHttp from "./api";

const PouchdbUpdate = require("pouchdb-update");
Pouchdb.plugin(PouchdbFind);
Pouchdb.plugin(PouchdbUpdate);

const CONFIG = {
    protocol:'https://',
    url:'jasatiapi.com.br/couchdb/',
    user:'akisuafeira_user',
    pass:'16AkiSuaFeira09',
    db:'akisuafeira_db',
}
const urlAuth = CONFIG.protocol+CONFIG.user+':'+CONFIG.pass+'@'+CONFIG.url+CONFIG.db;
interface Selector {
    selector:object
}

export default abstract class DataBase extends ApiHttp {
    private dbRemote : PouchDB.Database;
    private dbLocal : PouchDB.Database;
    protected dataDb : any[] = [];
    //public changes : PouchDB.Core.Changes<any>;
    public sync : any = {};

    constructor(){
        super();
        this.dbRemote = new Pouchdb(urlAuth);
        this.dbLocal = new Pouchdb(CONFIG.db);
    }

/*     protected async syncDb(cliente_id:string){
        if(cliente_id){
            this.sync = this.dbLocal.sync(this.dbRemote,{
                filter:function(doc,req){
                    if(doc.tipo === 'cliente'){
                        return doc._id === cliente_id
                    } else if (doc.tipo === 'mov') {
                        return doc.cliente_id === cliente_id
                    }
                },
                live:true,
                retry:true,
            }).on("change",(info)=>{
                console.log("change db", info);
            }).on("paused", (err)=>{
                console.log("paused db", err);
            }).on("active",()=>{
                console.log("active db");
            }).on("denied",(err)=>{
                console.log("denied db",err);
            }).on("complete",(info)=>{
                console.log("complete db", info);
            }).on("error",(err)=>{
                console.log("error db", err);
            })
        }
    } */

    protected cancelSync(){
        if(this.sync.cancel){
            this.sync.cancel();
        }
    }

    protected async getDocRemote(id:string){
        try {
            let doc = await this.dbRemote.get(id);
            return {status:"ok",doc:doc}
        } catch (error) {
            return {status:"error",msg:error}
        }
        
    }

    protected async findRemote(selector:Selector){
        return await this.dbRemote.find(selector);
    }
    protected async queryRemote(qry:string,opt:{}){
        return await this.dbRemote.query(qry,opt);
    }

    protected async queryUpdateRemote(qry:string,idDoc:string,opt:{}){
        try {
            let resp = await this.CouchDbFunctionUpdate({
                url:CONFIG.protocol+CONFIG.url+CONFIG.db,
                functionUpCouchName:qry+"/"+idDoc,
                user:CONFIG.user,
                pass:CONFIG.pass
            });
            return resp;
        } catch (error) {
            console.log(error)
            return null
        }
    }
    protected async changeRemote(opt:{
        since?:any,
        live:boolean,
        include_docs:boolean,
        filter:string,
        view?:string,
        query_params:{}
    }){
        return await this.dbRemote.changes(opt)
    }


    protected async createDataRemote(data:any){
        return await this.dbRemote.post(data)
    }

    protected async udateDataRemote(data:any){
        return await this.dbRemote.put(data);
    }

    protected async bulkDataRemote(data:any[]){
        return await this.dbRemote.bulkDocs(data);
    }

    protected async deleteDataRemote(data:any){
        data._deleted = true;
        return await this.dbRemote.put(data);
    }

    protected async getDocLocal(id:any){
        return await this.dbLocal.get(id)
    }

    protected async createDataLocal(data:any){
        return await this.dbLocal.post(data)
    }

    protected async updateDataLocal(data:any,opt:any={}){
        return await this.dbLocal.put(data,opt);
    }

    protected async deleteDataLocal(data:any){
        return await this.dbLocal.remove(data)
    }

    protected async findDataLocal(selector:Selector){
        return await this.dbLocal.find(selector)
    }
}