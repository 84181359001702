import { GlobalStates, GlobalResult } from "../../services/globalTypes"
import { ItemCartDispatch, ItemCartState } from "../itemCart/types";
import { Categoria } from "../../modulos/categorias/provider/types"
/**
 * Data types
 */

export interface FilterItem{
    descricao:string;
    categoria:Array<{_id:string}>
    codigo:any
}

export interface Item {
    readonly _id:any;
    readonly _rev:any;
    tipo:string;
    descricao:string;
    detalhes:string;
    uni_med:string;
    categoria:Categoria;
    custo:number;
    margem:number;
    preco:number;
    codigo:string;
    img:string;
    qt_estoque:number;
    ctrl_esto_man:boolean;//se o sistema vai verificar o estoque pelo campo estoque
    estoque:boolean;//controle para o gestor informar manualmente
    active:boolean;
    qt_min:number;//quantidade minima para compar o produto, 
    insumoGrupo:{_id:any,descricao:string}
    show_online:boolean;    
}


export interface ItemResult extends GlobalResult {
    rows?:Item[];
    row?:Item
}


/* Action types */
export enum ItemActionsTypes {
    READ_ITEM = "@readItem/READ_ITEM",
    READ_ITEM_SUCCESS = "@readItemSuccess/READ_ITEM_SUCCESS",
    READ_ITEM_FAILURE = "@readItemFailure/READ_ITEM_FAILURE",

    READ_ALL_ITENS = "@readAllItens/READ_ALL_ITENS",
    READ_ALL_ITENS_SUCCESS = "@readAllItensSuccess/READ_ALL_ITENS_SUCCESS",
    READ_ALL_ITENS_FAILURE = "@readAllItensFailure/READ_ALL_ITENS_FAILURE",

    FILTRAR_ITENS = "@filtrarItens/FILTRAR_ITENS",
    FILTRAR_ITENS_SUCCESS = "@filtrarItensSuccess/FILTRAR_ITENS_SUCCESS",
    FILTRAR_ITENS_FAILURE = "@filtrarItensFailure/FILTRAR_ITENS_FAILURE",

    SET_SHOW_FORM = "@setShowForm/SET_SHOW_FORM",
}

 /**
  * State Types
  */

export interface ItemState extends GlobalStates<Item> {
    readonly row:Item,
    readonly rows:Item[]
}

/**
 * View Types
 */

interface ItemProps {
    catalogoState: ItemState
    itemCartState:ItemCartState
}

export interface ItemDispatch {
    read(id:string):void
    readAll(reload:boolean):void
    setShowForm():void
    filtrar(value:FilterItem):void
}

interface MultDispatch {
    catalogActions:ItemDispatch,
    itemCartActions:ItemCartDispatch
}

export type Props = ItemProps & MultDispatch
