import {Reducer} from "redux";
import { EntregaPedido,TipoEntregaState,TipoEntregaActionsType } from "../provider/types";


const INITIAL_STATE : TipoEntregaState = {
    row: {} as EntregaPedido,
    rows:[],
    error:false,
    loading:false,
    msg:"",
    showForm:false,
    total_rows:0,
};

const reducer:Reducer<TipoEntregaState> = (state=INITIAL_STATE,action) => {
    switch (action.type) {

        //READ
        case TipoEntregaActionsType.READ_ALL:
            return {...state, loading:true, error:false, msg:""}
        case TipoEntregaActionsType.READ_ALL_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
        case TipoEntregaActionsType.READ_ALL_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg }

        case TipoEntregaActionsType.FILTRAR:
            return {...state, loading:true, error:false} 
        case TipoEntregaActionsType.FILTRAR_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
            
        case TipoEntregaActionsType.SET_SHOW_FORM:
            return {...state, showForm:!state.showForm };

        case TipoEntregaActionsType.SET_ROW:
            return {...state, showForm:!state.showForm, row:action.payload.row };

        case TipoEntregaActionsType.CLEAR_ERROR:
            return {...state, error:false,msg:"" }
        default:
            return { ...state};
    }
}

export default reducer;