import { call, put } from "redux-saga/effects";
import * as ActionsItem from "./actions";
import ItemProvider from "../../../provider/item";
import {estoqueProvider} from "../../../modulos/estoque/provider"
import {ItemResult, Item, ItemActionsTypes} from "../../../provider/item/types";
import { GlobalResult } from "../../../services/globalTypes";

export async function getItensEstoque(reload:boolean):Promise<ItemResult>{
    try {
        const resp:ItemResult = await ItemProvider.readAll(reload);
        const respEstoque:GlobalResult = await estoqueProvider.readAll({type:"view",view:{qryName:"item/estoqueByItem",opt:{group_level:1} } } );
        if (resp.status === "ok") {
            //ajustando a quantidade de estoque atual
            let itens:Item[] = resp.rows as Item[];
            itens.map((item,i)=>{
                let qtEstFind = respEstoque.rows?.find(qtEst=>qtEst.key === item._id);
                item.qt_estoque = qtEstFind?qtEstFind.value:0;
            })
            return {status:"ok", rows:itens};
        } else {
            return {status:"error", mensagem:resp.mensagem};
        }
            
    } catch (error) {
        return {status:"error", mensagem:"Ocorreu um erro na estrutura getItensEstoque em async catalogo. Detalhes: "+JSON.stringify(error)}        
    }
}

export function* readAllItem(actions:any){
    try {
        const resp:ItemResult = yield call(getItensEstoque,actions.payload.reload);
        if (resp.status === "ok") {
            yield put(ActionsItem.readAllSuccess(resp.rows as Item[]))
        } else {
            yield put(ActionsItem.readAllFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(ActionsItem.readAllFailure("Falha geral na consuta dos itens. Detalhes: "+JSON.stringify(error)))
    }
}

export function* readItem(actions:any){
    try {
        const resp:ItemResult = yield call(ItemProvider.read,actions.payload.id);
        if (resp.status === "ok") {
            yield put(ActionsItem.readSucces(resp.row as Item))
        } else {
            yield put(ActionsItem.readFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(ActionsItem.readAllFailure("Falha geral ao buscar o item. Detalhes: "+JSON.stringify(error)))
    }
}

export function* filtrar(actions:any){
    try {
        const resp:ItemResult = yield call(ItemProvider.filtrar, actions.payload.value);
        if(resp.status === "ok"){
            yield put(ActionsItem.filtrarSuccess(resp.rows as Item[]));
        } else {
            yield put(ActionsItem.filtrarFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItem.filtrarFailure("Falha geral no filtro do itens do catálogo. Detalhes: "+JSON.stringify(error)));
    }
}