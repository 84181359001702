import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";

import * as PedidoActions from "../../store/data/pedido/actions";
import { PedidoProps, PedidoDispatch, PedidoState } from "../../provider/pedido/types";
import { SessaoProps, SessaoState } from "../../provider/start/types";
import { PedidoData } from "../../provider/pedido/types"
import pedidoProvider from "../../provider/pedido";
import {
  List,
  ListItem,
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  LinearProgress,
  Divider,
  Grid,
  AppBar,
  Toolbar,
  Button,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  InputBaseProps,
  InputBase,
  
} from "@material-ui/core";
import { MdHistory, MdChangeHistory, MdShoppingBasket } from "react-icons/md";
import Modal from "../../components/Modal";
import PedidoItens from "../PedidoItens";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      height: '100%',
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginBottom: 40,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    title: {
      flexGrow: 1,
    },
    listText: {
      marginLeft: theme.spacing(1),
    },
    textSmall: {
      fontSize: 10,
    },
    textMidle: {
      fontSize: 13,
    },
  })
);


interface PedidoItensModalProps {
  handleClose(): void;
  open: boolean;
  pedido: PedidoData;
}
function ShowPedidoItensModal(props: PedidoItensModalProps) {
  const { handleClose, open, pedido } = props;

  return (
    <Modal
      component={
        <PedidoItens handleClose={handleClose} pedido={pedido} />
      }
      handleClose={handleClose}
      open={open}
    />
  );
}

interface HistoryProps {
  pedidoActions: PedidoDispatch;
  pedidoState: PedidoState;
  sessaoState: SessaoState;
}

interface PeriodoFilter {
  lengenda: string;
  data_ini: string;
  data_fim: string;
}

enum LegendaPeriodo {
  quinzeDias = "Últimos 15 dias",
}


function History({ pedidoActions, pedidoState, sessaoState }: HistoryProps) {
  const { rows, loading, error, msg, total_rows } = pedidoState;
  const { readAllPedidos } = pedidoActions;
  const classes = useStyles();
  const LegendaPeriodo = [
    "Últimos 15 dias",
    "Últimos 30 dias",
    "Últimos 90 dias",
    "Últimos 180 dias",
    "Últimos 360 dias",
  ];
  const [periodo, setPeriodo] = useState<PeriodoFilter>({
    data_fim: "",
    data_ini: "",
    lengenda: LegendaPeriodo[0],
  });
  const [openPedidoItensModal, setOpenPedidoItensModal] = useState(false);
  const [pedido, setPedido] = useState({} as PedidoData);

  const handleClosePedidoItensModal = () => {
    setOpenPedidoItensModal(false);
    setPedido({} as PedidoData);
  }

  const handleOpenPedidoItensModal = (pedido: PedidoData) => {
    setPedido(pedido);
    setOpenPedidoItensModal(true);
  }

  const handleLegendaPeriodo = (event:React.ChangeEvent<{value:unknown}>) => {
    setPeriodo(state=>({...state,lengenda:event.target.value as string}))
  }

  const handlePeriodoPedido = () => {
    let dtFim = new Date();
    let dtIni = new Date();
    switch (periodo.lengenda) {
      case "Últimos 15 dias":
        dtIni.setDate(dtFim.getDate()-15);
        setPeriodo(state=>({...state,data_ini:dtIni.toISOString(),data_fim:dtFim.toISOString()}))
        break;
      case "Últimos 30 dias":
        dtIni.setDate(dtFim.getDate()-30);
        setPeriodo(state=>({...state,data_ini:dtIni.toISOString(),data_fim:dtFim.toISOString()}))
        break;
      case "Últimos 90 dias":
          dtIni.setDate(dtFim.getDate()-90);
          setPeriodo(state=>({...state,data_ini:dtIni.toISOString(),data_fim:dtFim.toISOString()}))
        break;    
      case "Últimos 180 dias":
          dtIni.setDate(dtFim.getDate()-180);
          setPeriodo(state=>({...state,data_ini:dtIni.toISOString(),data_fim:dtFim.toISOString()}))
        break;    
      case "Últimos 360 dias":
          dtIni.setDate(dtFim.getDate()-360);
          setPeriodo(state=>({...state,data_ini:dtIni.toISOString(),data_fim:dtFim.toISOString()}))
        break;

      default:
        break;
    }
  }

  useEffect(()=>{
    handlePeriodoPedido();
  },[periodo.lengenda])

  
  //console.log("periodo", periodo);
  useEffect(() => {
    if (sessaoState.sessaoStarted) {
      readAllPedidos(sessaoState.row.user,periodo.data_ini,periodo.data_fim);
    }
  }, [periodo.data_ini])

  return (
    <div className={classes.root}>
      <ShowPedidoItensModal
        handleClose={handleClosePedidoItensModal}
        open={openPedidoItensModal}
        pedido={pedido}
      />
      <div className={classes.content}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <MdHistory size={25} />
            <Typography variant="h6" className={classes.title}>
              Histórico de pedidos
            </Typography>
            <FormControl variant="filled" > 
              <InputLabel id="demo-simple-select-filled-label">Período</InputLabel>
              <Select
                value={periodo.lengenda}
                onChange={handleLegendaPeriodo}
              >
                {
                  LegendaPeriodo.map((leg)=>(
                    <MenuItem value={leg}>{leg}</MenuItem>    
                  ))
                }
              </Select>
            </FormControl>
          </Toolbar>
        </AppBar>
        {loading && (
          <LinearProgress
            style={{ margin: 3, minWidth: "max-content" }}
            color="primary"
          ></LinearProgress>
        )}
        {rows.length === 0 && (
          <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <MdChangeHistory size={40} style={{ color: "silver" }} />
            <Typography variant={"h6"} color={"textSecondary"}>
              Não foi encontrado pedidos.
            </Typography>
          </div>
        )}
        <List style={{ maxHeight: "max-content", overflowY: "scroll" }}>
          {
            rows.map((row, idx) => (
              <ListItem
                alignItems="flex-start"
                key={idx}
                button
                onClick={() => handleOpenPedidoItensModal(row.pedido)}
              >
                <ListItemAvatar>
                  <Chip icon={<MdShoppingBasket />} label={row.pedido.codigo} color="primary" />
                </ListItemAvatar>
                <ListItemText
                  primary={row.pedido.status}
                  secondary={pedidoProvider.formatDataAteAgora(row.pedido.data_status)}
                >
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant={'body1'} color={'primary'}>
                    {
                      row.pedido.fechamento && (
                        pedidoProvider.formatMoney(row.pedido.fechamento.total)
                      )
                    }
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </div>

    </div>
  );
}

const mapStateProps = (store: ApplicationState) => ({
  pedidoState: store.pedido,
  sessaoState: store.start,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  pedidoActions: bindActionCreators(PedidoActions, dispatch)
});

export default connect(mapStateProps, mapDispatchToProps)(History);
