import React from "react";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from "@material-ui/core";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
interface OwnProps {
    value: string;
    label: string;
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
    helperText?:string;
    error?:boolean;
}
interface State {
    showPassword: boolean;
}
function TextPassword(props: OwnProps) {
    const { handleChange, label, value,helperText, error } = props;
    const [state, setState] = React.useState<State>({
        showPassword: false,
    })

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="outlined" fullWidth error={error}>
            <InputLabel >{label}</InputLabel>
            <OutlinedInput
                type={state.showPassword ? 'text' : 'password'}
                defaultValue={value}
                label={label}
                onChange={handleChange}

                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {state.showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error={error} >{helperText}</FormHelperText>
            
        </FormControl>
    )
}

export default TextPassword