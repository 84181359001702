import { action } from "typesafe-actions";
import { PedidoItensActionsType, ShowDataPedidoItens } from "../../../provider/pedidoItens/types";

export const readPedidoItens = (mov_id:string) => action(PedidoItensActionsType.READ_PEDIDO_ITENS,{mov_id});
export const readPedidoItensSuccess = (rows:ShowDataPedidoItens[]) => action(PedidoItensActionsType.READ_PEDIDO_ITENS_SUCCESS,{rows});
export const readPedidoItensFailure = (msg:string) => action(PedidoItensActionsType.READ_PEDIDO_ITENS_FAILURE,{msg});

export const selectPedidoItem = (_id:string) => action(PedidoItensActionsType.SELECT_PEDIDO_ITEM, {_id})
export const selectAllItens = (selected:boolean) => action(PedidoItensActionsType.SELECT_ALL_ITENS_PEDIDO, {selected});

export const repeatItensPedido = (itensSel:ShowDataPedidoItens[],manterPreco:boolean) => action(PedidoItensActionsType.REPEAT_ITENS_PEDIDO, { itensSel, manterPreco });
export const repeatItensPedidoSuccess = () => action(PedidoItensActionsType.REPEAT_ITENS_PEDIDO_SUCCESS);
export const repeatItensPedidoFailure = (msg:string) => action(PedidoItensActionsType.REPEAT_ITENS_PEDIDO_FAILURE, { msg });

export const setSendToCart = (parms:boolean) => action(PedidoItensActionsType.SET_SEND_TO_CART, {parms});