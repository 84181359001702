import React, { useState, useEffect } from "react";
import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    createStyles,
    Theme,
    CardHeader,
    TextField as TextFieldMaterial,
    Button,
    Fab,
} from "@material-ui/core";
import KiloGrama from "../../components/kilograma";
import Unidade from "../../components/unidade";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import { MdArrowBack, MdAddShoppingCart, MdAdd } from "react-icons/md";
import Utils from "../../services/utils";
import { showDataItemCart, ItemCartDispatch } from "../../provider/itemCart/types";
import * as CurrencyFormat from "react-currency-format";
import Alerta from "../../components/alerts/Alerts";


const util = new Utils();
const PATH_IMG = "https://akisuafeira.com.br/ws/App/Upload/";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
        },
        title: {
            flexGrow: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%",
        },
        fab: {
            position: 'fixed',
            top: _theme.spacing(15),
            right: _theme.spacing(4),
        },
    })
);
export enum ActionItem {
    update = "update",
    insert = "insert"
}
interface itemCartView {
    row: showDataItemCart;
    showView(): void;
    itemCartActions: ItemCartDispatch
    actionItem: ActionItem
}

export default function Item(props: itemCartView) {
    const { row, showView, itemCartActions, actionItem } = props;
    const [changePreco, setChangePreco] = useState(false);
    const classes = useStyles();
    const [alertSaldo, setAlertSaldo] = useState(false);
    const [msgSaldo, setMsgSaldo] = useState("");
    //console.log("item ",row);
    const handleChangePreco = (value: boolean) => {
        setChangePreco(value);
    }
    const validate = (values: showDataItemCart) => {
        const errors = {};
        return errors;
    };

    const salvarItemCart = (values: showDataItemCart) => {
        if(values.itemData.qt_estoque != undefined){
            if(values.itemCartData.qt <= values.itemData.qt_estoque){
                switch (actionItem) {
                    case ActionItem.insert:
                        itemCartActions.addItemCart(values);
                        break;
                    case ActionItem.update:
                        itemCartActions.updateCart(values);
                        break;
                }
                showView();    
            } else {
                //exibvir alerta de saldo
                if(row.itemData.qt_estoque <= 0){
                    setMsgSaldo("Descupe! o item "+row.itemData.descricao+" não está mais disponível.");
                } else {
                    setMsgSaldo("Descupe! só temos "+row.itemData.qt_estoque+" "+row.itemData.descricao+" disponível em estoque.");
                }                
                setAlertSaldo(true);
            }    
        } else {
            setMsgSaldo("Descupe! o item "+row.itemData.descricao+" não está mais disponível.");
            setAlertSaldo(true);
        }
    };

    const calcTotal = (qt: number, preco: number) => {
        let result = preco * qt;
        return Number(result.toFixed(2));
    };

    const calcQtde = (subtotal: number, preco: number) => {
        let result = subtotal / preco;
        return Number(result.toFixed(2));
    }

    const mutatorCalcTotal = ([name], state, { changeValue }) => {
        switch (name["entrada"]) {
            case "subtotal":
                changeValue(state, name["field"], () =>
                    calcTotal(name["qt"], name["preco"])
                );
                break;

            case "qtde":
                changeValue(state, name["field"], () =>
                    calcQtde(name["subtotal"], name["preco"])
                );
                break;
        }

    };

    const handleFocus = event => {
        event.preventDefault();
        const { target } = event;
        const extensionStarts = target.value.lastIndexOf('0');
        target.focus();
        target.setSelectionRange(0, extensionStarts);
    }
    //console.log("item ",row.itemData);

    return (
        <>
            {row.itemData && (
                <div className={classes.root}>
                    <Alerta show={alertSaldo} menssage={msgSaldo} tipoAlerta="error" handleClose={()=>setAlertSaldo(false)} />
                    <AppBar position="fixed">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="back"
                                onClick={showView}
                            >
                                <MdArrowBack />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {row.itemData.descricao}
                            </Typography>
                            <Typography variant="h6">
                                {util.formatMoney(row.itemData.preco)}
                            </Typography>
                            <Typography variant="body2">
                                {row.itemData.uni_med}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container style={{ marginTop: 50, display: "flex", flexGrow: 1 }}>
                        <Card>
                            <CardHeader
                                subheader={`Código: ${row.itemData.codigo}`}
                            />
                            <CardMedia
                                className={classes.media}
                                image={PATH_IMG + row.itemData.img}
                                title={row.itemData.descricao}
                            ></CardMedia>
                            <CardContent>
                                <Grid
                                    container
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="body2" >
                                            {row.itemData.detalhes && <><span>{row.itemData.detalhes}</span><br /></>}
                                            {
                                                row.itemData.uni_med === "UN" ? (
                                                    row.itemData.qt_min < 1 ? (
                                                        <small>Qtde. minima 1 {row.itemData.uni_med}</small>
                                                    ) :
                                                        (
                                                            <small>Qtde. minima {row.itemData.qt_min} {row.itemData.uni_med}</small>
                                                        )
                                                ) : (
                                                        row.itemData.qt_min > 0 && <small>Qtde. minima {row.itemData.qt_min.toFixed(3)} {row.itemData.uni_med}</small>
                                                    )
                                            }

                                        </Typography>
                                    </Grid>


                                    <Form
                                        onSubmit={salvarItemCart}
                                        initialValues={row}
                                        validate={validate}
                                        mutators={{ mutatorCalcTotal }}
                                    >
                                        {({
                                            handleSubmit,
                                            pristine,
                                            submitting,
                                            values,
                                            form,
                                            errors,
                                        }) => (
                                                <form
                                                    onSubmit={handleSubmit}
                                                    noValidate
                                                >

                                                    <Grid
                                                        container
                                                        justify={"space-between"}
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={8}>
                                                            <Field
                                                                name="itemCartData.qt"
                                                                type="number"
                                                            >
                                                                {(props) => {

                                                                    switch (values.itemData.uni_med) {
                                                                        case "KG":
                                                                            return (
                                                                                <KiloGrama
                                                                                    
                                                                                    changePreco={changePreco}
                                                                                    setChangePreco={handleChangePreco}
                                                                                    kgValue={props.input.value}
                                                                                    qtMin={Number(values.itemData.qt_min.toFixed(3))}
                                                                                    setKgValue={value => {
                                                                                        props.input.onChange(value);
                                                                                        form.mutators.mutatorCalcTotal({
                                                                                            entrada: "subtotal",
                                                                                            qt: value,
                                                                                            preco: values.itemData.preco,
                                                                                            field: 'itemCartData.subtotal'
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )

                                                                        default:
                                                                            return (
                                                                                <Unidade
                                                                                    unValue={props.input.value}
                                                                                    qt_min={Number.isNaN(values.itemData.qt_min) ? 1 : Number(values.itemData.qt_min)}
                                                                                    setUnValue={
                                                                                        value => {
                                                                                            props.input.onChange(value);
                                                                                            form.mutators.mutatorCalcTotal({
                                                                                                entrada: "subtotal",
                                                                                                qt: value,
                                                                                                preco: values.itemData.preco,
                                                                                                field: 'itemCartData.subtotal'
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                />
                                                                            )
                                                                    }


                                                                }}
                                                            </Field>
                                                        </Grid>

                                                        <Grid item xs={4} style={{ alignSelf: "flex-end", marginBottom: 17 }}>
                                                            {values.itemData.uni_med === "KG" && (
                                                                <Field
                                                                    name="itemCartData.subtotal"
                                                                    type="number"
                                                                >
                                                                    {(props) => (
                                                                        <CurrencyFormat
                                                                            variant="outlined"
                                                                            label="R$"
                                                                            value={props.input.value}
                                                                            name={props.input.name}
                                                                            allowNegative={false}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            isNumericString={true}
                                                                            inputmode="numeric"
                                                                            onValueChange={(result) => {
                                                                                //console.log(result);
                                                                                let value: number = result.value !== "" ? result.floatValue : 0;
                                                                                setChangePreco(true);
                                                                                form.mutators.mutatorCalcTotal({
                                                                                    entrada: "qtde",
                                                                                    subtotal: value,
                                                                                    preco: values.itemData.preco,
                                                                                    field: 'itemCartData.qt'
                                                                                })
                                                                                props.input.onChange(value);
                                                                            }}
                                                                            customInput={TextFieldMaterial}
                                                                        />

                                                                    )}

                                                                </Field>
                                                            )}
                                                            {values.itemData.uni_med !== "KG" && (
                                                                <Typography
                                                                    variant={"h6"}
                                                                    align={"center"}
                                                                >
                                                                    {util.formatMoney(values.itemCartData.subtotal)}
                                                                </Typography>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="itemCartData.obs"
                                                            >
                                                                {props => (
                                                                    <TextFieldMaterial
                                                                        fullWidth
                                                                        label='Observações'
                                                                        name={props.input.name}
                                                                        value={props.input.value}
                                                                        multiline
                                                                        variant="outlined"
                                                                        onChange={props.input.onChange}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                        <Fab 
                                                            color="secondary" 
                                                            className={classes.fab}
                                                            type="submit"
                                                            size='large'
                                                            disabled={
                                                                submitting ||
                                                                pristine ||
                                                                !errors ||
                                                                values.itemCartData.subtotal < 0.01
                                                            }

                                                        >
                                                            <MdAddShoppingCart size={25}/>
                                                        </Fab>
                                                    </Grid>
                                                </form>
                                            )}
                                    </Form>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Container>
                </div>
            )}
        </>
    );
}
