import React, {useState, useEffect, useCallback} from "react";
import LoginToobar from "../../components/toolbar/toolbarList";
import { Container, TextField, Button, LinearProgress, makeStyles, Theme, createStyles, Typography, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { IoMdKey, IoMdLock, IoMdArrowForward, IoMdPerson, IoMdClose } from "react-icons/io";
import Modal from "../../components/Modal";
import Backdrop from "../../components/backdrop/backdrop";
import TextPassword from "../../components/textPassword";
import Cadastro from "./cadastro";
import { Props } from "../../provider/usuario/types";
import Utils from "../../services/utils";
import { MdShoppingCart } from "react-icons/md";
import {
    useHistory,
    useRouteMatch,
    BrowserRouterProps,
} from "react-router-dom";

const utils = new Utils();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
        display: "flex", flexGrow: 1, height: "100%"
    },
    content:{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },    
    container:{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        display:"flex",
        flexDirection:"column",
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        maxWidth:500,
      },
      '& .MuiButton-root': {
        margin: 3,
        maxWidth: 500,
      }
    },
  }),
);
interface CadastroModalProps {
    handleClose(): void;
    open: boolean;
}
function ShowCadastroModal(props: CadastroModalProps) {
    const { handleClose, open } = props;

    return (
        <Modal
            component={
                <Cadastro onclose={handleClose} />
            }
            handleClose={handleClose}
            open={open}
        />
    );
}


interface LoginProps {
    handleClose(): void;
    userProps?:Props
}
function LoginView(props: LoginProps) {
    const { handleClose, userProps } = props;
    const classes = useStyles();
    const [phone, setPhone] = useState("");
    const [readUser, setReadUser] = useState(false);
    const [openCadastro, setOpenCadastro] = useState(false);
    const [textPassword, setTextPassword] = useState("");
    const routerHistory = useHistory();
    const { url } = useRouteMatch();

    const handleCloseCadastro = () => {
        setOpenCadastro(false);
    }

    const handleOpenCadastro = () => {
        userProps?.usuarioActions.createUser()
        setOpenCadastro(true);
    }    

    const changePhone = useCallback((number)=>{
        setPhone(number)
    },[phone]);

    const handleReadUser = useCallback(()=>{
        userProps?.usuarioActions.readUser();
        setReadUser(true);
    },[readUser])

    useEffect(()=>{
        //console.log("chamou")
        //se não tiver usuario no campo, e ainda não fez a leitura, 
        //faz a leitura do usuario
        if(!userProps?.usuarioState.row._id&&!readUser){
            handleReadUser();
        }
        //se tiver usuario logado fecha a tela de login
        if(userProps?.usuarioState.row._id){
            handleClose();
        }
        //se tiver localizado usuario realizando o login
        //testar se tem senha, validar sem senha
        if(userProps?.usuarioState.rowFind._id && utils.isEmpty(userProps?.usuarioState.rowFind.senha)){
            userProps.usuarioActions.loginValidate("",userProps.usuarioState.rowFind);
        }
    },[userProps?.usuarioState.row._id,userProps?.usuarioState.rowFind._id])

    return (
        <div className={classes.root}>
            <div className={classes.content} >
                <Backdrop show={userProps?.usuarioState.loading?true:false} />
                <ShowCadastroModal 
                    handleClose={handleCloseCadastro}
                    open={openCadastro}
                />
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IoMdPerson size={25} />
                        <Typography variant="body1" className={classes.title}>
                            Identificação
                        </Typography>
                        <IconButton
                            title="Sair"
                            aria-haspopup="true"
                            onClick={() => handleClose()}
                            color="inherit"
                            edge="end"
                        >
                            <IoMdClose size={25} />
                        </IconButton>                        
                    </Toolbar>
                </AppBar>                
                <Container className={classes.container}  >
                    {userProps?.usuarioState.loading && (
                        <LinearProgress
                            style={{ margin: 3, minWidth: "max-content" }}
                            color="primary"
                        ></LinearProgress>
                    )}
                    <Button
                        color="primary"
                        onClick={() => handleOpenCadastro() }
                    >
                        Faça o seu cadastro aqui!
                    </Button>                    
                    <IoMdPerson
                        size={120}
                        style={{ color: "silver", marginBottom: 30 }}
                    />
                    <Typography align={"center"} variant={"h4"} color="primary" style={{fontWeight:"bold", marginBottom:20,fontFamily:"Optima, sans-serif"}}>
                        Sua identificação
                    </Typography>

                     <div className={classes.form} >
                        {userProps?.usuarioState.rowFind._id === undefined &&(
                            <>
                            <TextField
                                error={userProps?.usuarioState.error}
                                label="Telefone ou email cadastrado"
                                type="text"
                                value={phone}
                                FormHelperTextProps={{error:userProps?.usuarioState.error}}
                                helperText={userProps?.usuarioState.msg}
                                variant="outlined"
                                onChange={num=>changePhone(num.target.value)}
                                onKeyPress={e=> e.key === "Enter" ? userProps?.usuarioActions.login(phone) : null}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => userProps?.usuarioActions.login(phone)}
                                
                            >
                                Confirmar
                            </Button>
                            </>
                        )}
                        {userProps?.usuarioState.rowFind._id&&userProps?.usuarioState.rowFind.senha !== "" &&(
                            <>
                                <TextPassword
                                    label="Senha de acesso"
                                    value={textPassword}
                                    handleChange={text=>setTextPassword(text.target.value)}
                                    helperText={userProps?.usuarioState.msg}
                                    error={userProps.usuarioState.error}
                                />
                                <Button
                                    endIcon
                                    variant="contained"
                                    color="primary"
                                    disabled={textPassword===""}
                                    onClick={() => userProps.usuarioActions.loginValidate(textPassword,userProps.usuarioState.rowFind) }
                                >
                                    Validar senha
                                    <IoMdArrowForward size={24} />
                                </Button> 
                                <Button
                                    color="primary"
                                    onClick={() =>routerHistory.push(`/sendEmailRecSenha`) }
                                >
                                Esqueci a minha senha!
                            </Button>                                                               
                            </>
                        )}
                    
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default LoginView;
