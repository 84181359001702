import { call, put } from "redux-saga/effects";
import { EstoqueResult,Estoque } from "../provider/types";
import {estoqueProvider} from "../provider";
import * as EstoqueActions from "./actions";

//CREATE
export function* createEstoque(actions:any) {
    try {
        console.log("chamou createEstoque")
        const resp:EstoqueResult = yield call(estoqueProvider.create, actions.payload.prm)
        if (resp.status === "ok") {
            yield put(EstoqueActions.createSuccess(resp.row))    
        } else {
            yield put(EstoqueActions.createFailure("Ouve uma falha ao criar um novo estoque. Detalhes: "+JSON.stringify(resp.mensagem)))
        }
    } catch (error) {
        yield put(EstoqueActions.createFailure("Ouve uma falha na função createEstoque no async. Detalhes: "+JSON.stringify(error)))
    }
}

//READ
export function* readAllEstoque(actions:any){
    try {
        let resp:EstoqueResult = yield call(estoqueProvider.readAll,actions.payload.prm)
        if(resp.status === "ok") {
            yield put(EstoqueActions.readAllSuccess(resp.rows as Estoque[]));
        } else {
            yield put(EstoqueActions.readAllFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(EstoqueActions.readAllFailure("Ocorreu uma falha na estrutura da função readAllEstoque em async. Detalhes: "+JSON.stringify(error)));
    }
}

//update

export function* updateEstoque(actions:any){
    try {
        let resp:EstoqueResult = yield call(estoqueProvider.update,actions.payload.row)
        if(resp.status === "ok") {
            yield put(EstoqueActions.updateSuccess(resp.row as Estoque));
        } else {
            yield put(EstoqueActions.updateFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(EstoqueActions.updateFailure("Ocorreu uma falha na estrutura da função updateEstoque em async. Detalhes: "+JSON.stringify(error)));
    }
}

//delete
export function* deleteEstoque(actions:any) {
    try {
        const resp:EstoqueResult = yield call(estoqueProvider.del, actions.payload.row);
        if (resp.status === "ok") {
            //repassa no success para remover da lista do state
            yield put(EstoqueActions.delSuccess(actions.payload.row));
        } else {
            yield put(EstoqueActions.delFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(EstoqueActions.delFailure("Ouve uma falha na função deleteEstoque em async. Detalhes: "+JSON.stringify(error)))
    }
}


//filtrar
export function* filtrarEstoque(actions:any){
    let resp:EstoqueResult = yield call(estoqueProvider.filtrar,actions.payload.prm)
    yield put(EstoqueActions.filtrarSuccess(resp.rows as Estoque[]));
}
