import React from "react";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Grid, ListItemSecondaryAction, IconButton, makeStyles, createStyles, Theme, ListItemIcon, Checkbox } from "@material-ui/core";
import { IoMdTrash, IoMdCheckbox, IoMdCheckboxOutline } from "react-icons/io";
import { showDataItemCart } from "../../provider/itemCart/types";
import Util from "../../services/utils";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

const util = new Util();
const PATH_IMG = "https://akisuafeira.com.br/ws/App/Upload/";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        title: {
            flexGrow: 1,
        },
        listText: {
            marginLeft: theme.spacing(1),
        },
        textSmall: {
            fontSize: 10,
        },
        textMidle: {
            fontSize: 13,
        },
    })
);

interface ItemListProps {
    item: showDataItemCart;
    handleClickItem(): void;
    removeItem: boolean;
    handleRemoveItem?(): void;
    showCheckbox: boolean;
}
function ItemList(props: ItemListProps) {
    const { item, handleClickItem, removeItem, handleRemoveItem, showCheckbox } = props;
    const classes = useStyles();

    return (
        <ListItem
            alignItems="flex-start"
            button
            onClick={handleClickItem}
        >
            <ListItemAvatar>
                <Avatar
                    alt={item.itemData.descricao}
                    src={
                        util.isEmpty(item.itemData.img)
                            ? "/assets/img/no-avatar.png"
                            : PATH_IMG + item.itemData.img
                    }
                    className={classes.large}
                ></Avatar>
            </ListItemAvatar>
            <ListItemText
                className={classes.listText}
                primary={
                    <span
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h6"
                            color="primary"
                        >
                            {item.itemData.descricao}
                        </Typography>
                        <Typography
                            variant="caption"
                            color="primary"
                        >
                            {util.formatMoney(
                                item.itemCartData.subtotal
                            )}
                        </Typography>
                    </span>
                }
                secondary={
                    <span>
                        <Grid container spacing={1} component="span">
                            <Grid item sm={3} >
                                <Typography
                                    component="span"
                                    variant="caption"
                                >
                                    {"Qtde: "}
                                </Typography>
                            </Grid>
                            <Grid item sm={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="caption"
                                >
                                    {item.itemCartData.qt}{" "}
                                    {item.itemData.uni_med}
                                </Typography>
                            </Grid>
                            <Grid item sm={3}>
                                <Typography variant="caption">
                                    {"Preço: "}
                                </Typography>
                            </Grid>
                            <Grid item sm={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="caption"
                                >
                                    {util.formatMoney(
                                        item.itemCartData.preco
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container component={"span"}>
                            <Grid item sm={12}>
                                <Typography
                                    color="error"
                                    variant={"body2"}
                                >
                                    {item.itemCartData.obs}
                                </Typography>
                            </Grid>
                        </Grid>
                    </span>
                }
            />
            <ListItemSecondaryAction>
                {removeItem && (
                    <IconButton edge="end" color="secondary" onClick={handleRemoveItem} >
                        <IoMdTrash size={25} />
                    </IconButton>
                )}
            </ListItemSecondaryAction>
            
            {showCheckbox && (
                <ListItemIcon>
                    {item.selected && (
                        <IoMdCheckbox size={24} color={"primary"} />
                    )}
                    {!item.selected && (
                        <MdCheckBoxOutlineBlank spacing={3} size={24} color={"primary"} />
                    )}

                </ListItemIcon>
            )}

        </ListItem>
    )
}

export default ItemList;