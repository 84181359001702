import React, { useEffect, useState } from "react"

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "../../store";
import * as UserActions from "../../store/data/user/actions";
import {
    useHistory,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import { makeStyles,createStyles, AppBar, Toolbar, Typography, Container,Grid,Button, CircularProgress } from "@material-ui/core";
import { Usuario, UsuarioDispatch, UsuarioState } from "../../provider/usuario/types";
import usuarioProvider from "../../provider/usuario";
import { FaHome } from "react-icons/fa";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    container:{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent:'center',
        alignItems:'center'
    }
}));

interface ValidarLinkViewProps {
    usuarioState:UsuarioState,
    usuarioActions:UsuarioDispatch,
}


function ValidarLinkView(props:ValidarLinkViewProps){
    const classes = useStyles();
    const routerHistory = useHistory();
    const { url } = useRouteMatch();    
    const { idUser } = useParams<{idUser:string}>()
    const {  usuarioActions, usuarioState } = props
    const { row,loading,error,msg } = usuarioState;
    const [resetConfirmado, setResetConfirmado] = useState(false);
    const [msgReset, setMsgReset] = useState("");

    const consultarUsuario = () => {
        if(idUser){
            usuarioActions.getUser(idUser);
        } else {
            setMsgReset("Link inválido, sua senha não foi apagada.")
        }
    }

    useEffect(()=>{
        consultarUsuario();
    },[])

    useEffect(()=>{
        if(row.resetsenhadt && row.resetsenhadt !== ""){
            usuarioProvider.resetSenha(row).then(resp=>{
                if(resp.status==="ok"){
                    usuarioActions.setValueRow(resp.row as Usuario);
                    setResetConfirmado(true);
                    setMsgReset("A senha foi apagada com sucesso!")
                } else {
                    setMsgReset(resp.mensagem as string)
                }
            })
        } else if(!loading&&row._id){
            setMsgReset("Link inválido, o usuario não solicitou alteração de senha.")
        }
    },[row])

    return(
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position={"sticky"} >
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Validar link de usuario
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={classes.container}>
                    {loading&&(
                        <Typography variant="h6">
                            <CircularProgress size={24}></CircularProgress>
                            Validando link...
                        </Typography>
                    )}
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>  
                        <Typography>
                            {msgReset}
                        </Typography>
                    </Grid>
                        <Grid item xs={12} md={12} sm={12}>  
                        {row._id&&(
                            <Button
                                variant={
                                    "contained"
                                }
                                endIcon
                                color={"primary"}
                                fullWidth={true}
                                onClick={() => routerHistory.push(`/catalogo/itens`)}
                            >   
                                Voltar para o catalogo.
                                <FaHome  size={24}/>
                            </Button>
                        )}
                        </Grid>                        
                    </Grid>
                    
                </Container>                
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    usuarioState: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    usuarioActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidarLinkView);
