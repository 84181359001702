import React, { useState } from "react"

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";

import * as CatalogoActions from "../../store/data/catalogo/actions";
import * as ItemCartActions from "../../store/data/itemCart/actions";
import * as EstoqueActions from "../../modulos/estoque/store/actions";

import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Search from "./components/containerSearch"
import ContainerCategoria from "../../modulos/categorias/view"

import {ListItemLoja} from "./components/itensLoja"
import { FilterItem, Item, ItemDispatch, ItemState } from "../../provider/item/types";
import { ItemCartState,ItemCartDispatch } from "../../provider/itemCart/types";
import ItemCart, { ActionItem } from "../Item/index";
import { EstoqueState,EstoquesActions } from "../../modulos/estoque/provider/types"

import CardListPromocao from "../../modulos/kitpromocao/view/cardListPromocao"
import Modal from "../../components/Modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginBottom: 40,
      backgroundColor:"#e5e2e2",
    },
    containerCategoria:{
        display:"flex", 
        flexDirection:'column',
    },
    containerCatalogo:{
        maxHeight:"65vh",
        overflow:"scroll",
    },
    containerPromocao:{
       display:'flex',
       justifyContent:'center'

    },
    containerItem:{
       display:"flex",
       justifyContent:'flex-start',
       marginTop:3,
    }        
  })
);


 

interface LojaProps{
    catalogActions:ItemDispatch,
    catalogoState:ItemState
    itemCartState:ItemCartState
    itemCartActions:ItemCartDispatch
    estoqueState:EstoqueState
    estoqueActions:EstoquesActions
}
function Loja(props:LojaProps){
    const [filtrarItens, setFiltrarItens] = useState<FilterItem>({categoria:[],codigo:'',descricao:''} as FilterItem)
    const { catalogActions,catalogoState,itemCartState,itemCartActions,estoqueActions,estoqueState } = props;
    const { showForm, loading } = catalogoState;
    const { setShowForm } = props.catalogActions;

    const classes = useStyles();

    const exibirDetalhe = () =>{
        setShowForm()
    }

    const showItemCartView = (itemSelected:Item) => {
        if(itemSelected.qt_estoque>itemSelected.qt_min){
            itemCartActions.createItemCart(itemSelected);
            setShowForm()    
        }
    }  
    
    const filtrarDescCod = (value:any) => {
        console.log('digitado',value);
        let cod:number;
        if(value === ''){
            //limpar filtro
            setFiltrarItens(state=>({...state,descricao:value,codigo:value}))
        } else {
            if(!isNaN(value)){
                cod = value
                console.log('digitado codigo',value);
                setFiltrarItens(state=>({...state,codigo:cod}))
            } else {
                console.log('digitado descricao',value);
                setFiltrarItens(state=>({...state,descricao:value}))
            }
    
        }
            
    }

    const addFiltrarCat = (id:string) => {
        let findCat = filtrarItens.categoria.find(cat=>cat._id === id);
        if(findCat === undefined)//se nao achar adiciona
            setFiltrarItens(state=>({...state, categoria:[...state.categoria, {_id:id} ]}))
    }

    const remFiltrarCat = (idx:number) => {
        let cats = filtrarItens.categoria;
        cats.splice(idx,1);
        setFiltrarItens(state=>({...state, categoria:cats}))
    }

    return(

            <div className={classes.content}>
                
                <Search handleOnChange={filtrarDescCod}/>
                <ContainerCategoria handleFilterItemCatAdd={addFiltrarCat} handleFilterItemCatRem={remFiltrarCat}  />
                <Container maxWidth="md" className={classes.containerCatalogo}>
                    <CardListPromocao />
                    <ListItemLoja 
                        itemState={catalogoState} 
                        itemActions={catalogActions} 
                        handleShowItem={showItemCartView} 
                        filtro={filtrarItens} 
                    />                    
                </Container>

                <Modal 
                    component={ItemCart({
                        row:itemCartState.row,
                        showView:exibirDetalhe, 
                        itemCartActions:itemCartActions,
                        actionItem:ActionItem.insert
                    })}
                    handleClose={exibirDetalhe}
                    open={showForm}
                />                 
            </div>
            
    )
}

const mapStateToProps = (state:ApplicationState) =>({
    catalogoState:state.catalogo,
    itemCartState:state.itemCart,
    estoqueState:state.estoque,
});
const mapDispatchToProps = (dispatch:Dispatch) => ({
    catalogActions: bindActionCreators(CatalogoActions,dispatch),
    itemCartActions: bindActionCreators(ItemCartActions,dispatch),
    estoqueActions: bindActionCreators(EstoqueActions,dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Loja);
