import { call, put } from "redux-saga/effects";
import * as SessaoActions from "./actions";
import * as UserActions from "../user/actions";
import * as PedidoActions from "../pedido/actions";
import { SessaoResult, Sessao } from "../../../provider/start/types";
import sessaoProvider from "../../../provider/start";
import pedidoProvider from "../../../provider/pedido";
import { PedidoResult, ShowDataPedido } from "../../../provider/pedido/types";

export function* createSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.createSessao);
        if(resp.status === "ok"){
            yield put(SessaoActions.createSessaoSuccess(resp.row));
        } else {
            yield put(SessaoActions.createSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.createSessaoFailure("Falha geral no async createSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* updateSessao(actions){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.updateSessao, actions.payload.row);
        if (resp.status === "ok") {
            yield put(SessaoActions.updateSessaoSuccess(resp.row));
        } else {
            yield put(SessaoActions.updateSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.updateSessaoFailure("Falha geral no async updateSessao. Detalhes: "+JSON.stringify(error)));
    }
}

export function* getSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.getSessao);
        if(resp.status === "ok"){
            yield put(SessaoActions.getSessaoSuccess(resp.row));
        } else {
            yield put(SessaoActions.getSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.getSessaoFailure("Falha geral no async getSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* delSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.delSessao);
        if(resp.status === "ok"){
            yield put(SessaoActions.delSessaoSuccess());
        } else {
            yield put(SessaoActions.delSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.delSessaoFailure("Falha geral no async delSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* clearUserSessao(){
    try {
        //console.log('chamou clearUserSessao');
        let resp:SessaoResult = yield call(sessaoProvider.clearUserSessao);
        if(resp.status === "ok"){
            yield put(SessaoActions.clearUserSessaoSuccess());
            yield put(UserActions.logOff());
            yield put(SessaoActions.setNotSessaoStart())
        } else {
            yield put(SessaoActions.clearUserSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.clearUserSessaoFailure("Falha geral no async clearUserSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* setUserSessao(action){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.getSessao)
        if (resp.status === "ok") {
            resp.row.user = action.payload.user
            yield put(SessaoActions.updateSessao(resp.row));
            yield put(SessaoActions.checkSessaoStarted());
        } else {
            yield put(SessaoActions.setUserSessaoFailure("Falha na busca da sessão para fazer a atualização."));
        }
    } catch (error) {
        yield put(SessaoActions.setUserSessaoFailure("Falha geral no async setUserSessao. Detalhes: "+JSON.stringify(error)));
    }
}

export function* clearCartSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.clearCartSessao);
        if(resp.status === "ok"){
            yield put(SessaoActions.clearCartSessaoSuccess());
        } else {
            yield put(SessaoActions.clearCartSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.clearCartSessaoFailure("Falha geral no async clearCartSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* checkSessaoStarted(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.checkSessaoStarted);
        if(resp.status === "ok"){
            yield put(PedidoActions.readAllPedidoAndamento(resp.row.user));
            yield put(UserActions.readUserSuccess(resp.row.user));
            yield put(SessaoActions.setSessaoStart(resp.row));
        } else {
            yield put(SessaoActions.createSessao())
        }
    } catch (error) {
        yield put(SessaoActions.setNotSessaoStart())
    }
}

export function* setInstallAppSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.setInstallAppSessao);
        if(resp.status === "ok"){
            //yield put(SessaoActions.clearCartSessaoSuccess());
        } else {
            //yield put(SessaoActions.clearCartSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
       // yield put(SessaoActions.clearCartSessaoFailure("Falha geral no async clearCartSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* setUninstallAppSessao(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.setUninstallAppSessao);
        if(resp.status === "ok"){
            //yield put(SessaoActions.clearCartSessaoSuccess());
        } else {
            //yield put(SessaoActions.clearCartSessaoFailure(resp.mensagem as string));
        }
    } catch (error) {
       // yield put(SessaoActions.clearCartSessaoFailure("Falha geral no async clearCartSessao. Detalhes "+JSON.stringify(error))); 
    }
}

export function* createRegistroLGPD(){
    try {
        let resp:SessaoResult = yield call(sessaoProvider.createRegistroLGPD);
        if(resp.status === "ok"){
            yield put(SessaoActions.updateSessao(resp.row))
            yield put(SessaoActions.createRegistroLGPDSuccess(resp.row.lgpd));
        } else {
            yield put(SessaoActions.createRegistroLGPDFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(SessaoActions.createRegistroLGPDFailure("Falha geral no async createRegistroLGPD. Detalhes "+JSON.stringify(error))); 
    }
}