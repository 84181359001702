import {Reducer} from "redux";
import { CategoriaState, CategoriaActionsTypes } from "../provider/types";
import CategoriaProvider from "../provider";
import categoriaProvider from "../provider";

const INITIAL_STATE : CategoriaState = {
    row:CategoriaProvider.newDataCategoria,
    rows:[],
    rowsSelected:[],
    error:false,
    loading:false,
    msg:"",
    showForm:false,
    total_rows:0,
};

const reducer:Reducer<CategoriaState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //CREATE
        case CategoriaActionsTypes.CREATE_CATEGORIA:
            return { ...state, loading: true, error: false }
        case CategoriaActionsTypes.CREATE_CATEGORIA_SUCCESS:
            return { ...state, loading: false, row: action.payload.row }
        case CategoriaActionsTypes.CREATE_CATEGORIA_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }
        
        //READ
        case CategoriaActionsTypes.READ_ALL_CATEGORIA:
            return {...state, loading:true, error:false, msg:""}
        case CategoriaActionsTypes.READ_ALL_CATEGORIA_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
        case CategoriaActionsTypes.READ_ALL_CATEGORIA_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg }
        
        //UPDATE
        case CategoriaActionsTypes.UPDATE_CATEGORIA:
            return { ...state, loading: true, error: false }
        case CategoriaActionsTypes.UPDATE_CATEGORIA_SUCCESS:
            return { ...state, loading: false, error: false, row: action.payload.row }
        case CategoriaActionsTypes.UPDATE_CATEGORIA_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        //DELETE
        case CategoriaActionsTypes.DELETE_CATEGORIA:
            return { ...state, loading: true, error: false }
        case CategoriaActionsTypes.DELETE_CATEGORIA_SUCCESS:
            return { ...state, loading: false, error: false }
        case CategoriaActionsTypes.DELETE_CATEGORIA_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        case CategoriaActionsTypes.ADD_CAT_SELECTED:
            let findCats = state.rowsSelected.find(cat=>cat._id===action.payload.row._id)
            if(findCats === undefined) {
                return {...state, rowsSelected:[...state.rowsSelected, action.payload.row]}
            } else {
                return state
            }
        case CategoriaActionsTypes.DELETE_CAT_SELECTED:
            let rowsSel = state.rowsSelected;
            CategoriaProvider.excluirLinhaArray(rowsSel,action.payload.idx)
            return {...state, rowsSelected:rowsSel}

        case CategoriaActionsTypes.SET_SHOW_FORM:
            return {...state, showForm:!state.showForm };

        case CategoriaActionsTypes.SET_ROW_CATEGORIA:
            return {...state, showForm:!state.showForm, row:action.payload.row };

        case CategoriaActionsTypes.CLEAR_ERROR_CATEGORIA:
            return {...state, error:false,msg:"" }

        default:
            return state
    }
}
export default reducer;