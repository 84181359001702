import React, { useEffect, useState, useContext } from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../store";

import { AppBar, Toolbar, Typography, IconButton, makeStyles, createStyles, LinearProgress, List, Divider, Switch, Button, Container, CircularProgress, ListItem, ListItemText, ListItemSecondaryAction, Collapse, ListSubheader, Theme, FormControl, InputLabel, Select, MenuItem, DialogTitle } from "@material-ui/core";
import { MdClose, MdList, MdArrowBack, MdArrowForward, MdSelectAll, MdAddShoppingCart, MdExpandLess,MdExpandMore } from "react-icons/md";

import { TipoEntregaState,EntregaPedido,TipoEntregaActions, DiaHoraEntrega } from "../provider/types";
import * as ActionsTipoEntrega from "../store/actions"

import Modal from "../../../components/Modal";
import { formatMoney } from "../../../utilsJs";


const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
        height: '100%',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    container:{
        display:"flex",
        flexDirection:"column",
        flex:1,
        overflowY:'scroll',
    },
    listSubheader:{
        width:'100%',
        backgroundColor:theme.palette.background.paper
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },    

}))

interface PropsViewSelecionarTipoEntregaModal extends PropsListTipoEntregas {
    open: boolean;
}
export function ViewSelecioarTipoEntregaModal(props: PropsViewSelecionarTipoEntregaModal) {
    const { handleClose, open,tipoEntregaActions,tipoEntregaState,setValue,totalPedido } = props;
    console.log("total pedido",totalPedido)
    return (
      <Modal
        component={
            <ViewListTipoEntrega 
                handleClose={handleClose} 
                tipoEntregaActions={tipoEntregaActions} 
                tipoEntregaState={tipoEntregaState} 
                setValue={setValue}
                totalPedido={totalPedido}
            />
        }
        handleClose={handleClose}
        open={open}
      />
    );
  }

interface PropsListTipoEntregas {
    tipoEntregaState:TipoEntregaState;
    tipoEntregaActions:TipoEntregaActions
    handleClose(): void;
    setValue(value:EntregaPedido,ixdDia:number,diaHoraPerson?:DiaHoraEntrega):void;
    totalPedido:number;
}

function ViewListTipoEntrega(props: PropsListTipoEntregas) {
    const { handleClose,setValue,totalPedido } = props;
    const { rows } =props.tipoEntregaState;
    const { readAll,filtrar,setRow } = props.tipoEntregaActions
    const [showSelectItem, setShowSelectItem] = useState(false);
    const [colapseItem, setColapseItem] = useState(-1);
    const [diaHoraSelected, setDiaHoraSelected] = useState<DiaHoraEntrega>({} as DiaHoraEntrega);
    const classes = useStyles();

    useEffect(()=>{
        readAll()
    },[])

    const handleClickItemList = (index:number) => {
        if(index!==colapseItem){
            setColapseItem(index);
        } else {
            setColapseItem(-1);
        }
    }

    const diaHoraEntregaDisponivel = (diaHoraEntrega:DiaHoraEntrega, personalizado:boolean) => {
        let dt = new Date();
        let diaAtual = dt.getDay();
        let horaAtual = dt.getHours();
        let minAtual = Number(dt.getHours()+"."+dt.getMinutes());
        let diaEntrega = diaHoraEntrega.day;
        let IseparatorHora = diaHoraEntrega.hora.search(":");
        let startMin = IseparatorHora + 1; //+mais um para pular o :
        let horaEntrega = Number(diaHoraEntrega.hora.substring(0,IseparatorHora));
        let minEntrega = Number(diaHoraEntrega.hora.replace(":","."));
/*         console.log("diaAtual",diaAtual)
        console.log("horaAtual",horaAtual)
        console.log("minAtual",minAtual)
        console.log("diaEntrega",diaEntrega)
        console.log("horaEntrega",horaEntrega)
        console.log("minEntrega",minEntrega)
  */    if(diaEntrega < diaAtual){
            //remove os dias passados
            return false 
        } else {
            if(diaEntrega > diaAtual){
                if(diaEntrega===diaAtual+1)
                    diaHoraEntrega.dia = "Amanhã";
                return true
            } else {
                if(!personalizado){//se não for personalizado, verifica o horario
                    if(horaEntrega > horaAtual) {
                        if(diaEntrega===diaAtual)
                            diaHoraEntrega.dia = "hoje";
                        return true;
                    } else if(minEntrega > minAtual) {
                        return true;
                    } else {
                        return false;
                    }                        
                } else {//se for personalizado, não verifica o horario
                    return true
                }
            }
        }
    }

    const filterDiaEntrega = (diaEntrega:any) => {
        let dt = new Date();
        let diaAtual = dt.getDay();
        if(diaEntrega.day < diaAtual){
            //remove os dias passados
            return false 
        } else if(diaEntrega.day > diaAtual){
            if(diaEntrega.day===diaAtual+1)
                diaEntrega.dia = "Amanhã";
            return true
        } else {
            diaEntrega.dia = "Hoje";
            return true
        }       
    }

    const filtraHoraEntrega = (horarioEntrega:any) => {
        //verificar se o dia ja foi selecionado
        if(diaHoraSelected.day){
            //verficar as configuracoes do dia
            let configDia = CDiaHoraEntrega.dias.find(dia=>dia.day===diaHoraSelected.day);
            //se o dia for aberto
            if(configDia?.aberto){

                let dt = new Date();
                dt.setMinutes(dt.getMinutes()+CDiaHoraEntrega.minTolerancia)
                let horaAtual = dt.getHours();
                let minAtual = Number(dt.getHours()+"."+dt.getMinutes());
                let diaAtual = dt.getDay();
                let IseparatorHora = horarioEntrega.search(":");
                let horaEntrega = Number(horarioEntrega.substring(0,IseparatorHora));
                let minEntrega = Number(horarioEntrega.replace(":","."));

                const regraHorario = () => {
                    //se o dia atual for igual ao dia selecionado
                    //faz a verificacao da hora
                    if(diaAtual === diaHoraSelected.day){
                        if(horaEntrega > horaAtual) {
                            console.log("exibir horaEntrega 1"+horaEntrega)
                            return true;
                        } else if(minEntrega >= minAtual) {
                            console.log("exibir horaEntrega 2"+horaEntrega)
                            return true;
                        } else {
                            console.log("nao passou horaAtual"+horaAtual)
                            return false;
                        }          
                    } else {
                        //se nao, retorna todos os horarios disponivel no dia
                        //nao filtra a hora do dia
                        return true
                    }
                }

                if(!configDia.limitHora){
                    //horario sem limites
                    return regraHorario();
                } else {
                    //filtrando horario com limites
                    console.log("configDia",configDia)
                    let horaEntregaMinimo = Number(configDia.horaIni?.substring(0,IseparatorHora))
                    let minEntregaMinimo = Number(configDia.horaIni?.replace(":","."))
                    let horaEntregaMaximo = Number(configDia.horaFim?.substring(0,IseparatorHora))
                    let minEntregaMaximo = Number(configDia.horaFim?.replace(":","."))
                    console.log("horaEntrega",horaEntrega);
                    console.log("horaEntregaMinimo",horaEntregaMinimo);
                    console.log("horaEntregaMaximo",horaEntregaMaximo);
                    console.log("minEntregaMinimo",minEntregaMinimo);
                    console.log("minEntregaMaximo",minEntregaMaximo);
                    if(horaEntrega >= horaEntregaMinimo && horaEntrega <= horaEntregaMaximo){
                        if(minEntrega >= minEntregaMinimo && minEntrega <= minEntregaMaximo){
                            console.log("passou limit horario",horaEntrega)
                            return regraHorario();
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }   
            } else {
                return false
            }
        } else {
            return false
        }

    }

    const setEntregaSelected = (entrega:EntregaPedido,index:number) => {
        //verifica se o tipo de entrega é personalizado e se o horario foi preenchido
        if(entrega.personalizado){
            if(diaHoraSelected.dia&&diaHoraSelected.hora){
                setValue(entrega,index,diaHoraSelected);
                handleClose();
            } else {
                //informe o horario da entrega
            }
        } else {
            setValue(entrega,index);
            handleClose();
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={handleClose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Selecione o Tipo de Entrega
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={classes.container}>
                    <List >
                        {rows.map((entrega, idx) => (
                            <div key={idx}>
                                <ListItem
                                    alignItems="flex-start"
                                    button
                                    onClick={()=>handleClickItemList(idx)}
                                    disabled={!entrega.enabled}
                                    
                                >
                                    <ListItemText
                                        primary={entrega.descricao}
                                        secondary={
                                            <>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {entrega.detalhes}
                                                </Typography>
                                                {entrega.valor_min>0&&totalPedido<=entrega.valor_min&&(
                                                    <Typography variant="caption" color="error">
                                                        Valor mínimo para o pedido é de {formatMoney(entrega.valor_min)}
                                                    </Typography>
                                                )}                                                
                                            </>
                                        }
                                    >

                                    </ListItemText>
                                    <ListItemSecondaryAction onClick={()=>handleClickItemList(idx)}>
                                        {entrega.custo>0&&formatMoney(entrega.custo)}
                                        {idx===colapseItem ? <MdExpandLess size={24}/> : <MdExpandMore size={24}/>}                                        
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={idx===colapseItem} timeout="auto" unmountOnExit>
                                    <List 
                                        component="div" 
                                        disablePadding
                                        className={classes.listSubheader}
                                        subheader={<ListSubheader>{entrega.descricao}</ListSubheader>}
                                    >
                                        {!entrega.personalizado?entrega.data_hora_entrega.map((dia,i)=>{
                                            if(diaHoraEntregaDisponivel(dia,entrega.personalizado)){
                                                return(
                                                    <ListItem
                                                        key={i}
                                                        alignItems="flex-start"
                                                        button
                                                        onClick={()=>setEntregaSelected(entrega,i)}
                                                    >
                                                        <ListItemText
                                                            primary={dia.dia}
                                                        />                                                            
                                                        <ListItemSecondaryAction onClick={()=>setEntregaSelected(entrega,i)}>A partir das: {dia.hora}</ListItemSecondaryAction>
                                                 
                                                    </ListItem>
                                                )
                                            }
                                        }):(
                                                <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <div>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel id="selec-dia">Dia:</InputLabel>
                                                                <Select
                                                                    labelId="selec-dia"
                                                                    value={diaHoraSelected.dia}
                                                                    onChange={(event)=> setDiaHoraSelected(state=>({...state,day:CDiaHoraEntrega.dias[event.target.value as number].day, dia:CDiaHoraEntrega.dias[event.target.value as number].dia}))}
                                                                >
                                                                    {CDiaHoraEntrega.dias.map((value,i)=>{
                                                                        if(filterDiaEntrega(value)){
                                                                            return (<MenuItem value={i}>{value.dia}</MenuItem>)
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </FormControl>

                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel id="selec-hora">A partir das:</InputLabel>
                                                                <Select
                                                                    labelId="selec-hora"
                                                                    value={diaHoraSelected.hora}
                                                                    onChange={(event)=> setDiaHoraSelected(state=>({...state, hora:event.target.value as string}))}
                                                                    disabled={!diaHoraSelected.day}
                                                                >
                                                                    {CDiaHoraEntrega.horarios.map(hora=>{
                                                                        if(filtraHoraEntrega(hora)){
                                                                            return(
                                                                                <MenuItem value={hora}>{hora}</MenuItem>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                    }
                                                />                                                            

                                                <ListItemSecondaryAction>
                                                    <IconButton 
                                                        disabled={!diaHoraSelected.dia||!diaHoraSelected.hora}
                                                        onClick={()=>setEntregaSelected(entrega,idx)}
                                                        color="primary"
                                                    >
                                                        <MdArrowForward size={25} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                            
                                        }
                                    </List>
                                </Collapse>
                                <Divider />
                            </div>
                        ))}
                    </List>
                </Container>

            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    tipoEntregaState: state.tipoentrega,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    tipoEntregaActions: bindActionCreators(ActionsTipoEntrega, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewListTipoEntrega);

interface ConfigDiaHoraEntrega {
    horarios:string[],
    dias:Array<
        {day:number,dia:string,aberto:boolean,limitHora:boolean,horaIni?:string,horaFim?:string}
    >
    minTolerancia:number
}
const CDiaHoraEntrega:ConfigDiaHoraEntrega = {
    minTolerancia:30,
    horarios:[
        "8:00",
        "8:30",
        "9:00",
        "9:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30"    
    ],
    dias:[
        {
            day: 0,
            dia: "Domingo",
            aberto:false,
            limitHora:false,
        },
        {
            day: 1,
            dia: "Segunda",
            aberto:true,
            limitHora:false,
        },
        {
            day: 2,
            dia: "Terça",
            aberto:true,
            limitHora:false,
        },
        {
            day: 3,
            dia: "Quarta",
            aberto:true,
            limitHora:false,
        },
        {
            day: 4,
            dia: "Quinta",
            aberto:true,
            limitHora:false,
        },
        {
            day: 5,
            dia: "Sexta",
            aberto:true,
            limitHora:false,
        },
        {
            day: 6,
            dia: "Sábado",
            aberto:true,
            limitHora:true,
            horaIni:"11:00",
            horaFim:"14:00",
        }          

    ]
}
