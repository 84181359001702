import { Categoria,CategoriaColumnsType,CategoriaResult,CategoriaDispatch } from "./types"
import Utils from "../../../services/utils"

class CategoriaProvider extends Utils implements CategoriaDispatch {
    
    public rows:Categoria[] = [];
    public tipo:string = "categoria";
    public newDataCategoria:Categoria = {
        _id:undefined,
        _rev:undefined,
        tipo:this.tipo,
        descricao:"",
        imagem:"",
        categoria_pai:{_id:"",descricao:""}
    }

    createCategoria = () : CategoriaResult => {
        return {status:"ok", row:this.newDataCategoria}
    }

    readAllCategorias = async () : Promise<CategoriaResult> => {
        try {
            let opt = {
                selector:{
                    tipo:this.tipo
                },
                use_index:'index-tipo'
            }
            let result = await this.findRemote(opt);
            this.rows = result.docs as Categoria[];
            return {status:"ok", rows:this.rows}

        } catch (error) {
            return {status:"error", mensagem:"Ocorreu uma falha na estrutura da função readAll de CategoriaProvider. Detalhes "+ JSON.stringify(error)}
        }
    }

    updateCategoria = async (row: Categoria): Promise<CategoriaResult> => {
        try {
            let result = await this.salvarRemoto(row);
            if (result.status) {
                return {status:"ok",row:result.row as Categoria};
            } else {
                return {status:"error", mensagem:result.erro as string}
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função updateCategoria em CategoriaProvider. Detalhes:"+JSON.stringify(error)}
        }
    }

    delCategoria = async(row: Categoria): Promise<CategoriaResult>   => {
        try {
            let result = await this.deleteDataRemote(row);
            if (result.ok) {
                return {status:"ok"};
            } else {
                return {status:"error", mensagem:"Ocorreu uma falha, o meio de pagamento não foi removido."};
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função delCategoria em CategoriaProvider"+JSON.stringify(error)}
        }
    }
    addCatSelected = (row: Categoria) => {};

    delSelected = (idx: number) => {

    }    

    filtrar  = async (value:any) :Promise<CategoriaResult> => {
        try {
            if(this.rows.length === 0){
                await this.readAllCategorias()
            }
            const rowsFilter = this.filterAllArray(this.rows,value);
            if (rowsFilter.ok) {
                return { status:"ok", rows:rowsFilter.data }
            } else {
                return { status:"error", mensagem:'Ocorreu uma falha na consulta dos meios de pagamento. Detalhes: '+rowsFilter.mgs }
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função filtrar em CategoriaProvider. Detalhes:"+JSON.stringify(error)}
        }
    }   

    clearErroCategoria = () => {}

    setRowCategoria = (row:Categoria) => {}

}

const categoriaProvider = new CategoriaProvider()
export default categoriaProvider