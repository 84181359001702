import { Reducer } from "redux";
import { SessaoActionsTypes, SessaoState, Sessao } from "../../../provider/start/types";
import { Usuario } from "../../../provider/usuario/types";

const INITIAL_STATE:SessaoState = {
    row:{} as Sessao,
    rows:[],
    error:false,
    loading:false,
    msg:"",
    showForm:false,
    total_rows:0,
    sessaoStarted:false,
};

const reducer:Reducer<SessaoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SessaoActionsTypes.CREATE_SESSAO:
            return {...state, loading:true, error:false,msg:""};
        case SessaoActionsTypes.CREATE_SESSAO_SUCCESS:
            return {...state, loading:false, error:false,msg:"", row:action.payload.row, sessaoStarted:true}    
        case SessaoActionsTypes.CREATE_SESSAO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg, sessaoStarted:false};
        
        case SessaoActionsTypes.GET_SESSAO:
            return {...state, loading:true, error:false, msg:""};
        case SessaoActionsTypes.GET_SESSAO_SUCCESS:
            return {...state, loading:true, error:false, msg:"", row:action.payload.row};
        case SessaoActionsTypes.GET_SESSAO_FAILURE:
            return {...state, loading:false, error:false, msg:action.payload.msg};
        
        case SessaoActionsTypes.UPDATE_SESSAO:
            return {...state, loading:true, error:false, mgs:""};
        case SessaoActionsTypes.UPDATE_SESSAO_SUCCESS:
            return {...state, loading:false, error:false, row:action.payload.row };
        case SessaoActionsTypes.UPDATE_SESSAO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg };

        case SessaoActionsTypes.SET_USER_SESSAO:
            return {...state, error:false, msg:"", row:{...state.row, user:action.payload.user} }
        case SessaoActionsTypes.SET_USER_SESSAO_FAILURE:
            return {...state, error:true, msg:action.payload.msg}    

        case SessaoActionsTypes.CLEAR_USER_SESSAO:
            return {...state, loading:true, error:false, msg:""};
        case SessaoActionsTypes.CLEAR_USER_SESSAO_SUCCESS:
            console.log('CLEAR_USER_SESSAO_SUCCESS');
            return {...state, loading:false, error:false, msg:"", row:{...state.row, user:{} as Usuario } };
        case SessaoActionsTypes.CLEAR_CART_SESSAO_FAILURE:
            return {...state, loadign:false, error:false, msg:action.payload.msg};

        case SessaoActionsTypes.CLEAR_CART_SESSAO:
            return {...state, loading:true, error:false, msg:""};
        case SessaoActionsTypes.CLEAR_CART_SESSAO_SUCCESS:
            return {...state, loading:false, error:false, msg:"", row:{...state.row, cart:[] } };
        case SessaoActionsTypes.CLEAR_CART_SESSAO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case SessaoActionsTypes.DEL_SESSAO:
            return {...state, loading:true, error:false, msg:"",}
        case SessaoActionsTypes.DEL_SESSAO_SUCCESS:
            return {...state, loading:false, error:false, msg:"", row:{} as Sessao, sessaoStarted:false }
        case SessaoActionsTypes.DEL_SESSAO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};
        
        case SessaoActionsTypes.SESSAO_STARTED:
            return {...state, loading:true}
        case SessaoActionsTypes.SET_SESSAO_START:
            return {...state, loading:false, sessaoStarted:true, row:action.payload.row};
        case SessaoActionsTypes.SET_NOT_SESSAO_STARTED:
            console.log('SET_NOT_SESSAO_STARTED');
            return {...state, loading:false, sessaoStarted:false};

        case SessaoActionsTypes.SET_INSTALL_APP_SESSAO:
            return {...state, row:{ ...state.row, appIsInstall:true} }
        case SessaoActionsTypes.SET_UNINSTALL_APP_SESSAO:
            return {...state, row:{ ...state.row, appIsInstall:false} }

        case SessaoActionsTypes.CREATE_LGPD:
            return {...state, loading:true, error:false, msg:""}
        case SessaoActionsTypes.CREATE_LGPD_SUCCESS:
            return {...state, loading:false, row:{...state.row, lgpd:action.payload.row}}
        case SessaoActionsTypes.CREATE_LGPD_FAILURE:
            return {...state, loading:false, error:true,msg:action.payload.msg}
            
        default:
            return state;
    }
}

export default reducer;