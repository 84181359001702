import { Item } from "../../../provider/item/types";
import {GlobalResult,GlobalStates,ColumnTable,GlobalDoc,GlobalDispatch } from "../../../services/globalTypes";

export interface KitPromocao extends GlobalDoc {
    tipo:"kit-promocao";
    descricao:string;
    detalhes:string;
    img:string;
    desconto:number;
    ativo:boolean;
    itens:ItemPromocao[];
}

export interface ItemPromocao {
    _id:string;
    qt:number;
    valor:number;
    item:Item
}

export enum KitPromocaoActionsType {
    //CREATE
    CREATE = "@KitPromocao/CREATE",
    CREATE_SUCCESS = "@KitPromocao/CREATE_SUCCESS",
    CREATE_FAILURE = "@KitPromocao/CREATE_FAILURE",

    //READ
    READ_ALL = "@KitPromocao/READ_ALL",
    READ_ALL_SUCCESS = "@KitPromocao/READ_ALL_SUCCESS",
    READ_ALL_FAILURE = "@KitPromocao/READ_ALL_FAILURE",

    //UPDATE
    UPDATE = "@KitPromocao/UPDATE",
    UPDATE_SUCCESS = "@KitPromocao/UPDATE_SUCCESS",
    UPDATE_FAILURE = "@KitPromocao/UPDATE_FAILURE",

    //DELETE
    DELETE = "@KitPromocao/DELETE",
    DELETE_SUCCESS = "@KitPromocao/DELETE_SUCCESS",
    DELETE_FAILURE = "@KitPromocaoDELETE_FAILURE",

    //FILTRO ARRAY

    FILTRAR = "@KitPromocao/FILTRAR",
    FILTRAR_SUCCESS = "@KitPromocao/FILTRAR_SUCCESS",

    SET_ROW = "@KitPromocao/SET_ROW",

    SET_SHOW_FORM = "@KitPromocao/SET_SHOW_FORM",

    CLEAR_ERROR = "@KitPromocao/CLEAR_ERROR",
}

export interface KitPromocaoResult extends GlobalResult {}
export interface KitPromocaoState extends GlobalStates<KitPromocao> {}
export interface KitPromocaosActions extends GlobalDispatch<KitPromocao>{}
