import React from "react"

import { makeStyles,createStyles, Box, Typography, Button, Card, AppBar, Toolbar, IconButton, Container } from "@material-ui/core";
import QRCode from "react-qr-code";
import { FaWhatsapp } from "react-icons/fa";


const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    container:{
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        justifyContent:"space-between",
        flexGrow: 1,
        backgroundColor:"#128C7E"
    },
    title: {
        flexGrow: 1,
    },
    subtitle:{
        color:"white",
        textAlign:"justify",
    },
    card:{
        display:"flex",
        flex:1,
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
    },
    img:{
        width:"100%",
        height:"auto"
    }
}));

export default function GroupOfertaView(){
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div 
                className={classes.content}

            >
                <AppBar position={"sticky"} >
                    <Toolbar>
                         <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                        >
                            <FaWhatsapp />
                        </IconButton>

                        <Typography variant="h6" className={classes.title}>
                            Grupo de Ofertas Akisuafeira
                        </Typography>
                    </Toolbar>
                </AppBar> 

                <Container className={classes.container} >
                    <Box >
                        <Typography variant="h6" className={classes.subtitle}>Entre no grupo e receba notificações sobre promoções, descontos especiais e horário da chegada das mercadorias novas</Typography>     
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            flex:1,
                            justifyContent:"center",
                            flexDirection:"column",
                            alignItems:"center",
                            maxHeight:300,
                            maxWidth:300,
                            
                        }}
                    >
                        <Card  className={classes.card}>
                            <QRCode  size={128} value="https://chat.whatsapp.com/GnjieUXXIs64ZFodvF4ejJ"/>
                        </Card>
                        <Typography variant="subtitle2" className={classes.subtitle}>Escanei o QR Code, ou clique no botão abaixo para entrar no grupo de ofertas especiais</Typography>     
                    </Box>
                    <Box 
                        sx={{margin:5}}
                    >
                        <Button
                            target="_blank"
                            href="https://chat.whatsapp.com/GnjieUXXIs64ZFodvF4ejJ"
                            variant="contained"
                            color="secondary"
                        >
                            Entrar no grupo
                        </Button>
                    </Box>
                </Container>
            </div>
        </div>
    )
}