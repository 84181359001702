import React, {useEffect,useState} from "react"

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../store";

import * as ActionsKitPromocao from "../store/actions";
import { KitPromocaoState,KitPromocaosActions,KitPromocao } from "../provider/types"

import * as PedidoItensActions from "../../../store/data/pedidoItens/actions";
import { PedidoItensDispatch,PedidoItensState } from "../../../provider/pedidoItens/types";


import { createStyles, makeStyles, Theme, Paper,Typography,List,ListItem, ImageListItem, ImageListItemBar, ImageList,CircularProgress } from "@material-ui/core";
import { soma,formatMoney } from "../../../utilsJs"
import {ShowPedidoItensModal } from "./listItensPromocao"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        overflow: 'hidden',
        //backgroundColor: theme.palette.background.paper,
        maxHeight:150,
    },   
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },       
    containerList:{
        display: 'flex',
        flexDirection:"row",
        padding: 0 ,
        '& > *': {
            margin: theme.spacing(1),
            minWidth: theme.spacing(20),
            minHeight: theme.spacing(20),
          },
          [theme.breakpoints.down('sm')]:{
            maxWidth:390,
            overflowX:'scroll',
            scrollbarWidth:"none"
          },
    },
    titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },    
    img:{
        width:'100%',
        height:'inherit',
        padding:2,              
        maxWidth: '100%', 
    },
    containerTextImg:{
        maxWidth:"100%",
        display:"flex",
        flex:1,
        flexDirection:"column",
        backgroundColor:"rgba(62, 73, 15, 0.2)",
        color:"white",
    },
    title:{
        flexGrow:1,
        color:"#fff"
    },
    containerTitle:{
        display:"flex",
        flex:1,
        justifyContent:"center"
    },
    containerDescricao:{
        position: 'absolute',
        borderTopLeftRadius:30,
        top: '28%',
        right: 0,
        display:"flex",
        flex:1,
        flexDirection:"column",
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        overflow: "hidden" ,
        padding:theme.spacing(1),       
    },
    containerListImagemItem:{
        cursor:'pointer',
        paddingInlineEnd:2,
    },

    

  })
);
const PATH_IMG = "https://akisuafeira.com.br/ws/App/Upload/";

interface PromocaoItemProps{
    row:KitPromocao
    handleOnClick(row:KitPromocao):void
}
function PromocaoItem(props:PromocaoItemProps){
    const {handleOnClick} = props;
    const { descricao,detalhes,img, itens  } = props.row
    const classes = useStyles();
    const valor = soma(itens,"qt","valor")

    return(
        <ImageListItem className={classes.containerListImagemItem} onClick={()=>handleOnClick(props.row)}>
            <img src={PATH_IMG+img} alt={descricao} className={classes.img} />
            <ImageListItemBar
                title={descricao}
                subtitle={detalhes}
                classes={{
                    root:classes.titleBar,
                }}
            />
            <div className={classes.containerDescricao}>
                <Typography  variant="h6" className={classes.title} noWrap  >{formatMoney(valor)}</Typography>
            </div>            
        </ImageListItem>        
    )
}

interface PropsPromocaoListView{
    kitpromocaoState:KitPromocaoState
    kitpromocaoActions:KitPromocaosActions
    pedidoItensActions:PedidoItensDispatch;
    pedidoItensState:PedidoItensState
}

function PromocaoListView(props:PropsPromocaoListView){
    const { rows,total_rows,showForm,loading } = props.kitpromocaoState
    const [openItensPromocao, setOpenItensPromocao] = useState(false);
    const { readAll, setRow } = props.kitpromocaoActions;
    const classes = useStyles();
    console.log("rows listPromocao",rows);
    useEffect(()=>{
        if(total_rows === 0){
            readAll({
                type:"view",
                view:{
                    qryName:"item/kitpromocao",
                    opt:{include_docs:true}
                }
            })
        }
    },[total_rows])

    const handleOnClick = (row:KitPromocao) => {
        setRow(row);
        setOpenItensPromocao(true);
    }

    const handleOnCloseItensPromocao = () => {
        props.pedidoItensActions.setSendToCart(false);
        setOpenItensPromocao(false)
    }
    return(
        <div className={classes.rootContainer}>
            <ShowPedidoItensModal 
                handleClose={handleOnCloseItensPromocao}
                kitpromocaoState={props.kitpromocaoState}
                pedidoItensActions={props.pedidoItensActions}
                pedidoItensState={props.pedidoItensState}
                open={openItensPromocao}
            />            
            {loading?(
                <CircularProgress color="primary"/>
            ):(
                <ImageList className={classes.imageList} cols={1}>
                {
                    rows.map((item,i)=>(
                        <PromocaoItem
                            key={item._id} 
                            row={item}
                            handleOnClick={handleOnClick}
                        />
                    ))
                }
                </ImageList>
            )}
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    kitpromocaoState: state.kitpromocao,
    pedidoItensState:state.pedidoItens,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    kitpromocaoActions: bindActionCreators(ActionsKitPromocao, dispatch),
    pedidoItensActions: bindActionCreators(PedidoItensActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PromocaoListView);


