import { Reducer } from "redux";
import { ItemActionsTypes, ItemState } from "../../../provider/item/types";
import ItemProvider from "../../../provider/item/index"
import Item from "../../../view/Item";
import { estoqueProvider } from "../../../modulos/estoque/provider";

const INITIAL_STATE: ItemState = {
    error:false,
    loading: false,
    msg: "",
    row:ItemProvider.dataItem,
    rows:[],
    showForm:false,
    total_rows: 0,
}

const reducer:Reducer<ItemState> = (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case ItemActionsTypes.READ_ALL_ITENS:
            return {...state, loading:true, error:false, msg:""};
        case ItemActionsTypes.READ_ALL_ITENS_SUCCESS:
            return {...state, loading:false, error:false, rows:actions.payload.rows, total_rows:actions.payload.rows.length}
        case ItemActionsTypes.READ_ALL_ITENS_SUCCESS:
            return {...state, loading:false, error:true, msg:actions.payload.msg}    
        
        case ItemActionsTypes.READ_ITEM:
            return {...state, loading:true, error:false, msg:""};
        case ItemActionsTypes.READ_ITEM_SUCCESS:
            return {...state, loading:false, error:false, msg:"", row:actions.payload.row};    
        case ItemActionsTypes.READ_ITEM_FAILURE:
            return {...state, loading:false, error:true, msg:actions.payload.msg}
        
        case ItemActionsTypes.FILTRAR_ITENS:
            return {...state, loading:true, error:false, msg:""};
        case ItemActionsTypes.FILTRAR_ITENS_SUCCESS:
            return {...state, loading:false, error:false, rows:actions.payload.rows};
        case ItemActionsTypes.FILTRAR_ITENS_FAILURE:
            return {...state, loading:false, error:true, msg:actions.payload.msg};
        
        case ItemActionsTypes.SET_SHOW_FORM:
            return{...state, showForm:!state.showForm};

        default:
            return state;
    }
}

export default reducer;