import { action } from "typesafe-actions";
import { SessaoActionsTypes,  Sessao, Lgpd } from "../../../provider/start/types";
import { Usuario } from "../../../provider/usuario/types";

export const createSessao = () => action(SessaoActionsTypes.CREATE_SESSAO);
export const createSessaoSuccess = (row:Sessao) => action(SessaoActionsTypes.CREATE_SESSAO_SUCCESS, { row });
export const createSessaoFailure = (msg:string) => action(SessaoActionsTypes.CREATE_SESSAO_FAILURE, { msg });

export const updateSessao = (row:Sessao) => action(SessaoActionsTypes.UPDATE_SESSAO, { row });
export const updateSessaoSuccess = (row:Sessao) => action(SessaoActionsTypes.UPDATE_SESSAO_SUCCESS, { row });
export const updateSessaoFailure = (msg:string) => action(SessaoActionsTypes.UPDATE_SESSAO_FAILURE, { msg });

export const getSessao = () => action(SessaoActionsTypes.GET_SESSAO);
export const getSessaoSuccess = (row:Sessao) => action(SessaoActionsTypes.GET_SESSAO_SUCCESS, { row });
export const getSessaoFailure = (msg:string) => action(SessaoActionsTypes.GET_SESSAO_FAILURE, { msg });

export const delSessao = () => action(SessaoActionsTypes.DEL_SESSAO);
export const delSessaoSuccess = () => action(SessaoActionsTypes.DEL_SESSAO_SUCCESS);
export const delSessaoFailure = (msg:string) => action(SessaoActionsTypes.DEL_SESSAO_FAILURE, {msg});

export const setUserSessao = (user:Usuario) => action(SessaoActionsTypes.SET_USER_SESSAO, { user });
export const setUserSessaoFailure = (msg:string) => action(SessaoActionsTypes.SET_USER_SESSAO_FAILURE, { msg });

export const clearUserSessao = () => action(SessaoActionsTypes.CLEAR_USER_SESSAO);
export const clearUserSessaoSuccess = () => action(SessaoActionsTypes.CLEAR_USER_SESSAO_SUCCESS);
export const clearUserSessaoFailure = (msg:string) => action(SessaoActionsTypes.CLEAR_USER_SESSAO_FAILURE, { msg });

export const clearCartSessao = () => action(SessaoActionsTypes.CLEAR_CART_SESSAO);
export const clearCartSessaoSuccess = () => action(SessaoActionsTypes.CLEAR_CART_SESSAO_SUCCESS);
export const clearCartSessaoFailure = (msg:string) => action(SessaoActionsTypes.CLEAR_CART_SESSAO_FAILURE, { msg });

export const checkSessaoStarted = () => action(SessaoActionsTypes.SESSAO_STARTED);
export const setSessaoStart = (row:Sessao) => action(SessaoActionsTypes.SET_SESSAO_START, { row });
export const setNotSessaoStart = () => action(SessaoActionsTypes.SET_NOT_SESSAO_STARTED);

export const setInstallAppSessao = () => action(SessaoActionsTypes.SET_INSTALL_APP_SESSAO)
export const setUninstallAppSessao = () => action(SessaoActionsTypes.SET_UNINSTALL_APP_SESSAO)

export const createRegistroLGPD = () => action(SessaoActionsTypes.CREATE_LGPD)
export const createRegistroLGPDSuccess = (row:Lgpd) => action(SessaoActionsTypes.CREATE_LGPD_SUCCESS, {row})
export const createRegistroLGPDFailure = (msg:string) => action(SessaoActionsTypes.CREATE_LGPD_FAILURE, {msg})