import React from "react"
import {
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import { makeStyles,createStyles, AppBar, Toolbar, IconButton, Typography, Container, List, ListItem, ListItemText, Divider, Grid, Button } from "@material-ui/core";
import { MdArrowBack } from "react-icons/md";
import { FaHome } from "react-icons/fa";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

function EmailSentView(){
    const classes = useStyles();
    const routerHistory = useHistory();
    const { url } = useRouteMatch();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position={"sticky"} >
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Email Enviado!
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                <Typography variant="h6">
                        O email para recuperar a senha foi enviado,  siga os passo abaixo para continuar.
                    </Typography>
                    <List >

                        <ListItem>
                            <ListItemText 
                                primary="1 - Acesse o seu email com o titulo 'Recuperação de senha' e click no link que esta no email."
                                secondary="Obs: esse link tem validade até 60 min. apos o recebimento do email."
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText 
                                primary="2 - Apos clicar no link do email, você será redirecionado para o aplicativo visualizado a mensagem de senha apagada."
                                secondary="Apos isso, se identifique no aplicativo e recrie a nova senha."
                            />
                        </ListItem>

                        <Divider />
                    </List>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12}>
                            <Button
                                variant={
                                    "contained"
                                }
                                endIcon
                                color={"primary"}
                                fullWidth={true}
                                onClick={() => routerHistory.push(`/catalogo/itens`)}
                            >
                                Voltar para o catalogo.
                                <FaHome  size={24}/>
                            </Button>
                        </Grid>

                    </Grid>
                    
                </Container>
            </div>
        </div>
    )
}

export default EmailSentView