import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../store";
import * as MeioPagActions from "../store/actions";

import { AppBar, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core";

import ModalSelectMeioPag from "../../../components/Modal";
import { MeioPag,MeioPagState,MeioPagDispatch  } from "../provider/types"

interface SelectMeioPagProps {
    onClose(selected: object): void;
    open: boolean;
    meioPagState:MeioPagState;
    meioPagActions:MeioPagDispatch;
}

function SelectMeiosPag(props: SelectMeioPagProps) {
    const { onClose, open } = props;
    const { rows, loading, total_rows } = props.meioPagState;
    const { readAll } = props.meioPagActions;
    const [meioPagSelected, setMeioPagaSelected] = useState({})

    useEffect(() => {
        if (total_rows === 0)
            readAll();
    }, [total_rows])

    const handleClose = () => {
        onClose(meioPagSelected);
    };

    return (
        <ModalSelectMeioPag
            handleClose={handleClose}
            open={open}
            component={
                <div>
                    <AppBar position={"sticky"}>
                        <Toolbar>
                            <Typography variant={"h6"}>
                                Escolha o Meio de Pagamento
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {rows.map((mp, idx) => (
                            <>
                                <ListItem
                                    button
                                    onClick={() => onClose(mp)}
                                    key={idx}
                                >
                                    <ListItemText primary={mp.descricao} secondary={mp.forma.descricao}/>
                                    {mp.percDesc&&(
                                        <ListItemSecondaryAction>{mp.percDesc}% de Desconto</ListItemSecondaryAction>
                                    )}
                                    
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                </div>
            }
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    meioPagState: state.meioPag
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    meioPagActions: bindActionCreators(MeioPagActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectMeiosPag);