import { Categoria } from "../../modulos/categorias/provider/types";
import Utils from "../../services/utils";
import { Item, ItemResult, ItemDispatch, FilterItem } from "./types";

class ItemProvider extends Utils implements ItemDispatch {
    public tipo:string = "item";
    public row:Item = {} as Item;
    public rows:Item[] = [];
    private msgError:string = "Ocorreu uma falha no modulo de item. Detalhes: ";
    public dataItem:Item = {
        tipo:this.tipo,
        _id:undefined,_rev:undefined,
        descricao: "",
        detalhes: "",
        uni_med:"",
        categoria:{} as Categoria,
        codigo: "",
        custo:0,
        margem:30,
        preco:0,
        img:"",
        qt_estoque:0,
        ctrl_esto_man:false,
        estoque:false,
        active:false,
        qt_min:0,
        insumoGrupo:{_id:undefined,descricao:""},
        show_online:false,        
    }     

    read = async (id:string):Promise<ItemResult> => {
        try {
            let result = await this.getDocRemote(id);
            this.row = result.doc as Item;
            return {status:"ok", row:this.row}
        } catch (error) {
            return {status:"error", mensagem:this.msgError + error}
        }
    }

    readAll = async (reload:boolean):Promise<ItemResult> => {
        try {
            if (this.rows.length === 0 || reload) {

                let result = await this.queryRemote('item/catalogo_online',{})
                this.rows = result.rows.map(item => item.value) as unknown as Item[];
                this.rows.sort((a,b)=>{
                    return (a.descricao > b.descricao) ? 1 : ((b.descricao>a.descricao)?-1:0)
                })
                return {status:"ok",rows:this.rows}
            } else {
                return {status:"ok",rows:this.rows};
            }

        } catch (error) {
            return {status:"error", mensagem:this.msgError+JSON.stringify(error)}
        }
    }



    filtrar = (value:FilterItem) : ItemResult => {
        try {
            let dataFind:Item[] = [];
            let tipoFiltro:number = -1;
            if(!this.isEmpty(value.codigo)){
                tipoFiltro = 0
            } else if(value.categoria.length > 0) {
                if(!this.isEmpty(value.descricao)){
                    tipoFiltro = 1
                } else {
                    tipoFiltro = 2
                }
            } else if(!this.isEmpty(value.descricao)) {
                tipoFiltro = 3
            }

            if(tipoFiltro !== -1){
                dataFind = this.rows.filter((item,idx)=>{
                    //buscar todos se nao tiver nenhum parametro
                    let result = true;

                    switch (tipoFiltro) {
                        case 0://filtra somente pelo código
                            return item.codigo === value.codigo;    
                        case 1://filtrar por categoria e descricao
                            return (value.categoria.filter(cat=> cat._id===item.categoria._id).length>0
                            && item.descricao.toLowerCase().indexOf(value.descricao.toLowerCase()) > -1)
                        case 2://filtrar por categoria
                            return value.categoria.filter(cat=> cat._id===item.categoria._id).length>0
                        default://filtrar por descricao
                            return item.descricao.toLowerCase().indexOf(value.descricao.toLowerCase()) > -1
                    }
                })
            } else {
                dataFind = this.rows;
            }
            return { status:"ok", rows:dataFind }

        } catch (error) {
            return {status:"error", mensagem:this.msgError+JSON.stringify(error)}
        }
    }


    setShowForm(){}

}
const itemProvider = new ItemProvider();

export default itemProvider;