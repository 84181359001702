import { action } from "typesafe-actions";
import { PedidoActionsTypes, ShowDataPedido, MeioPagPedido,  } from "../../../provider/pedido/types";
import { Usuario } from "../../../provider/usuario/types";
import { showDataItemCart } from "../../../provider/itemCart/types";
import { EntregaPedido } from "../../../modulos/tipoEntregas/provider/types";


export const createPedido = (cliente:Usuario,itens:showDataItemCart[]) => action(PedidoActionsTypes.CREATE_PEDIDO, {cliente, itens});
export const createPedidoSuccess = (row:ShowDataPedido) => action(PedidoActionsTypes.CREATE_PEDIDO_SUCCESS, {row});
export const createPedidoFailure = (msg:string) => action(PedidoActionsTypes.CREATE_PEDIDO_FAILURE, {msg});

export const readAllPedidos = (cliente:Usuario,data_ini:string,data_fim:string) => action(PedidoActionsTypes.READ_ALL_PEDIDOS, { cliente,data_ini,data_fim });
export const readAllPedidosSuccess = (rows:ShowDataPedido[]) => action(PedidoActionsTypes.READ_ALL_PEDIDOS_SUCCESS, { rows });
export const readAllPedidoFailure = (msg:string) => action(PedidoActionsTypes.READ_ALL_PEDIDOS_FAILURE, { msg });

export const addMeioPag = (meioPag:MeioPagPedido) => action(PedidoActionsTypes.ADD_MEIO_PAG, { meioPag } );
export const setValorFechamento = (valor:number, codigo:number, total:number) => action(PedidoActionsTypes.SET_VALOR_FECHAMENTO, { valor, codigo, total });
export const setFechamentoPedido = (descontos:number, acrescimos:number,subtotal:number, total:number) => action(PedidoActionsTypes.SET_FECHAMENTO_PEDIDO, { descontos,acrescimos,subtotal,total })
export const setTipoEntrega = (tipoEntrega:any) => action(PedidoActionsTypes.SET_TIPO_ENTREGA, {tipoEntrega});
export const setObsPedido = (obs:string) => action(PedidoActionsTypes.SET_OBS_PEDIDO, {obs});
export const remMeioPag = (idx:number) => action(PedidoActionsTypes.REM_MEIO_PAG, { idx } );

export const sendPedido = (row:ShowDataPedido) => action(PedidoActionsTypes.SEND_PEDIDO, { row });
export const sendPedidoSuccess = (row:ShowDataPedido) => action(PedidoActionsTypes.SEND_PEDIDO_SUCCESS, { row });
export const sendPedidoFailure = (msg:string) => action(PedidoActionsTypes.SEND_PEDIDO_FAILURE, { msg });

export const readAllPedidoAndamento = (cliente:Usuario) => action(PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO, { cliente });
export const readAllPedidoAndamentoSuccess = (rows:ShowDataPedido[]) => action(PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO_SUCCESS, { rows });
export const readAllPedidoAndamentoFailure = (msg:string) => action(PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO_FAILURE, { msg });
