import { call, put } from "redux-saga/effects";
import { KitPromocaoResult,KitPromocao } from "../provider/types";
import {kitPromocaoProvider} from "../provider";
import * as KitPromocaoActions from "./actions";
import pedidoProvider from "../../../provider/pedido";
import sessaoProvider from "../../../provider/start";
import { SessaoResult } from "../../../provider/start/types";
import { PedidoResult, ShowDataPedido } from "../../../provider/pedido/types";
import pedidoItensProvider from "../../../provider/pedidoItens";
import { PedidoItensResult } from "../../../provider/pedidoItens/types";

//CREATE
export function* createKitPromocao(actions:any) {
    try {
        const resp:KitPromocaoResult = yield call(kitPromocaoProvider.create, actions.payload.prm)
        if (resp.status === "ok") {
            yield put(KitPromocaoActions.createSuccess(resp.row))    
        } else {
            yield put(KitPromocaoActions.createFailure("Ouve uma falha ao criar um novo KitPromocao. Detalhes: "+JSON.stringify(resp.mensagem)))
        }
    } catch (error) {
        yield put(KitPromocaoActions.createFailure("Ouve uma falha na função createKitPromocao no async. Detalhes: "+JSON.stringify(error)))
    }
}

//READ
export function* readAllKitPromocao(actions:any){
    try {
        let respSessao:SessaoResult = yield call(sessaoProvider.getSessao);
        let lastPedido:KitPromocao = {} as KitPromocao;
        if(respSessao.status === "ok" && respSessao.row.user._id){
            console.log("readAllKitPromocao passou 1")
            let respPed:PedidoResult = yield call(pedidoProvider.readLastPedido, respSessao.row.user)
            console.log("readAllKitPromocao passou 2",respPed)
            if(respPed.status === "ok" && respPed.rows){
                console.log("readAllKitPromocao passou 3")
                let pedido = respPed.rows[0].pedido._id;
                let respItensPed:PedidoItensResult = yield call(pedidoItensProvider.readPedidoItens, pedido);
                console.log("readAllKitPromocao passou 4",respItensPed)
                if(respItensPed.status === "ok" && respItensPed.rows){
                    console.log("readAllKitPromocao passou 5")
                    lastPedido = {
                        descricao:"Seu Ultimo Pedido!",
                        detalhes:"Nossa sugestão para você.",
                        tipo:"kit-promocao",
                        img:"sugestao.jpg",
                        desconto:0,
                        ativo:true,
                        _id:1,
                        _rev:1,
                        itens:[]
                    }
                    respItensPed.rows.map(item=>{
                        lastPedido.itens.push({
                            _id:item.itemData._id,
                            qt:item.itemCartData.qt,
                            valor:item.itemData.preco,
                            item:item.itemData
                        })
                    })
                }
            }
            
        }
        
        let resp:KitPromocaoResult = yield call(kitPromocaoProvider.readAll,actions.payload.prm)
        console.log("readAllKitPromocao passou 6",resp)
        if(resp.status === "ok") {
            //tratando os dados de uma visao array com as propriedades
            //doc:object,id:string,key:Array,value:KitPromocao
            let kitParents:any[] = [];
            let kitChilds:any[] = [];

            resp.rows?.map((row,idx)=>{
                if(row['key'][1] === 0){
                    kitParents.push(row['value']);
                } else {
                    kitChilds.push(row['doc']);
                }
            })
            kitParents.map((row,i)=>{
                row['itens'].map(item=>{
                    let doc = kitChilds.find(doc=>item['_id']===doc['_id']);
                    if(doc !== undefined){
                        item['item'] = doc;
                    }
                })
            })
            if (lastPedido._id) {
                kitParents = [lastPedido, ...kitParents]
            }           
            console.log("readAllKitPromocao passou 7",kitParents) 
            yield put(KitPromocaoActions.readAllSuccess(kitParents as KitPromocao[]));
        } else {
            yield put(KitPromocaoActions.readAllFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(KitPromocaoActions.readAllFailure("Ocorreu uma falha na estrutura da função readAllKitPromocao em async. Detalhes: "+JSON.stringify(error)));
    }
}

//update

export function* updateKitPromocao(actions:any){
    try {
        let resp:KitPromocaoResult = yield call(kitPromocaoProvider.update,actions.payload.row)
        if(resp.status === "ok") {
            yield put(KitPromocaoActions.updateSuccess(resp.row as KitPromocao));
        } else {
            yield put(KitPromocaoActions.updateFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(KitPromocaoActions.updateFailure("Ocorreu uma falha na estrutura da função updateKitPromocao em async. Detalhes: "+JSON.stringify(error)));
    }
}

//delete
export function* deleteKitPromocao(actions:any) {
    try {
        const resp:KitPromocaoResult = yield call(kitPromocaoProvider.del, actions.payload.row);
        if (resp.status === "ok") {
            //repassa no success para remover da lista do state
            yield put(KitPromocaoActions.delSuccess(actions.payload.row));
        } else {
            yield put(KitPromocaoActions.delFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(KitPromocaoActions.delFailure("Ouve uma falha na função deleteKitPromocao em async. Detalhes: "+JSON.stringify(error)))
    }
}


//filtrar
export function* filtrarKitPromocao(actions:any){
    let resp:KitPromocaoResult = yield call(kitPromocaoProvider.filtrar,actions.payload.prm)
    yield put(KitPromocaoActions.filtrarSuccess(resp.rows as KitPromocao[]));
}
