import Provider from "../../../services/provider";
import { EntregaPedido } from "./types";

const newDataEntregaPedido = {} as EntregaPedido

class EntregaPedidoProvider extends Provider<EntregaPedido>{

}

export const entregaPedidoProvider = new EntregaPedidoProvider(
    "entrega",
    newDataEntregaPedido,
    [
        {title:"Descricao",field0:"descricao",type:"text"},
    ]
)
