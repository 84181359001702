/**
 * Aqui são as actions que aciona os reduces
 * Importar o framework typesafe-actions para controlar as actions e chamar o reducer
 * 
 *  */ 
import { CardActions } from "@material-ui/core";
import { action } from "typesafe-actions";
import { UsuarioActiosTypes, Usuario, UsuarioResult } from "../../../provider/usuario/types";

export const readUser = () => action(UsuarioActiosTypes.READ_USUARIO);
export const readUserSuccess = (row:Usuario) => action(UsuarioActiosTypes.READ_USUARIO_SUCCESS, { row });
export const readUserFailure = (msg:string) => action(UsuarioActiosTypes.READ_USUARIO_FAILURE, { msg });

export const login = (prmLogin:string) => action(UsuarioActiosTypes.LOGIN, { prmLogin });
export const loginFound = (row:Usuario) => action(UsuarioActiosTypes.LOGIN_FOUND, { row });
export const loginNotFound = (msg:string) => action(UsuarioActiosTypes.LOGIN_NOT_FOUND, { msg });
export const loginValidate = (pwLogin:string,row:Usuario) => action(UsuarioActiosTypes.LOGIN_VALIDATE, { pwLogin, row });
export const loginSuccess = (row:Usuario) => action(UsuarioActiosTypes.LOGIN_SUCCESS, { row });
export const loginFailure = (msg:string) => action(UsuarioActiosTypes.LOGIN_FAILURE, { msg });

export const logOff = () => action(UsuarioActiosTypes.LOGOFF)

export const createUser = () => action(UsuarioActiosTypes.CREATE_USUARIO);
export const createUserSuccess = (row:Usuario) => action(UsuarioActiosTypes.CREATE_USUARIO_SUCCESS, { row });
export const createuserFailure = (msg:string) => action(UsuarioActiosTypes.CREATE_USUARIO_FAILURE, { msg });

export const salvar = (row:Usuario) => action(UsuarioActiosTypes.SALVAR, {row});
export const salvaSuccess = (row:Usuario) => action(UsuarioActiosTypes.SALVAR_SUCCESS, {row});
export const salvarFailure = (msg:string) => action(UsuarioActiosTypes.SALVAR_FAILURE, {msg});

export const sendEmailRecSenha = (email:string) => action(UsuarioActiosTypes.SEND_EMAIL, {email})
export const sendEmailRecSenhaSuccess = () => action(UsuarioActiosTypes.SEND_EMAIL_SUCCESS)
export const sendEmailRecSenhaFailure = (msg:string) => action(UsuarioActiosTypes.SEND_EMAIL_FAILURE,{msg})

export const getUser = (id:string) =>  action(UsuarioActiosTypes.GET_USER, {id})
export const getUserSuccess = (row:Usuario) =>  action(UsuarioActiosTypes.GET_USER_SUCCESS, {row})
export const getUserFailure = (msg:string) =>  action(UsuarioActiosTypes.GET_USER_FAILURE, {msg})

export const setPassword = (pw:string) => action(UsuarioActiosTypes.SET_PASSWORD, { pw });

export const setValueRow = (row:Usuario) => action(UsuarioActiosTypes.SET_VALUE_ROW, { row });

export const clearError = () => action(UsuarioActiosTypes.CLEAR_ERROR)