/**
 * Fazer a estrutura do reducer
 * importar o framwork redux
 * iniciar a constantate INITIAL_STATE
 * criar a função reducer com a estrutura de switch case
 * 
 */

 import { Reducer } from "redux";
 import { UsuarioState, UsuarioActiosTypes , Usuario} from "../../../provider/usuario/types";
 import UsuarioProvider from "../../../provider/usuario";


 const INITIAL_STATE:UsuarioState = {
     row:UsuarioProvider.dataCliente,
     rowFind:{} as Usuario,
     rows:[],
     error:false,
     loading:false,
     msg:"",
     showForm:false,
     total_rows:0,
     sentEmail:false,
 };

 const reducer:Reducer<UsuarioState> = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case UsuarioActiosTypes.LOGIN:
            return {...state, loading:true, error:false, msg:""}
        case UsuarioActiosTypes.LOGIN_FOUND:
            return {...state, loading:false, error:false, rowFind:action.payload.row};
        case UsuarioActiosTypes.LOGIN_NOT_FOUND:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case UsuarioActiosTypes.LOGIN_VALIDATE:
            return {...state, loading:true, msg:"", error:false}
        case UsuarioActiosTypes.LOGIN_SUCCESS:
            return {...state, loading:false, error:false, row:action.payload.row};
        case UsuarioActiosTypes.LOGIN_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case UsuarioActiosTypes.LOGOFF:
            console.log('LOGOFF data cliente',UsuarioProvider.dataCliente);
            return {...state, row: UsuarioProvider.dataCliente, rowFind: {} as Usuario }

        case UsuarioActiosTypes.READ_USUARIO:
            return {...state, loading:true, error:false, msg:""};
        case UsuarioActiosTypes.READ_USUARIO_SUCCESS:
            return {...state, loading:false, error: false, row:action.payload.row};
        case UsuarioActiosTypes.READ_USUARIO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};
            
        case UsuarioActiosTypes.CREATE_USUARIO:
            return {...state, loading:true, error:false, msg:""};
        case UsuarioActiosTypes.CREATE_USUARIO_SUCCESS:
            return {...state, loading:false, row:action.payload.row};
        case UsuarioActiosTypes.CREATE_USUARIO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}

        case UsuarioActiosTypes.SALVAR:
            return {...state, loading:true, error:false, msg:""};
        case UsuarioActiosTypes.SALVAR_SUCCESS:
            return {...state, loading:false, row:action.payload.row};
        case UsuarioActiosTypes.SALVAR_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case UsuarioActiosTypes.SEND_EMAIL:
            return {...state, loading:true, msg:"", sentEmail:false}
        case UsuarioActiosTypes.SEND_EMAIL_SUCCESS:
            return {...state, loading:false, sentEmail:true}
        case UsuarioActiosTypes.SEND_EMAIL_FAILURE:
            return {...state, loading:false, sentEmail:false,error:true, msg:action.payload.msg}

        case UsuarioActiosTypes.GET_USER:
            return {...state, loading:true, error:false, msg:""}
        case UsuarioActiosTypes.GET_USER_SUCCESS:
            return {...state, loading:false, row:action.payload.row}
        case UsuarioActiosTypes.GET_USER_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}

        case UsuarioActiosTypes.SET_PASSWORD:
            return {...state, row:{...state.row, senha:action.payload.pw }};

        case UsuarioActiosTypes.SET_VALUE_ROW:
            return {...state, row:action.payload.row};

        case UsuarioActiosTypes.CLEAR_ERROR:
            return {...state, error:false, msg:""}

        default:
            return state;
    }      
 }
 export default reducer;