import Utils from "../../services/utils";
import { Item } from "../item/types";
import { itemCart, showDataItemCart, ItemCartDispatch, ItemCartResult} from "./types";
import sessaoProvider from "../../provider/start";
import { SessaoResult, Sessao } from "../start/types";
import itemProvider from "../item/index";

class ItemCartProvider extends Utils implements ItemCartDispatch {

    public tipo:string = "item-mov";
    private msgError:string = "Ocorreu uma falha tente outra vez. Detalhes: ";
    public data:itemCart = {
        tipo:'item-mov',
        _id:undefined,
        _rev:undefined,
        custo:0,
        item_id:"",
        mov_id:"",
        obs:"",
        preco:0,
        qt:0,
        qt_eq:0,
        subtotal:0,
        data:"",
        direcao_estoque:"S",
        insumos:[],
    }

    createItemCart = (item: Item):ItemCartResult => {

        let newItemCart : showDataItemCart = {
            itemData:{...itemProvider.dataItem,...item},
            itemCartData:{
                ...this.data,
                item_id:item._id,
                custo:item.custo,
                preco:item.preco,    
            }
        }
        return {status:"ok", row:newItemCart}
    }

    addItemCart = async (row:showDataItemCart):Promise<SessaoResult> => {
        try {
            let resp = await sessaoProvider.getSessao();
            if(resp.status === "ok"){
            /**verifica se o item já está no carrinho  */
                let find = resp.row.cart.find((itemFind,idx)=>{
                    return itemFind.itemData._id === row.itemData._id;
                });
                //se encontrar, coloca ele, se nao cria um novo
                if(find !== undefined){
                    find.itemCartData = row.itemCartData;
                    return {status:"ok", row:resp.row}
                } else {
                    resp.row.cart.push(row);
                    return {status:"ok",row:resp.row}    
                }                
            } else {
                return {status:"error", mensagem:"Falha ao adicionar o item ao carrinho. Detalhes: "+JSON.stringify(resp.mensagem), row:resp.row}
            }
        } catch (error) {
            return {status:"error",mensagem:"Falha geral ao adicionar o item ao carrinho. Detahes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }

    addMultItemCart = async (rows:showDataItemCart[]):Promise<SessaoResult> => {
        try {
            let resp = await sessaoProvider.getSessao();
            if(resp.status === "ok"){
                resp.row.cart = resp.row.cart.concat(rows);
                return {status:"ok",row:resp.row}
            } else {
                return {status:"error", mensagem:"Falha ao adicionar os itens ao carrinho. Detalhes: "+JSON.stringify(resp.mensagem), row:resp.row}
            }
        } catch (error) {
            return {status:"error",mensagem:"Falha geral ao adicionar os itens ao carrinho. Detahes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }    

    remItemCart = async (index:number):Promise<SessaoResult> => {
        try {
            let resp = await sessaoProvider.getSessao();
            if (resp.status === "ok") {
                this.excluirLinhaArray(resp.row.cart,index);
                return {status:"ok",row:resp.row}
            } else {
                return {status:"error",mensagem:resp.mensagem,row:resp.row}
            }            
        } catch (error) {
            return {status:"error", mensagem:"Falha geral ao remover o item do carrinho. Detalhes: "+JSON.stringify(error), row:{} as Sessao};
        }
    }

    readAllItensCart = async():Promise<ItemCartResult> => {
        try {
            let resp = await sessaoProvider.getSessao();
            if(resp.status === "ok") {
                return {status:"ok",rows:resp.row.cart}
            } else {
                return {status:"error", mensagem:"Ainda não foi criado um carrinho na sessão."}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral ao buscar os itens do carrinho. Detalhes: "+JSON.stringify(error)};
        }
    }

    updateCart = async(itemCart:showDataItemCart):Promise<SessaoResult> => {
        try {
            let resp = await sessaoProvider.getSessao();
            if (resp.status === "ok") {
                resp.row.cart.find((item,idx)=>{
                    if(item.itemData._id === itemCart.itemData._id){
                        item.itemCartData = itemCart.itemCartData
                    }
                });
                return {status:"ok",row:resp.row}
            } else {
                return {status:"error",mensagem:resp.mensagem,row:resp.row}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral ao atualizar o item do carrinho. Detalhes: "+JSON.stringify(error), row:{} as Sessao};
        }
    }

    readItemCart(){

    }

    setItemUpdate(itemCart: showDataItemCart): void {
        throw new Error("Method not implemented.");
    }

    confirmarCart(itensCart: showDataItemCart[]): void {
        throw new Error("Method not implemented.");
    }

    confirmaCartCancel(): void {
        throw new Error("Method not implemented.");
    }

    clearError(): void {
        throw new Error("Method not implemented.");
    }
    



}

const itemCartProvider = new ItemCartProvider()
export default itemCartProvider;