/**
 * Funções para serem reutizadas em todo o app
 * diferente de utils em services que é uma classe e precisa ser instanciada
 * aqui é só importar a funcao
 */
 import moment from "moment";
 import validator from "validator";

export const mutatorSetValue = ([field, value], state, { changeValue }) => {
    changeValue(state, field, () => value)
}    

export const isEmail = (prm:any) => {
    if(!isEmpty(prm)){
        let email = validator.isEmail(prm)
        //console.log("é email ",email);
        return email;    
    } else {
        return false;
    }
}

export const formatMoney = (value:number) => {
    if (!value) return value;
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: 'BRL'
    });
    let formated = formatter.format(value);
    return formated;
};	

export const excluirRegistroArray = (array: any[], index:number) => {
    array.splice(index, 1);
} 

export const excluirRegistroArrayRow = (array:any[], row:any) => {
    let i = array.findIndex(find=>find._id===row._id);
    array.splice(i, 1);
}

export const formaDateIsoValue = (date:string) => {
    let d = moment(date).format();
    return d;
}

export const getDataText = (date:string) => {
    let d = moment(date).format("DD/MM/YYYY HH:mm");
    return d;
}

export const getDataAmerica = (date:string) => {
    return moment(date).format("YYYY-MM-DD");
}

export const formatDataAteAgora = (date:string) => {
    return moment(date).fromNow();
}

export const isEmpty = (prm:any) => {
    switch (prm) {
        case null:
            return true;
        case undefined:
            return true;
        case '':
            return true		
        default:
            return false
    }
}

export const soma = (array:any[],rowQt:any,rowValue:any) => {
    var total = 0;
    //console.log(array);
    array.map((row, key)=>{
        if (rowQt!='') {
            var valor = row[rowQt]*row[rowValue];
            total = total + valor;
        } else {
            //console.log(row[rowValue]);
            total = total + row[rowValue];
        }
    });
    return Number(total.toFixed(2));
}

export const existePropriedade = (object:Object,propertName:Array<string>) => {
    /* 
    *@object é o obejeto a ser verificado se existe a propriedade
    *@propertName contem um array de string que são as proprieddades a serem verificadas no mesmo nível
    * exemplo: objeto.nome.id propertName deve ser parametrizado assim ['nome','id']
    * o codigo vai verificar se o objeto no primeiro paramentro tem a propriedade nome
    * e de se o objeto nome tem a propriedade id 
    */
    let resultado = false;
    //validar objeto
    if(typeof object === "object"){
        //recebe o objeto pai para iniciar o ciclo
        let objChild = object;
        for (let i = 0; i < propertName.length; i++) {
            const element = propertName[i];
            //verificar existe a propriedade
            if(objChild.hasOwnProperty(element)){
                //se existir veriricar se é um objeto
                if(typeof objChild[element] === 'object'){
                    //preencher com o novo objeto
                    objChild = objChild[element];
                    resultado = true;
                } else {
                    resultado = true;
                }
            } else {
                resultado = false;
            }
        }

    }
    return resultado;
}