import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ApplicationState } from "../../store";
import * as SessaoActions from "../../store/data/start/actions";
import * as PedidoActions from "../../store/data/pedido/actions";
import { SessaoProps, SessaoState, SessaoDispatch } from "../../provider/start/types";
import { PedidoProps, PedidoState, PedidoDispatch } from "../../provider/pedido/types";
import {
    makeStyles,
    createStyles,
    Theme,
    Avatar,
    LinearProgress,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
        },
        content: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
        },
        versao:{
            position:"relative",
            display:"flex",
            bottom:2,
            alignSelf:"flex-end",
            padding:5,
        }
    })
);
interface StartProps {
    sessaoState:SessaoState,
    sessaoActions:SessaoDispatch,
    pedidoState:PedidoState,
    pedidoActions:PedidoDispatch,
}
function Start({ sessaoState, sessaoActions, pedidoState }:StartProps ) {

    
    const { createSessao, checkSessaoStarted } = sessaoActions;
    const { sessaoStarted, loading, row } = sessaoState;
    const routerHistory = useHistory();
    const { url } = useRouteMatch();
    const classes = useStyles();

    useEffect(() => {
        /**useEfect somente é chamado a cada mudança na sessaoStated */
        checkSessaoStarted();
        //console.log("sessao",sessaoState);
        //console.log("checou sessao loading",loading);
        //console.log("checou sessao pedido loading",pedidoState.loading);
        
        if (sessaoStarted&&!loading&&!pedidoState.loading) {
            //checar se ja aceitou cookies de acordo com o LGPD
            if(row.lgpd.hasOwnProperty("_id")){
                if (row.user._id) {
                    /**se ja tem usuario logado, agora verifica se tem algum pedido
                     * em andamento que já foi enviado, para exibir sua situação
                     */
                    if (pedidoState.total_rows > 0) {
                        routerHistory.push(`${url}andamento`);    
                    } else {
                        routerHistory.push(`${url}catalogo/itens`);
                    }
                } else {
                    /**se a sessao ja foi iniciada mas nao tem usuario logado, vai para os
                     * itens do catalogo
                     */
                    routerHistory.push(`${url}catalogo/itens`);
                }
            } else {
                //aceitar os cookies
                routerHistory.push(`${url}aceitacookies`); 
            }                
        }

    }, [sessaoStarted, pedidoState.loading]);
    return (
        <div className={classes.root}>
            {loading && (
                <LinearProgress
                    style={{ margin: 3, minWidth: "max-content" }}
                    color="primary"
                ></LinearProgress>
            )}
            <div className={classes.content}>
                <Avatar
                    src="../branding.png"
                    alt="AkiSuaFeira"
                    className={classes.avatar}
                />
                <Typography variant="h6">Iniciando...</Typography>
            </div>
            <Typography variant="caption" className={classes.versao}>Versão 2.0.4</Typography>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    sessaoState: state.start,
    pedidoState: state.pedido,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    sessaoActions: bindActionCreators(SessaoActions, dispatch),
    pedidoActions: bindActionCreators(PedidoActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Start);
