import Utils from "../../services/utils";
import { TipoPedido, StatusPedido, PedidoData, PedidoDispatch, PedidoResult, MeioPagPedido, ShowDataPedido, Config, TipoMovimentacao } from "./types"
import { Usuario } from "../usuario/types";
import { showDataItemCart } from "../itemCart/types";
import { EntregaPedido } from "../../modulos/tipoEntregas/provider/types";
import { ApiGestor } from "../../services/api"

class PedidoProvider extends Utils implements PedidoDispatch {

    public tipo: string = "mov";

    public pedidoData: PedidoData = {
        tipo: this.tipo,
        _id: undefined, _rev: undefined,
        codigo: "",
        cliente_id: "",
        data_cad: "",
        data_prev_ent: "",//datahora prevista para entrega
        data_status: "",//datahora que o status foi alterado
        status: StatusPedido.iniciado,
        tipo_mov: TipoPedido.PEDIDO,
        fechamento: {
            acrescimos: 0,
            descontos: 0,
            subtotal:0,
            total: 0,
            meios_pag: []
        },
        movimentacao:{} as TipoMovimentacao,
        entrega:{
            custo:0,
            descricao:"",
            detalhes:"",
            dia:"",
            hora:"",
            valor_min:0,
        },
        obs:"",
        local:"app",
    }

    createPedido = async (cliente: Usuario, itens: showDataItemCart[]):Promise<PedidoResult> => {

        let dt = this.formaDateIsoValue(Date().toString());
        let config:any = await this.getDocRemote("@config");
        if(config.status ===  "ok"){
            let tipoMovimentacao = config.doc['tipoMovPedidoDefault']
            return {
                status: "ok",
                row: {
                    pedido: {
                        ...this.pedidoData,
                        _id:this.getNewUID(),
                        cliente_id: cliente._id,
                        data_cad: dt,
                        movimentacao:tipoMovimentacao
                    },
                    cliente: cliente,
                    itens: itens,
                }
            }
        } else {
            return {status:"error", mensagem:config.msg as string}
        }
    }


    postPedido = async (pedido: PedidoData): Promise<PedidoResult> => {
        try {
            let result = await this.createDataRemote(pedido);
            if (result.ok) {
                pedido._id = result.id;
                pedido._rev = result.rev;
                return { status: "ok", row: { pedido: pedido, cliente: {} as Usuario, itens: [] } }
            } else {
                return { status: "error", mensagem: 'O cabeçalho do pedido não pode ser enviado. Por favor, tente outra vez!' }
            }
        } catch (error) {
            return { status: "error", mensagem: 'Falha geral no pedidoProvider postPedido. Detalhes: ' + JSON.stringify(error) }
        }
    }


    sendPedido = async (row: ShowDataPedido): Promise<PedidoResult> => {
        try {
            /**
             * aqui chamar a funcao para gerar o novo codigo do pedido 
             * prms1:nome da funcão que gera o codigo no servidor
             * prms2:nome do documento que armazena o codigo
             */
            let resultGetCodigo = await this.queryUpdateRemote("get_cod_mov_inc","@config",{});
            //verificar se pegou o codigo se não retornar a falha
            if(resultGetCodigo?.data['result'] === "ok"){

                row.pedido.codigo = resultGetCodigo.data["codigo"];
                row.pedido.status = StatusPedido.confirmado;
                row.pedido.data_status = new Date().toISOString();
                //row.pedido.fechamento.total = row.itens.map(item => item.itemCartData.subtotal).reduce((acc, cur) => acc + cur);

                /**Atualiza os itens com o _id do pedido */
                row.itens.map(item => {
                    item.itemCartData.mov_id = row.pedido._id;
                    item.itemCartData.data = row.pedido.data_cad;
                });

                /**colocar o pedido e os items em uma array unica*/
                let data: any[] = [];
                //adiciona o pedido
                data.push(row.pedido);
                //adiciona os itens
                row.itens.map(item => {
                    data.push(item.itemCartData);
                });

                /**Enviar todos os docs para o servidor de uma unica vez */

                let results = await this.bulkDataRemote(data);

                 
                let itemSend = true
                    
                results.map(result => {
                    if (itemSend && result.rev) {
                        itemSend = true;
                    } else {
                        itemSend = false;
                    }
                });
 
                if (itemSend) {
                    //enviar notificacao de um novo pedido
                    this.sendNotificationPedido(row)

                    return { status: "ok", row: row }
                } else {
                    return { status: "error", mensagem: "Falha ao enviar os itens do pedido. Tente outra vez." };
                }


            } else {
                return {status:"error", mensagem: "Seu pedido não pode ser enviado. Verifique sua conexão com a internet e tente outra vez."}
            }

        } catch (error) {
            return { status: "error", mensagem: "Falha geral no sendPedido do pedidoProvider. Detalhes: " + JSON.stringify(error) };
        }
    }

    sendNotificationPedido = async (row:ShowDataPedido) => {
        try {
            let notification = {
                title:"Novo pedido N°"+row.pedido.codigo,
                body:"Cliente: "+row.cliente.nome,
                topics:"/topics/scambPedApp",
            }
            const resp = await ApiGestor.post('/sendNotification',notification)
            console.log("resp notification",resp);
        } catch (error) {
            return console.log("error notification", error);
        }
    }

    readAllPedidoAndamento = async (cliente: Usuario): Promise<PedidoResult> => {
        try {
            const rows: ShowDataPedido[] = [];
            let qryName = "mov/mov_andamento_by_cli_id"
            let opt = {
                key: cliente._id,
                include_docs: true,
            };
            let result = await this.queryRemote(qryName, opt);
            if (result.total_rows > 0) {
                result.rows.map(pedidos => {
                    let row: ShowDataPedido = {
                        pedido: pedidos.value.mov,
                        cliente: pedidos.doc as Usuario,
                        itens: []
                    };
                    rows.push(row);
                });
                return { status: "ok", rows: rows }
            } else {
                return { status: "error", mensagem: "Sem pedidos em andamento.", rows: [] }
            }
        } catch (error) {
            return { status: "error", mensagem: "Falha geral em readPedidoAndamento do pedidoProvider, detalhes: " + JSON.stringify(error) }
        }
    };

    readAllPedidos = async (cliente: Usuario, data_ini:string, data_fim:string): Promise<PedidoResult>  => {
        try {
            const rows: ShowDataPedido[] = [];
            let qryName = "mov/mov_all_by_cli_id"
            let opt = {
                startkey: [cliente._id,data_ini],
                endkey:[cliente._id,data_fim],
                include_docs: true,
            };
            let result = await this.queryRemote(qryName, opt);
            if (result.total_rows > 0) {
                result.rows.map(pedidos => {
                    let row: ShowDataPedido = {
                        pedido: pedidos.value.mov,
                        cliente: pedidos.doc as Usuario,
                        itens: []
                    };
                    rows.push(row);
                });
                rows.sort((a,b)=>Number(b.pedido.codigo) - Number(a.pedido.codigo));
                return { status: "ok", rows: rows }
            } else {
                return { status: "error", mensagem: "Sem histórico de pedidos", rows: [] }
            }
        } catch (error) {
            return { status: "error", mensagem: "Falha geral em readAllPedidos do pedidoProvider, detalhes: " + JSON.stringify(error) }
        }
    }

    readLastPedido = async (cliente:Usuario): Promise<PedidoResult> => {
        try {
            const rows: ShowDataPedido[] = [];
            let qryName = "mov/mov_all_by_cli_id"
            let opt = {
                startkey: [cliente._id,{}],
                endkey:[cliente._id,0],
                descending:true,
                limit:1,
                include_docs: true,
            };
            let result = await this.queryRemote(qryName, opt);    
            if (result.total_rows > 0) {
                result.rows.map(pedidos => {
                    let row: ShowDataPedido = {
                        pedido: pedidos.value.mov,
                        cliente: pedidos.doc as Usuario,
                        itens: []
                    };
                    rows.push(row);
                });
                return { status: "ok", rows: rows }   
            } else {
                return { status: "error", mensagem: "Cliente sem pedidos", rows: [] }
            }                                 
        } catch (error) {
            return { status: "error", mensagem: "Falha geral em readLastPedido do pedidoProvider, detalhes: " + JSON.stringify(error) }
        }
    }

    addMeioPag(meioPag: MeioPagPedido) { }

    remMeioPag(idx: number) { }

    setValorFechamento() { }

    setFechamentoPedido() {};

    setTipoEntrega(tipoEntrada: EntregaPedido): void {
        
    }

    setObsPedido(obs: string): void {
        
    }

}
const pedidoProvider = new PedidoProvider();

export default pedidoProvider;