import Utils from "../../../services/utils";
import { MeioPagResult, MeioPag, MeioPagDispatch, TipoRegistro } from "./types"

class MeioPagProvider extends Utils implements MeioPagDispatch {
    public rows:MeioPag[] = [];
    public tipo:string = "meiopag";
    public newDataMeioPag:MeioPag = {
        _id:undefined,
        _rev:undefined,
        tipo:this.tipo,
        codigo:NaN,
        descricao:"",
        percDesc:0,
        forma:{//é a forma como é realizada o meio de pagamento. A Vista, a Prazo, Deposito Bancario, Cartão
            descricao:"",
            gerarRegistro:false,
            permiteTroco:false,
            tipoRegistro:TipoRegistro.none
        }

    }

    createMeioPag = () : MeioPagResult => {
        return {status:"ok", row:this.newDataMeioPag}
    }

    readAll = async () : Promise<MeioPagResult> => {
        console.log("chamou readAll meioPag");
        try {
            let result = await this.queryRemote('meioPag/online',{})
            let values:MeioPag[] = result.rows.map(views=>{
                return views.value
            })
            this.rows = values;
            return {status:"ok", rows:this.rows}

        } catch (error) {
            return {status:"error", mensagem:"Ocorreu uma falha na estrutura da função readAll de MeioPagProvider. Detalhes "+ JSON.stringify(error)}
        }
    }

    updateMeioPag = async (row: MeioPag): Promise<MeioPagResult> => {
        try {
            let result = await this.salvarRemoto(row);
            if (result.status) {
                return {status:"ok",row:result.row as MeioPag};
            } else {
                return {status:"error", mensagem:result.erro as string}
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função updateMeioPag em MeioPagProvider. Detalhes:"+JSON.stringify(error)}
        }
    }

    delMeioPag = async(row: MeioPag): Promise<MeioPagResult>   => {
        try {
            let result = await this.deleteDataRemote(row);
            if (result.ok) {
                return {status:"ok"};
            } else {
                return {status:"error", mensagem:"Ocorreu uma falha, o meio de pagamento não foi removido."};
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função delMeioPag em MeioPagProvider"+JSON.stringify(error)}
        }
    }

    filtrar = (value:any) : MeioPagResult => {
        try {
            const rowsFilter = this.filterAllArray(this.rows,value);
            if (rowsFilter.ok) {
                return { status:"ok", rows:rowsFilter.data }
            } else {
                return { status:"error", mensagem:'Ocorreu uma falha na consulta dos meios de pagamento. Detalhes: '+rowsFilter.mgs }
            }
        } catch (error) {
            return {status:"error", mensagem:"Ouve uma falha na função filtrar em MeioPagProvider. Detalhes:"+JSON.stringify(error)}
        }
    }    

    setShowForm = () => {}

    setRowMeioPag = () => {}
}
const meioPagProvider = new MeioPagProvider();
export default meioPagProvider;