import { call, put } from "redux-saga/effects";
import * as PedidoItensActions from "./actions";
import * as ItemCartActions from "../itemCart/actions";
import { PedidoItensResult, PedidoItensActionsType, ShowDataPedidoItens } from "../../../provider/pedidoItens/types";
import pedidoItensProvider from "../../../provider/pedidoItens";
import { showDataItemCart } from "../../../provider/itemCart/types";

export function* readPedidoItens(actions){
    try {
        let resp:PedidoItensResult = yield call(pedidoItensProvider.readPedidoItens,actions.payload.mov_id);
        if (resp.status === "ok") {
            yield put(PedidoItensActions.readPedidoItensSuccess(resp.rows as ShowDataPedidoItens[]));
        } else {
            yield put(PedidoItensActions.readPedidoItensFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(PedidoItensActions.readPedidoItensFailure("Falha geral no async readPedidoItens, detalhes: "+JSON.stringify(error)));
    }
}

export function* repeatItensPedido(actions){
    try {
        console.log("repeatItensPedido",actions)
        let resp:PedidoItensResult = yield call(pedidoItensProvider.repeatItensPedido, actions.payload.itensSel,actions.payload.manterPreco);
        if (resp.status === "ok") {
            console.log("repeatItensPedido ok",resp);
            yield put(ItemCartActions.addMultItemCart(resp.rows as showDataItemCart[]));
            yield put(PedidoItensActions.repeatItensPedidoSuccess());
        } else {
            console.log("repeatItensPedido error",resp);
            yield put(PedidoItensActions.repeatItensPedidoFailure(resp.mensagem as string));
        }
    } catch (error) {
        console.log("repeatItensPedido error geral",error);
        yield put(PedidoItensActions.repeatItensPedidoFailure("Falha geral no async repeatItensPedido, detalhes: "+JSON.stringify(error)));
    }
}