import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Toolbar, AppBar, IconButton, Typography, Container, Card, CardHeader, Badge, Avatar, LinearProgress, Chip, CardContent, Slide, Slider, CardActions, Button, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import { FaMotorcycle, FaShoppingBag, FaHome, FaClipboardCheck, FaHistory, FaWindowClose, FaWhatsapp } from "react-icons/fa";
import {
    useHistory,
    useRouteMatch,
} from "react-router-dom";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: '100%',
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

export default function PedidoEnviado() {
    const classes = useStyles();
    const routerHistory = useHistory();
    const { url } = useRouteMatch();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position={"sticky"} >
                    <Toolbar>
                        <FaClipboardCheck size={25} />
                        <Typography variant="h6" className={classes.title}>
                            Pedido Enviado
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container>
                    <Typography variant={'h4'} color={'primary'}>
                        Seu Pedido Foi Enviado Com Sucesso!
                    </Typography>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                O que deseja fazer agora?
                            </ListSubheader>
                        }
                        >
                        <ListItem button onClick={() => routerHistory.push(`/`)}>
                            <ListItemIcon>
                                <FaMotorcycle size={25} color={'primary'} />
                            </ListItemIcon>
                            <ListItemText primary="Verificar o andamento do seu pedido." />
                        </ListItem>  

                        <ListItem button onClick={() => routerHistory.push(`${url}catalogo/itens`)}>
                            <ListItemIcon>
                                <FaHome  size={23} color={'primary'}/>
                            </ListItemIcon>
                            <ListItemText primary="Voltar para o catálogo" />
                        </ListItem>  
                        <ListItem button onClick={() => routerHistory.push(`/grupowhatsappofertas`)}>
                            <ListItemIcon>
                                <FaWhatsapp  size={23} color={'primary'}/>
                            </ListItemIcon>
                            <ListItemText primary="Grupo de Ofertas WhatsApp" secondary="Participar do grupo e receber notificações sobre ofertas"/>
                        </ListItem>                          
                        {/**
                        <ListItem button onClick={() => routerHistory.push(`/catalogo/historico`)}>
                            <ListItemIcon>
                                <FaHistory  size={23} color={'primary'}/>
                            </ListItemIcon>
                            <ListItemText primary="Visualizar seu histórico de pedidos." />
                        </ListItem> 
                        <Divider />
                        <ListItem button onClick={() => window.close()}>
                            <ListItemIcon>
                                <FaWindowClose size={23} color={'primary'}/>
                            </ListItemIcon>
                            <ListItemText primary="Fechar aplicativo" />
                        </ListItem>                         
                        */}
                    </List>



                </Container>
            </div>
        </div>
    )
}

