import { Sessao, SessaoDispatch, SessaoResult, SessaoID, Lgpd } from "./types";
import { Usuario } from "../usuario/types";
import Utils from "../../services/utils";

class SessaoProvider extends Utils implements SessaoDispatch {
    private count:number = 0;
    public dataSessao:Sessao = {
        _id:undefined,
        _rev:undefined,
        cart:[],
        user:{} as Usuario,
        appIsInstall:false,
        lgpd:{} as Lgpd 
    }
    
    createSessao = async (): Promise<SessaoResult> => {
        try {
            this.count++;
//            console.log("createSessao : "+this.count);
            let sessao:Sessao = {
                ...this.dataSessao,
                _id:SessaoID.ID,
            }
            let result = await this.createDataLocal(sessao);
            //console.log(result);
            if(result.ok){
                sessao._rev = result.rev
                return {status:"ok",row:sessao}                
            } else {
                return {status:"error",row:{} as Sessao, mensagem:"Falha ao iniciar uma nova sessao. Detalhes: "+JSON.stringify(result)}                
            }            
        } catch (error) {
            return {status:"error",row:{} as Sessao, mensagem:"Falha geral ao iniciar uma nova sessao. Detalhes: "+JSON.stringify(error)}                
        }
    }

    updateSessao = async (row:Sessao): Promise<SessaoResult> => {
        try {
            //console.log("provider updateSessao",row);
            let resp = await this.updateDataLocal(row);
            //console.log("response do update ", resp)
            if(resp.ok){
                row._rev = resp.rev
                return {status:"ok", row:row}
            } else {
                return {status:"error", row:row,mensagem:"Não foi possivel atualizar as informações da sessão."};
            }
        } catch (error) {
            return {status:"error", row:row, mensagem:"Falha geral no updateSessao da sessaoProvider, detalhes: "+JSON.stringify(error)};
        }
    }

    getSessao = async (): Promise<SessaoResult> => {
        try {
            this.count++;
            //console.log("getSessao : "+this.count);
            let resp = {
                ...this.dataSessao,
                ... await this.getDocLocal(SessaoID.ID)
            } 
            //console.log("reponse do getSessao"+JSON.stringify(resp));

            if(resp._id){
                return {status:"ok", row:resp as Sessao}
            } else {
                return {status:"error", row:{} as Sessao}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no getSessao da sessioProvider. Detalhes: "+JSON.stringify(error), row:{} as Sessao};
        }
    }

    checkSessaoStarted = async (): Promise<SessaoResult> => {
        try {
            let resp:SessaoResult  = await this.getSessao();
            if(resp.status === "ok"){
                return resp    
            } else {
                return resp;
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no checkSessaoStarted da sessioPriovider. Detalhes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }
    delSessao(): void {
        throw new Error("Method not implemented.");
    }
    setUserSessao(user:Usuario){}

    clearUserSessao = async ():Promise<SessaoResult> => {
        try {
            let resp = await this.getSessao();
            if (resp.status === 'ok') {
                let sessao = resp.row;
                sessao.user = {} as Usuario;
                let respUpdate = await this.updateSessao(sessao);
                if (respUpdate.status === 'ok') {
                    return {status:"ok", row:respUpdate.row};
                } else {
                    return {status:"error", mensagem:respUpdate.mensagem, row:respUpdate.row};
                }
            } else {
                return {status:"error", mensagem:resp.mensagem, row:resp.row}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no clearUserSessao do SessaoProvider. Detalhes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }
    
    clearCartSessao = async (): Promise<SessaoResult> => {
        try {
            let resp = await this.getSessao();
            if(resp.status==="ok"){
                let sessao = resp.row;
                //limpando o carrinho
                sessao.cart = [];
                let respUpdate = await this.updateSessao(sessao);
                if (respUpdate.status === "ok") {
                    return {status:"ok", row:respUpdate.row};
                } else {
                    return {status:"error", mensagem:respUpdate.mensagem, row:respUpdate.row};
                } 
            } else {
                return {status:"error", mensagem:resp.mensagem, row:resp.row}
            }
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no clearCartSessao do SessaoProvider. Detalhes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }

    setInstallAppSessao = async (): Promise<SessaoResult> => {
        try {
            let resp = await this.getSessao();
            if(resp.status==="ok"){
                let sessao = resp.row;
                //setando que o app foi instalado
                sessao.appIsInstall = true
                let respUpdate = await this.updateSessao(sessao);
                if (respUpdate.status === "ok") {
                    return {status:"ok", row:respUpdate.row};
                } else {
                    return {status:"error", mensagem:respUpdate.mensagem, row:respUpdate.row};
                } 
            } else {
                return {status:"error", mensagem:resp.mensagem, row:resp.row}
            }            
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no setInstallAppSessao do SessaoProvider. Detalhes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }

    setUninstallAppSessao = async (): Promise<SessaoResult> => {
        try {
            let resp = await this.getSessao();
            if(resp.status==="ok"){
                let sessao = resp.row;
                //setando que o app foi desinstalado
                sessao.appIsInstall = false
                let respUpdate = await this.updateSessao(sessao);
                if (respUpdate.status === "ok") {
                    return {status:"ok", row:respUpdate.row};
                } else {
                    return {status:"error", mensagem:respUpdate.mensagem, row:respUpdate.row};
                } 
            } else {
                return {status:"error", mensagem:resp.mensagem, row:resp.row}
            }            
        } catch (error) {
            return {status:"error", mensagem:"Falha geral no setUninstallAppSessao do SessaoProvider. Detalhes: "+JSON.stringify(error), row:{} as Sessao}
        }
    }  
    
    createRegistroLGPD = async ():Promise<SessaoResult> => {
        try {
            let dt = new Date();
            let newLGPD:Lgpd = {
                _id:undefined,
                _rev:undefined,
                tipo:"lgpd",
                datahora:this.getDataText(dt.toString()),
                plataforma:navigator.platform,
                aceitaCookie:true,
            }
            let response = await this.salvarRemoto(newLGPD);
            console.log("resp create Lgpd",response);
            if(response.status){
                let lgpd = response.row;
                let resp = await this.getSessao();
                let sessao = resp.row;
                sessao.lgpd = lgpd;
                return {status:"ok",row:sessao}
            } else {
                return {status:"error",mensagem:"Falha ao gerar o registro LGPD, detalhes: "+ JSON.stringify(response.erro), row:{}as Sessao}
            }
        } catch (error) {
            return {status:"error",mensagem:"Falha ao geral na função createRegistroLGPD LGPD, detalhes: "+ JSON.stringify(error), row:{}as Sessao}
        }
    }
    
}

const sessaoProvider = new SessaoProvider();
export default sessaoProvider;