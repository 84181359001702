import React, {useEffect} from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ApplicationState } from "../../store";
import * as SessaoActions from "../../store/data/start/actions";


import { AppBar, Container, createStyles, makeStyles, Theme, Toolbar, Typography,Button } from "@material-ui/core";
import { FaCookie } from "react-icons/fa"
import { SessaoDispatch, SessaoState } from "../../provider/start/types";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      height: '100%',
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginBottom: 40,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    title: {
      flexGrow: 1,
      marginLeft:theme.spacing(2)
    },
    listText: {
      marginLeft: theme.spacing(1),
    },
    textSmall: {
      fontSize: 10,
    },
    textMidle: {
      fontSize: 13,
    },
    containerText:{
        display:"flex",
        flexDirection:"column",
        flex:1,
        marginTop:5,
        marginBottom:50,
    },
    textInfo:{
        marginTop:theme.spacing(1)
    },
    containerButton:{
        flex:1
    },
    buttomConfirmar:{
        position:'fixed',
        bottom:5,
        display:"flex",
        alignSelf:'center',
    }     
  })
);

interface CookiesProps {
    sessaoState:SessaoState,
    sessaoActions:SessaoDispatch,
}

function Cookies(props:CookiesProps){
    const { row,sessaoStarted } = props.sessaoState
    const { _id } = row.lgpd
    const { createRegistroLGPD } = props.sessaoActions
    const routerHistory = useHistory();
    console.log("sessao",row);
    useEffect(()=>{
        if(_id !== undefined){
            routerHistory.push("/");
        }
    },[_id])

    const verificarCookieEnable = () => {
        document.cookie = "testCookie=valid";
        let cookieEnable = document.cookie.indexOf("testCookie") !== -1 ? true: false;
        if(cookieEnable){
            document.cookie = "testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        }
        console.log("cookie enable: ",cookieEnable);
        return cookieEnable;
    }

    const aceitarCookies = () => {
        let cookieEnable = verificarCookieEnable();
        if(cookieEnable){
            //gerar um registro Lgpd
            createRegistroLGPD()
            //colocar o registro gerado na sessao no localStorage

            //redirecionar para o inicio do app
        } else {
            //navegador nao aceita cookie, mostar aviso
            alert("Seu dispositivo está configurado para não permitir gravação de dados. Habilite os cookies em seu navegador para continuar a utilizar o aplicativo.")
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="sticky" color="primary">
                    <Toolbar>
                        <FaCookie size={25} />
                        <Typography variant="h6" className={classes.title}>
                            Concordar para acessar o app
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                    <div className={classes.containerText}>
                        <Typography variant="h6">
                            Utilizamos cookies para fornecer uma melhor experiência para nossos usuários.
                        </Typography>
                        <Typography  variant="body1" className={classes.textInfo}>
                            Cookies é um pequena aplicação que serve para guardar informações de nosso aplicativo, exemplo seus dados de login e os itens que é adicionado no carrinho.
                        </Typography>
                        <Typography variant="body1" className={classes.textInfo}>
                            Essas informações ficam somente no seu dispositivo, caso não aceite utilizar os cookies, essas informações será excluida assim que sair do aplicativo, pedindo novamente em um proximo acesso.
                        </Typography>
                        <Typography variant="h6">
                            Usuários de iPhone 
                        </Typography>
                        <Typography variant="body1" className={classes.textInfo}>
                            Verifique se o nosso aplicativo tem permissão para gravar os cookies nas configurações do seu navegador.
                        </Typography>
                        <Typography variant="body1" className={classes.textInfo}>
                            Para verificar:  1 - Abra o aplicativo ajustes, 2 - Desça a tela e click em Safari, 3 - Na parte de Privacidade e Segurança, Veja se as funções Impedir rastreamento e Bloquear todos os cookies esteja desabilitadas.
                        </Typography>

                        <Typography variant="body1" className={classes.textInfo}>
                            Caso não tenha interesse em realizar essas configurações, dê a permissão somente ao nosso aplicativo para gravar cookies, e assim terá uma melhor experiência no uso do nosso aplicativo.
                        </Typography>

                    </div>
                    <div className={classes.containerButton}>
                        <Button 
                            variant="contained" 
                            color="primary"
                            className={classes.buttomConfirmar}
                            onClick={()=>aceitarCookies()}
                        > Aceitar e Continuar no App</Button>
                    </div>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    sessaoState: state.start,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    sessaoActions: bindActionCreators(SessaoActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cookies);