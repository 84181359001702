import React from "react"

import ToolBarList from "../../../components/toolbar/toolbarList";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  })
);

interface PropsContainerSearch{
    handleOnChange:(value:any)=>void;
}

const comoFazerPedido = () =>{
    window.open("https://akisuafeira.com.br/#saibamais","_blank");
}

const whatsapp = () => {
    window.open("https://wa.me/557591182584","_blank")
}


function ContainerSearch(props:PropsContainerSearch){
    const {handleOnChange} = props;
    const classes = useStyles();
    return(
        <ToolBarList
            title="Catálogo"
            showInputSearch={true}
            inputSearch={{
                placeholder:"Encontrar Item...",
                handleOnChange:handleOnChange
            }}
            showButtonNovo={false}
            showButtonMenu={true}
            buttonMenu={{
                label:"Ajuda",
                title:"Ajuda",
                menuItens:[
                    {title:"Como fazer meu pedido?", handle:comoFazerPedido},
                    {title:"Conversar no whatsapp", handle:whatsapp},
                ]

            }}
            showButtonSearch={false}
            showButtonClose={false}
            showButtonWhats={false}
        ></ToolBarList> 
    )
}

export default ContainerSearch