import React, { useState, useEffect } from 'react';
import { TextField, createStyles, makeStyles, IconButton, Button, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@material-ui/core";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { concat } from 'pouchdb-find';

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    box: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    containerField: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingRight: 3
    },
    containerVirgula: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    spaceKG: {
        paddingTop: 5,
        paddingBottom: 5,
    }
}))
enum Action {
    insert = 'insert',
    update = 'update',
}
interface OwnProps {
    kgValue: number;
    setKgValue(value: any): void;
    changePreco: boolean;
    setChangePreco(value: boolean): void;
    qtMin: number;
}

interface State {
    kilo: number;
    grama: number;
    inc: number;
    showKgUn: string;//values KG UN 
    changed:boolean;//informa se a quantidade foi alterada ou nao. serve para a quantidade iniciar zerado
}

function Kilograma(props: OwnProps) {
    const { kgValue, setKgValue, changePreco, setChangePreco, qtMin } = props;
    const  QTMIN = qtMin <= 0 ? 0.1.toFixed(3) : qtMin.toFixed(3)
    //console.log("qt-Min",QTMIN);
    
    const [state, setState] = useState<State>({
        kilo: 0,
        grama: 0,
        inc: 0,
        showKgUn: "KG",
        changed:false,
    });
    //console.log("state",state);
    const classes = useStyles();

    const setIncrementoGrama = (pos?: string) => {
        //console.log("setIncrementoGrama")
        handleChangeQt();
        let inc = state.inc;
        //se for incremento soma +1 se nao e inc for maior que 0 subtrai -1
        switch (pos) {
            case "inc":
                inc++;
                break
            case "dec":
                inc > 0 ? inc-- : inc = inc;
                break;
            default:
                inc = inc;
                break;
        }
        //pos==="inc"? inc++ : inc > 0 ? inc-- : inc=inc;

        let kg = (Number(QTMIN) * inc).toFixed(3);
        //console.log("kg", kg);
        //console.log("inc", inc);
        let index = kg.indexOf(".");
        let kilo = Number(kg.substring(0, index));
        let grama = Number(kg.substring(index + 1, kg.length));
        if (Number.isNaN(grama))grama = 100;

        setState(state => ({
            ...state,
            kilo: kilo,
            grama: grama,
            inc: inc,
        }));
        //console.log("pos inc ", state)
    }

    const setKilo = (kilo: number) => {
        setChangePreco(false);
        setState(state => ({ ...state, kilo: kilo }));
    }

    const setGrama = (grama: number) => {
        setChangePreco(false);
        setState(state => ({ ...state, grama: grama }));
    }

    const setUnEq = (un: number) => {
        setChangePreco(false);
        setState(state => ({ ...state, inc: un }));
    }

    useEffect(() => {
        if(state.changed)
            KiloAndGramaToKgValue();
    }, [state.kilo, state.grama])

    useEffect(() => {
        if(state.changed)
            setIncrementoGrama()
    }, [state.inc])

    useEffect(() => {
        if(state.changed)
            valorToKiloAndGrama()
    }, [kgValue])

    useEffect(()=>{
        handleChangeQt()
        if(kgValue>0)
            valorToKiloAndGrama();
    },[changePreco])

    const valorToKiloAndGrama = () => {
        //console.log("valorToKiloAndGrama");    
        let k = 0, g = 0;
        let kgValueStr = "";
        if (kgValue === 0) {
            kgValueStr = QTMIN;
        } else {
            kgValueStr = String(kgValue);
        }
        //console.log("chamou valorToKiloAndGrama", kgValueStr);
        let index = kgValueStr.indexOf(".");
        k = Number(kgValueStr.substring(0, index));
        g = Number(kgValueStr.substring(index + 1, kgValueStr.length));
        if(Number.isNaN(g)){
            g = 100;
        }
        
        setState(state => ({
            ...state,
            kilo: k,
            grama: g,
            inc: Number((kgValue / (qtMin>0?Number(QTMIN):0.100)).toFixed(0)),
        }));

    }

    const KiloAndGramaToKgValue = () => {
        //console.log("KiloAndGramaToKgValue");
        if (!changePreco) {
            //console.log("chamou KiloAndGramaToKgValue", state);
            let grama: string = String(state.grama);
            while (grama.length < 3) grama = "0" + grama;
            let kg = String(state.kilo) + "." + grama;
            //console.log("kg val ", kg);
            setKgValue(Number(kg).toFixed(3));
        }
    }

    const incrementKilo = () => {
        handleChangeQt();
        setChangePreco(false);
        setState(state => ({ ...state, kilo: state.kilo + 1 }))
    }

    const decrementKilo = () => {
        if (state.kilo > 0) {
            setChangePreco(false);
            setState(state => ({ ...state, kilo: state.kilo - 1 }))
        }
    }

    const handleChangeRadio = (event:React.ChangeEvent<HTMLInputElement>) => {
        setState(state=>({...state,showKgUn:event.target.value}))
    }

    const handleChangeQt = () => {
        if(!state.changed){
            setState(state=>({...state, changed:true}));
        }
    }

    /*     const incrementGrama = () => {
            setChangePreco(false);
            setState(state=>({...state, grama:state.grama+100}))
        }
    
        const decrementGrama = () => {
            if(state.grama > 0) {
                setChangePreco(false);
                setState(state=>({...state, grama:state.grama-100}))
            }
        }    */

    return (
        <div className={classes.root}>
            <div id="controller-radio" className={classes.box}>
                <FormControl component="fieldset" className={classes.box}>
                    <FormLabel component="legend">Escolher por:</FormLabel>
                    <RadioGroup row defaultValue="end" value={state.showKgUn} onChange={handleChangeRadio}>
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label="KG"
                            value="KG"
                        />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label="UN"
                            value="UN"
                        />                    
                    </RadioGroup>
                </FormControl>                

            </div>
            <div id="controller-fields" className={classes.box}>

                {state.showKgUn==="KG"&&(

                    <>

                    <div className={classes.containerField}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={incrementKilo}
                        >
                            <AiOutlinePlus />
                        </Button>
                        <TextField
                            className={classes.spaceKG}
                            InputLabelProps={{ style: { paddingTop: 10 } }}
                            type="number"
                            name="kilo"
                            label="Kilo"
                            variant="outlined"
                            value={state.kilo}
                            size="small"
                            onChange={(result) => {
                                let value = Number(result.target.value)
                                if (Number.isInteger(value)) {
                                    if (value > -1) {
                                        setKilo(value);
                                    }
                                } else {
                                    setKilo(0);
                                }

                            }}
                        />
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={decrementKilo}
                        >
                            <AiOutlineMinus />
                        </Button>
                    </div>
                    <div className={classes.containerVirgula}><p>,</p></div>
                    <div className={classes.containerField}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setIncrementoGrama("inc")}
                        >
                            <AiOutlinePlus />
                        </Button>
                        <TextField
                            className={classes.spaceKG}
                            InputLabelProps={{ style: { paddingTop: 10 } }}
                            type="number"
                            name="grama"
                            label="Gramas"
                            variant="outlined"
                            value={state.grama}
                            size="small"
                            onChange={(result) => {
                                let value = Number(result.target.value);
                                if (Number.isInteger(value)) {
                                    if (value > -1) {
                                        setGrama(value);
                                    }
                                } else {
                                    setGrama(0);
                                }

                            }}
                        />
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setIncrementoGrama("dec")}

                        >
                            <AiOutlineMinus />
                        </Button>
                    </div>
                    {/**nao exibir unidade quando for kg 
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", flex:1,  marginLeft:10 }}>
                        <p>{state.inc} UN</p>
                    </div>                
                    */}

                    </>

                )}
                            
                {state.showKgUn==="UN"&&(
                    <>
                    <div className={classes.containerField}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setIncrementoGrama("inc")}
                        >
                            <AiOutlinePlus />
                        </Button>
                        <TextField
                            className={classes.spaceKG}
                            InputLabelProps={{ style: { paddingTop: 10 } }}
                            type="number"
                            name="inc"
                            label="Unidade"
                            variant="outlined"
                            value={state.inc}
                            size="small"
                            onChange={(result) => {
                                let value = Number(result.target.value);
                                if (Number.isInteger(value)) {
                                    if (value > -1) {
                                        setUnEq(value);
                                    }
                                } else {
                                    setUnEq(0);
                                }

                            }}
                        />
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setIncrementoGrama("dec")}

                        >
                            <AiOutlineMinus />
                        </Button>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", flex:1,  marginLeft:10 }}>
                         <p>{kgValue} KG</p>
                    </div>                     
                    </>
                )}


            </div>

        </div>
    )
}

export default Kilograma;