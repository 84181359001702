import React from "react"
import {
    Typography,
    AppBar,
    Toolbar,
    Container,
    Button,
    Grid,
    TextField as TextFieldMaterial,
    makeStyles,
    createStyles,
    IconButton,
    Paper,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { MdSave, MdExitToApp, MdLock } from "react-icons/md";
import TextPassword from "../../components/textPassword";
import Backdrop from "../../components/backdrop/backdrop";
import { Props, Usuario } from "../../provider/usuario/types";
import Utils from "../../services/utils";
import Modal from "../../components/Modal";
import Alerta from "../../components/alerts/Alerts";
import AlterarSenha from "./password";
import usuarioProvider from "../../provider/usuario";
import { useEffect } from "react";

const utils = new Utils();
const useStyles = makeStyles(() => createStyles({
    titlePaper: {
        marginBottom: 10,
    },
    paper: {
        marginBottom: 10, padding: 10, display: "flex", flexGrow: 1, flexDirection: "column"
    }
}))
function Formulario(props: Props) {
    const { usuarioActions, usuarioState } = props;
    const [showModalAlterarSenha, setShowModalAlterarSenha] = React.useState(false);
    const [showAltertSenha, setShowAlertSenha] = React.useState(false);
    const classes = useStyles();

    useEffect(()=>{
        if(usuarioState.row._id && !usuarioState.row.senha)
            setShowAlertSenha(true);
    },[usuarioState.row._id])
    const validate = (values: Usuario) => {
        const errors: any = {endereco:{}};
        console.log("values", values);
        

            if (utils.isEmpty(values.nome)) {
                errors.nome = 'Informe o seu nome!'
            }

            if (utils.isEmpty(values.sobrenome)) {
                errors.sobrenome = 'Informe o seu sobrenome!'
            }

            if (utils.isEmpty(values.apelido)) {
                errors.apelido = 'Informe o nome como é mais conhecido.'
            }

            if(utils.isEmpty(values.whatsapp)){
                errors.whatsapp = 'Informe o numero do seu whatsapp.'
            } else {
                if (values.whatsapp.length < 11) {
                    errors.whatsapp = 'Informe o numero com o DDD sem o ZERO, mais o prefixo 9.';
                }
            }
            if(!utils.isEmpty(values.endereco)){
                if (utils.isEmpty(values.endereco.rua)) {
                    errors.endereco.rua = 'Informe o nome da rua onde fica sua residência.'
                }
                if (utils.isEmpty(values.endereco.numero)) {
                    errors.endereco.numero = 'Informe o numero de sua residência.'
                }
                if (utils.isEmpty(values.endereco.bairro)) {
                    errors.endereco.bairro = 'Informe o nome do bairro ou povoado onde de sua residência.'
                }
                if (utils.isEmpty(values.endereco.complemento)) {
                    errors.endereco.complemento = 'Informe um ponto de referencia para chegar em sua residência.'
                }    
            }
        console.log("errors", errors);
        return errors;
    };

    const salvarUsuario = (value: Usuario) => {
        usuarioActions.salvar(value);
    }

    const handleCloseModalAlterarSenha = () => {
        setShowModalAlterarSenha(false);
    }

    const handleOpenModalAlterarSenha = (values:Usuario) => {
        //se for um novo usuario
        //manter os valores que já foram digitados no form
        if(!usuarioState.row._id){
            usuarioActions.setValueRow(values);
        }
        setShowModalAlterarSenha(true);
    }

    return (
        <div style={{ display: "flex", flexGrow: 1 }}>

            <Alerta show={showAltertSenha} menssage="Sua senha ainda não foi casdastrado!" tipoAlerta="error" handleClose={()=>setShowAlertSenha(false)} />

            <Backdrop show={usuarioState.loading} />
            <Modal
                component={<AlterarSenha onClose={handleCloseModalAlterarSenha} usuarioActions={usuarioActions} usuarioState={usuarioState} />}
                handleClose={handleCloseModalAlterarSenha}
                open={showModalAlterarSenha}
            />
            <Form
                onSubmit={salvarUsuario}
                initialValues={usuarioState.row}
                validate={validate}
            >
                {({
                    handleSubmit,
                    pristine,
                    submitting,
                    values,
                    form,
                    errors,
                }) => (
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                        >
                            <Paper elevation={3} variant={"elevation"} className={classes.paper}>

                                <Typography variant={"h6"} className={classes.titlePaper}>
                                    Dados Pessoais
                        </Typography>
                                <Grid
                                    container
                                    justify={"space-between"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={3}
                                >

                                    <Grid item xs={12}>
                                        <Field
                                            name="nome"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    label='Seu nome'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="sobrenome"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    label='Seu sobrenome'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="apelido"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    size="small"
                                                    label='Mais conheciado(a) como:'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="whatsapp"
                                            type="number"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    label='Whatsapp'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}

                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="email"
                                            type="email"

                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    size="small"
                                                    label='Email'
                                                    type="email"
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error}
                                                    helperText={props.meta.error}

                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant={
                                                "contained"
                                            }
                                            startIcon
                                            color={"primary"}
                                            fullWidth={true}
                                            onClick={()=> handleOpenModalAlterarSenha(values)}
                                        >
                                            <MdLock
                                                size={24}
                                            />
                                            {!usuarioState.row.senha ? "Cadastre sua senha" : "Alterar Senha"}
                                        </Button>

                                    </Grid>
                                    {usuarioState.row._id && (
                                        <Grid item xs={12}>
                                            <Field
                                                name="data_cad"
                                            >
                                                {props => (
                                                    <TextFieldMaterial
                                                        fullWidth
                                                        disabled
                                                        size="small"
                                                        label='Cliente desde:'
                                                        type="datetime"
                                                        name={props.input.name}
                                                        defaultValue={utils.getDataText(props.input.value)}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    )}

                                </Grid>
                            </Paper>

                            <Paper elevation={3} variant={"elevation"} className={classes.paper}>

                                <Typography variant={"h6"} className={classes.titlePaper}>
                                    Endereço
                        </Typography>
                                <Grid
                                    container
                                    justify={"space-between"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={3}
                                >
                                    <Grid item xs={12}>
                                        <Field
                                            name="endereco.rua"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    label='Nome da Rua'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}

                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="endereco.numero"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    size="small"
                                                    label='Numero'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}

                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="endereco.bairro"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    size="small"
                                                    label='Bairro'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}

                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="endereco.complemento"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    size="small"
                                                    label='Ponto de Referência'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                    onBlur={props.input.onBlur}
                                                    error={props.meta.error&&props.meta.touched}
                                                    helperText={props.meta.touched?props.meta.error:null}

                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="endereco.cidade"
                                        >
                                            {props => (
                                                <TextFieldMaterial
                                                    fullWidth
                                                    disabled
                                                    size="small"
                                                    label='Cidade'
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    variant="outlined"
                                                    onChange={props.input.onChange}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Paper>


                            <Grid item xs={12}>
                                <Button
                                    variant={
                                        "contained"
                                    }
                                    startIcon
                                    color={"primary"}
                                    fullWidth={true}
                                    type="submit"
                                    disabled={
                                        submitting || pristine || !errors
                                    }
                                >
                                    <MdSave
                                        size={24}
                                    />
                                Salvar
                                </Button>
                                
                            </Grid>
                        </form>
                    )}
            </Form>
        </div>
    )
}

export default Formulario