import React, {useEffect, useState} from "react"

import clsx from "clsx";
import { createStyles, makeStyles, Theme, Paper,Typography,List,ListItem, Grid } from "@material-ui/core";
import { Item,ItemDispatch,ItemState,FilterItem } from "../../../provider/item/types"
import Util from "../../../services/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerList:{
        display: 'flex',
        flexDirection:"row",
        flexWrap:"wrap",
        padding: 0 ,
        '& > *': {
            margin: theme.spacing(0.5),
            width: theme.spacing(14),
            minHeight: theme.spacing(17),
          },
    },
    containerGrid:{
        flexGrow:1,
        
    },
    containerItem:{
        display:"flex",
        flex:1,
        minHeight: theme.spacing(20),
        maxWidth: '-webkit-fill-available',
        backgroundColor:"#fff",
        cursor:'pointer',
        borderRadius:15,
    },
    img:{
        maxWidth:"100%",
        height: "auto",
        borderRadius:15,
    }, 
    containerTextImg:{
        position:'relative',
        maxWidth:"100%",
        display:"flex",
        flex:1,
        flexDirection:"column",
    },
    title:{
        flexGrow:0,
        lineHeight: '13px',
        overflow:'hidden',
    },
    titleDisable:{
        flexGrow:0,
        color:'gray',
        lineHeight: '13px',
        overflow:'hidden',
    },
    containerImgEnable:{        
        display:"flex",
        flex:1,
        borderTopLeftRadius:15,
        borderTopRightRadius:15,
    },
    containerImgDisable:{        
        display:"flex",
        flex:1,
        flexDirection:"column",
        justifyContent:'center',
        alignItems:"center",
        filter:'grayscale(100%)',
    },    
    containerPreco:{
        display:"flex",
        flex:1,
        flexDirection:"row",
        justifyContent:"space-between",
        color:"#333"
    },
    containerDescricao:{
        display:"flex",
        flex:1,
        flexDirection:"column",
        overflow: "hidden",
        color:"#333",
        padding:theme.spacing(1),
    },
    titleIndisponivel:{
        top: 25,
        display: 'flex',
        position: 'absolute',
        alignSelf: 'center',
        transform: 'rotate(-45deg)',
        border: 'solid 1px',
        borderRadius: 5,
        maxWidth: 97,
        color:'#790606',
    }
  })
);
const PATH_IMG = "https://akisuafeira.com.br/ws/App/Upload/";
const util = new Util();

interface ItemLojaProps{
    item:Item,
    handleOnClick(item:Item):void
}

export function ItemLoja(props:ItemLojaProps){
    const {_id,active,categoria,codigo,custo,descricao,detalhes,img,margem,preco,qt_min,uni_med,qt_estoque,estoque} = props.item;
    const { handleOnClick,item } = props
    const classes = useStyles();
    const [elevation,setElevation] = useState(0);
    const estoqueDisponivel = qt_estoque>qt_min;
    
    return(
        <Paper elevation={elevation} className={classes.containerItem} onClick={()=>handleOnClick(item)} onMouseEnter={()=>setElevation(3)} onMouseLeave={()=>setElevation(0)} >
            <div className={classes.containerTextImg}>
                <div className={clsx(classes.containerImgEnable, {[classes.containerImgDisable]:!estoqueDisponivel,})} style={{background:`url(${PATH_IMG+img}) no-repeat center`,backgroundSize:"cover",display:"flex"}}>
                     
                </div>                    
                {
                        !estoqueDisponivel &&(
                            <Typography className={classes.titleIndisponivel} variant="body2" >
                                Indisponível
                            </Typography>
                        )
                    } 
                <div className={classes.containerDescricao}>
                    <Typography  className={clsx(classes.title, {[classes.titleDisable]:!estoqueDisponivel})} color="primary" variant="body1"  >{descricao}</Typography>
                    <div className={classes.containerPreco}>
                        <Typography  variant="button" noWrap  >{util.formatMoney(preco)}</Typography>
                        <Typography  variant="button" noWrap  >{uni_med}</Typography>
                    </div>
                    <Typography variant="caption" style={{fontSize:10,lineHeight:1,overflow:'hidden'}} >{detalhes}</Typography>
                  
                </div>
                
            </div>
        </Paper>
    )
}
interface ListItemLojaProps{
    itemState:ItemState,
    itemActions:ItemDispatch,
    handleShowItem(item:Item):void
    filtro:FilterItem
}
export function ListItemLoja(props:ListItemLojaProps){
    const { rows, total_rows } = props.itemState;
    const { readAll,filtrar } = props.itemActions
    const { categoria,codigo,descricao } = props.filtro;
    const classes = useStyles();

    useEffect(()=>{
        readAll(false);
    },[])


    useEffect(()=>{
        filtrar(props.filtro)
    },[categoria.length,codigo,descricao])

    return(

        <Grid container spacing={0} className={classes.containerGrid} style={{WebkitOverflowScrolling:"auto"}}>
            {
                rows.map((item,i)=>(
                    <Grid item xs={6} sm={3} md={2} style={{padding:"1px",display:'flex', flex:1, margin:1,marginTop:5}} key={item._id} >
                        <ItemLoja
                            handleOnClick={props.handleShowItem}
                            item={item}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}
