import { call, put, takeLatest } from "redux-saga/effects";
import { TipoEntregaResult,EntregaPedido,TipoEntregaActionsType } from "../provider/types";
import {entregaPedidoProvider} from "../provider";
import * as TipoEntregaActions from "./actions";


//READ
export function* readAllTipoEntregas(actions:any){
    try {
        let resp:TipoEntregaResult = yield call(entregaPedidoProvider.readAll,{
            type:"selector",
            prmSelector:{selector:{tipo:"entrega",ativo:true}}
        });
        if(resp.status === "ok"){
            yield put(TipoEntregaActions.readAllSuccess(resp.rows as EntregaPedido[]))
        } else {
            yield put(TipoEntregaActions.readAllFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(TipoEntregaActions.readAllFailure("Ocorreu uma falha na estrutura da função readAllTipoEntregas em async. Detalhes: "+JSON.stringify(error)));
    }
}


//filtrar
export function* filtrarEntregas(actions:any){
    let resp:TipoEntregaResult = yield call(entregaPedidoProvider.filtrar,actions.payload.prm)
    yield put(TipoEntregaActions.filtrarSuccess(resp.rows as EntregaPedido[]));
}

export const tipoEntregaEffects = [
    takeLatest(TipoEntregaActionsType.READ_ALL,readAllTipoEntregas),
    takeLatest(TipoEntregaActionsType.FILTRAR, filtrarEntregas)
]