import { action } from "typesafe-actions";
import { Item } from "../../../provider/item/types";
import { EstoqueActionsType,Estoque } from "../provider/types";

export const create = (prm?:any) => action(EstoqueActionsType.CREATE,{prm})
export const createSuccess = (row:Estoque) => action(EstoqueActionsType.CREATE_SUCCESS, {row})
export const createFailure = (msg:string) => action(EstoqueActionsType.CREATE_FAILURE, {msg})

export const readAll = (prm?:any) => action(EstoqueActionsType.READ_ALL, { prm })
export const readAllSuccess = (rows:Estoque[]) => action(EstoqueActionsType.READ_ALL_SUCCESS, { rows })
export const readAllFailure = (msg:string) => action(EstoqueActionsType.READ_ALL_FAILURE, { msg })

export const read = (prm?:any) => {}

//actions UPDATE
export const update = (row:Estoque)=> action(EstoqueActionsType.UPDATE, {row})
export const updateSuccess = (row:Estoque) => action(EstoqueActionsType.UPDATE_SUCCESS, { row })
export const updateFailure = (msg:string) => action(EstoqueActionsType.CREATE_FAILURE, {msg})

//actions DELETE
export const del = (row:Estoque)=>action(EstoqueActionsType.DELETE, {row});
export const delSuccess = (row:Estoque) => action(EstoqueActionsType.DELETE_SUCCESS, {row})
export const delFailure = (msg:string) => action(EstoqueActionsType.DELETE_FAILURE, {msg})

export const filtrar = (value:string) => action(EstoqueActionsType.FILTRAR, { value })
export const filtrarSuccess = (rows:Estoque[]) => action(EstoqueActionsType.FILTRAR_SUCCESS, { rows })

export const setRow = (row:Estoque)=>action(EstoqueActionsType.SET_ROW, {row});

export const clearError = ()=>action(EstoqueActionsType.CLEAR_ERROR);

export const setShowForm = () => action(EstoqueActionsType.SET_SHOW_FORM)