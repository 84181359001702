import { call, put } from "redux-saga/effects";
import { CategoriaResult, Categoria } from "../provider/types";
import CategoriaProvider from "../provider";
import * as CategoriaActions from "./actions";


//CREATE
export function* createCategoria() {
    try {
        console.log("chamou createCategoria")
        const resp:CategoriaResult = yield call(CategoriaProvider.createCategoria)
        if (resp.status === "ok") {
            yield put(CategoriaActions.createCategoriaSuccess(resp.row as Categoria))    
        } else {
            yield put(CategoriaActions.createCategoriaFailure("Ouve uma falha ao criar uma nova categoria. Detalhes: "+JSON.stringify(resp.mensagem)))
        }
    } catch (error) {
        yield put(CategoriaActions.createCategoriaFailure("Ouve uma falha na função createCategoria no async. Detalhes: "+JSON.stringify(error)))
    }
}

//READ
export function* readAllCategoria(actions:any){
    try {
        let resp:CategoriaResult = yield call(CategoriaProvider.readAllCategorias)
        if(resp.status === "ok") {
            yield put(CategoriaActions.readAllSuccess(resp.rows as Categoria[]));
        } else {
            yield put(CategoriaActions.readAllFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(CategoriaActions.readAllFailure("Ocorreu uma falha na estrutura da função readAllCategorias em async. Detalhes: "+JSON.stringify(error)));
    }
}

//UPDATE
export function* updateCategoria(actions:any) {
    try {
        const resp:CategoriaResult = yield call(CategoriaProvider.updateCategoria,actions.payload.row);
        if (resp.status === "ok") {
            yield put(CategoriaActions.updateCategoriaSuccess(resp.row as Categoria))
            yield put(CategoriaActions.readAllCategorias());
        } else {
            yield put(CategoriaActions.updateCategoriaFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(CategoriaActions.updateCategoriaFailure("Ouve uma falha na função updateCategoria em async. Detalhes: "+JSON.stringify(error)))
    }
}

//DELETE
export function* DeleteCategoria(actions:any) {
    try {
        const resp:CategoriaResult = yield call(CategoriaProvider.delCategoria, actions.payload.row);
        if (resp.status === "ok") {
            yield put(CategoriaActions.delCategoriaSuccess());
            yield put(CategoriaActions.readAllCategorias());
        } else {
            yield put(CategoriaActions.delCategoriaFailure(resp.mensagem as string))
        }
    } catch (error) {
        yield put(CategoriaActions.delCategoriaFailure("Ouve uma falha na função deleteCategoria em async. Detalhes: "+JSON.stringify(error)))
    }
}