var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
function handleClicked() {return alert("iframe clicked") }
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/609a9ece185beb22b30c47aa/1f5dvomlq';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
var interval = setInterval(()=>{
    var i = document.getElementsByTagName("iframe")[0];
    if(i){
        var id = document.getElementsByTagName("iframe")[0].parentElement.getAttribute("id");
        var el = document.getElementById(id);
        el.style = 'display: block !important;z-index:1101 !important; bottom:80px !important;'
        i.removeAttribute("style");
        var att = document.createAttribute("onload");
        att.value = handleClicked;
        i.setAttributeNode(att);

        //i.setAttribute("onclick","handleClicked();");
        //i.setAttribute("id","my-tawlk-iframe");
        //i.style.bottom = '80px';
        //i.style.zIndex = 100 ;
        clearInterval(interval);
    }
        
},1000)
})();

