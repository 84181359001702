import { Reducer } from "redux";
import { PedidoActionsTypes, PedidoState, ShowDataPedido } from "../../../provider/pedido/types";

const INITIAL_STATE:PedidoState = {
    row:{} as ShowDataPedido,
    rows:[],
    loading:false,
    error:false,
    msg:"",
    showForm:false,
    total_rows:0,
    sended:false,
};

const reducer:Reducer<PedidoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PedidoActionsTypes.CREATE_PEDIDO:
            return {...state, loading:true, error:false, msg:""};
        case PedidoActionsTypes.CREATE_PEDIDO_SUCCESS:
            return {...state, loading:false, error:false, row:action.payload.row , sended:false};
        case PedidoActionsTypes.CREATE_PEDIDO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case PedidoActionsTypes.ADD_MEIO_PAG:
            return {...state, row:{...state.row, pedido:{...state.row.pedido, fechamento:{...state.row.pedido.fechamento, meios_pag:[...state.row.pedido.fechamento.meios_pag, action.payload.meioPag ] } } } }
        case PedidoActionsTypes.SET_VALOR_FECHAMENTO:
            return {...state, row:{...state.row, pedido:{...state.row.pedido, fechamento:{...state.row.pedido.fechamento, meios_pag:[...state.row.pedido.fechamento.meios_pag.map(value=>{if(value.codigo===action.payload.codigo){return {...value, valor:action.payload.valor, troco:action.payload.valor>action.payload.total?action.payload.valor-action.payload.total:0} } else return {...value} } ) ] } } } }
        case PedidoActionsTypes.SET_FECHAMENTO_PEDIDO:
            console.log("chamou SET_FECHAMENTO_PEDIDO",action)
            return {... state, row:{...state.row, pedido:{...state.row.pedido, fechamento:{...state.row.pedido.fechamento, acrescimos:action.payload.acrescimos, descontos:action.payload.descontos, subtotal:action.payload.subtotal, total:action.payload.total } } } }
        case PedidoActionsTypes.SET_TIPO_ENTREGA:
            return {...state, row:{...state.row, pedido:{...state.row.pedido, entrega:action.payload.tipoEntrega } } }
        case PedidoActionsTypes.SET_OBS_PEDIDO:
            return {...state, row:{...state.row, pedido:{...state.row.pedido, obs:action.payload.obs } } }    

        case PedidoActionsTypes.REM_MEIO_PAG:
            let arr:any[] = state.row.pedido.fechamento.meios_pag;
            arr.splice(action.payload.idx,1);
            return {...state, row:{...state.row, pedido:{...state.row.pedido, fechamento:{...state.row.pedido.fechamento, meios_pag:arr  } } } }

        case PedidoActionsTypes.SEND_PEDIDO:
            return {...state, loading:true, error:false, msg:""}
        case PedidoActionsTypes.SEND_PEDIDO_SUCCESS:
            return {...state, loadign:false, row:action.payload.row, sended:true}
        case PedidoActionsTypes.SEND_PEDIDO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}
            
        case PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO:
            return {...state, loading:true, error:false, msg:""}
        case PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO_SUCCESS:
            return {...state, loading:false, error:false, rows:action.payload.rows, total_rows:action.payload.rows.length };
        case PedidoActionsTypes.READ_PEDIDOS_ANDAMENTO_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg }

        case PedidoActionsTypes.READ_ALL_PEDIDOS:
            return {...state, loading:true, error:false, msg:""};
        case PedidoActionsTypes.READ_ALL_PEDIDOS_SUCCESS:
            return {...state, loading:false, rows:action.payload.rows, total_rows:action.payload.rows.length};
        case PedidoActionsTypes.READ_ALL_PEDIDOS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};
            
        default:
            return state;
    }
}

export default reducer;