import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      zIndex: theme.zIndex.drawer + 10,
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface State extends SnackbarOrigin {
  duration: number;
}

interface ParamsAlerts {
  tipoAlerta: "error" | "warning" | "info" | "success";
  show: boolean;
  menssage: string;
  handleClose():void;
  buttonAction?:JSX.Element;
}

export default function Alerts(params: ParamsAlerts) {
  const [state, setState] = React.useState<State>({
    vertical: "top",
    horizontal: "center",
    duration: 7000
  });
  const { tipoAlerta, show, menssage, handleClose, buttonAction } = params;

  const styles = useStyles();
  const { vertical, horizontal, duration } = state;


  return (
    <Snackbar
      id={'snackbar-alert'}
      open={show}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      className={styles.alert}
    >
      <Alert onClose={handleClose} severity={tipoAlerta} action={buttonAction}>
        {menssage}
      </Alert>
    </Snackbar>
  );
}
