import { action } from "typesafe-actions";
import { MeioPagActionsTypes,MeioPag } from "../provider/types";

//actions CREATE
export const createMeioPag= () => action(MeioPagActionsTypes.CREATE_MEIO_PAG)
export const createMeioPagSuccess = (row:MeioPag) => action(MeioPagActionsTypes.CREATE_MEIO_PAG_SUCCESS, { row })
export const createMeioPagFailure = (msg:string) => action(MeioPagActionsTypes.CREATE_MEIO_PAG_FAILURE, {msg})

//acttions READ
export const readAll= () => action(MeioPagActionsTypes.READ_ALL_MEIO_PAG)
export const readAllSuccess = (rows:MeioPag[]) => action(MeioPagActionsTypes.READ_ALL_MEIO_PAG_SUCCESS, { rows })
export const readAllFailure = (msg:string) => action(MeioPagActionsTypes.READ_ALL_MEIO_PAG_FAILURE, {msg})

//actions UPDATE
export const updateMeioPag= (row:MeioPag) => action(MeioPagActionsTypes.UPDATE_MEIO_PAG, {row} )
export const updateMeioPagSuccess = (row:MeioPag) => action(MeioPagActionsTypes.UPDATE_MEIO_PAG_SUCCESS, { row })
export const updateMeioPagFailure = (msg:string) => action(MeioPagActionsTypes.CREATE_MEIO_PAG_FAILURE, {msg})

//actions DELETE
export const delMeioPag= (row:MeioPag) => action(MeioPagActionsTypes.DELETE_MEIO_PAG, {row} )
export const delMeioPagSuccess = () => action(MeioPagActionsTypes.DELETE_MEIO_PAG_SUCCESS)
export const delMeioPagFailure = (msg:string) => action(MeioPagActionsTypes.DELETE_MEIO_PAG_FAILURE, {msg})

//other actions

export const setRowMeioPag = (row:MeioPag) => action(MeioPagActionsTypes.SET_ROW_MEIO_PAG, {row})

export const setShowForm = () => action(MeioPagActionsTypes.SET_SHOW_FORM)
