import { GlobalStates, GlobalResult, ColumnTable } from "../../../services/globalTypes";

/**parametro que indica que vai gerar um novo registro no banco de dados */
export enum TipoRegistro {
    caixa = "CAIXA",
    titulo = "TITULO",//conta a receber
    none = "NONE",//
}

export interface MeioPag{
    readonly _id:any
    readonly _rev:any
    tipo:string
    codigo:number
    descricao:string
    percDesc:number//percentual de desconto
    forma:{
        descricao:string
        gerarRegistro:boolean
        tipoRegistro:TipoRegistro
        permiteTroco:boolean
    }
    
}

export interface MeioPagResult extends GlobalResult {
    row?:MeioPag,
    rows?:MeioPag[]
}

export enum MeioPagActionsTypes {
    //CREATE
    CREATE_MEIO_PAG = "@CreateMeioPag/CREATE_MEIO_PAG",
    CREATE_MEIO_PAG_SUCCESS = "@CreateMeioPagSuccess/CREATE_MEIO_PAG_SUCCESS",
    CREATE_MEIO_PAG_FAILURE = "@CreateMeioPagFailure/CREATE_MEIO_PAG_FAILURE",

    //READ
    READ_ALL_MEIO_PAG = "@ReadAllMeioPag/READ_ALL_MEIO_PAG",
    READ_ALL_MEIO_PAG_SUCCESS = "@ReadAllMeioPagSuccess/READ_ALL_MEIO_PAG_SUCCESS",
    READ_ALL_MEIO_PAG_FAILURE = "@ReadAllMeioPagFailure/READ_ALL_MEIO_PAG_FAILURE",

    //UPDATE
    UPDATE_MEIO_PAG = "@UpdateMeioPag/UPDATE_MEIO_PAG",
    UPDATE_MEIO_PAG_SUCCESS = "@UpdateMeioPagSuccess/UPDATE_MEIO_PAG_SUCCESS",
    UPDATE_MEIO_PAG_FAILURE = "@UpdateMeioPagFailure/UPDATE_MEIO_PAG_FAILURE",

    //DELETE
    DELETE_MEIO_PAG = "@DeleteMeioPag/DELETE_MEIO_PAG",
    DELETE_MEIO_PAG_SUCCESS = "@DeleteMeioPagSuccess/DELETE_MEIO_PAG_SUCCESS",
    DELETE_MEIO_PAG_FAILURE = "@DeleteMeioPagFailureDELETE_MEIO_PAG_FAILURE",

    SET_ROW_MEIO_PAG = "@setRowMeioPag/SET_ROW_MEIO_PAG",

    SET_SHOW_FORM = "@setShowForm/SET_SHOW_FORM",

}

export interface MeioPagState extends GlobalStates<MeioPag> {
    readonly row:MeioPag,
    readonly rows:MeioPag[],
}

export interface MeioPagDispatch {
    createMeioPag: () => void
    readAll: ()=> void
    updateMeioPag: (row:MeioPag) => void
    delMeioPag:(row:MeioPag) => void
    setRowMeioPag: (row:MeioPag)=>void
    setShowForm: ()=>void
}




