import { Item } from "../../../provider/item/types";
import {GlobalResult,GlobalStates,ColumnTable,GlobalDoc,GlobalDispatch } from "../../../services/globalTypes";

export interface Estoque extends GlobalDoc {
    tipo:"estoque",
    data:string;//data criado
    mov_id:string;
    itens_estoque:ItemEstoque[];
}

export interface ItemEstoque {
    item_id:string;
    qt:number;
}

export enum EstoqueActionsType {
    //CREATE
    CREATE = "@Estoque/CREATE",
    CREATE_SUCCESS = "@Estoque/CREATE_SUCCESS",
    CREATE_FAILURE = "@Estoque/CREATE_FAILURE",

    //READ
    READ_ALL = "@Estoque/READ_ALL",
    READ_ALL_SUCCESS = "@Estoque/READ_ALL_SUCCESS",
    READ_ALL_FAILURE = "@Estoque/READ_ALL_FAILURE",

    //UPDATE
    UPDATE = "@Estoque/UPDATE",
    UPDATE_SUCCESS = "@Estoque/UPDATE_SUCCESS",
    UPDATE_FAILURE = "@Estoque/UPDATE_FAILURE",

    //DELETE
    DELETE = "@Estoque/DELETE",
    DELETE_SUCCESS = "@Estoque/DELETE_SUCCESS",
    DELETE_FAILURE = "@EstoqueDELETE_FAILURE",

    //FILTRO ARRAY

    FILTRAR = "@Estoque/FILTRAR",
    FILTRAR_SUCCESS = "@Estoque/FILTRAR_SUCCESS",

    SET_ROW = "@Estoque/SET_ROW",

    SET_SHOW_FORM = "@Estoque/SET_SHOW_FORM",

    CLEAR_ERROR = "@Estoque/CLEAR_ERROR",
}

export interface EstoqueResult extends GlobalResult {}
export interface EstoqueState extends GlobalStates<Estoque> {}
export interface EstoquesActions extends GlobalDispatch<Estoque>{}
