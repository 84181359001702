import { GlobalStates, GlobalResult } from "../../services/globalTypes";
import { itemCart, showDataItemCart } from "../itemCart/types";
import { PedidoDispatch } from "../pedido/types";

export interface PedidoItens extends itemCart{}

export interface ShowDataPedidoItens extends showDataItemCart {}

export interface PedidoItensResult extends GlobalResult {
    row?:ShowDataPedidoItens,
    rows?:ShowDataPedidoItens[],
}

export enum PedidoItensActionsType {
    READ_PEDIDO_ITENS = "@readPedidoItens/READ_PEDIDO_ITENS",
    READ_PEDIDO_ITENS_SUCCESS = "@readPedidoItensSuccess/READ_PEDIDO_ITENS_SUCCESS",
    READ_PEDIDO_ITENS_FAILURE = "@readPedidoItensFailure/READ_PEDIDO_ITENS_FAILURE",

    SELECT_PEDIDO_ITEM = "@selectPedidoItem/SELECT_PEDIDO_ITEM",
    SELECT_ALL_ITENS_PEDIDO = "@selectAllItensPedido/SELECT_ALL_ITENS_PEDIDO",

    REPEAT_ITENS_PEDIDO = "@repeatItensPedido/REPEAT_ITENS_PEDIDO",
    REPEAT_ITENS_PEDIDO_SUCCESS = "@repeatItensPedidoSuccess/REPEAT_ITENS_PEDIDO_SUCCESS",
    REPEAT_ITENS_PEDIDO_FAILURE = "@repeatItensPedidoFailure/REPEAT_ITENS_PEDIDO_FAILURE",

    SET_SEND_TO_CART = "@setSendToCart/SET_SEND_TO_CART",

}

export interface PedidoItensState extends GlobalStates<ShowDataPedidoItens> {
    readonly row:ShowDataPedidoItens,
    readonly rows:ShowDataPedidoItens[],
    readonly rowsSel:ShowDataPedidoItens[],
    readonly sendToCart:boolean,
}

export interface PedidoItensDispatch {
    readPedidoItens(mov_id:string):void;
    selectPedidoItem(_id:string):void;
    selectAllItens(selected:boolean):void;
    repeatItensPedido(itensSel:ShowDataPedidoItens[], materPreco?:boolean):void;
    setSendToCart(parms:boolean):void
}

export interface PedidoItensProps {
    pedidoItensState:PedidoItensState
    pedidoItensActions:PedidoItensDispatch
}