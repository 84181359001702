import React from "react";
import { Reducer } from "redux";
import { PedidoItensState, PedidoItensActionsType, ShowDataPedidoItens } from "../../../provider/pedidoItens/types";
import { Item } from "../../../provider/item/types";
import { itemCart, showDataItemCart } from "../../../provider/itemCart/types";


const INITIAL_STATE:PedidoItensState = {
    error:false,
    loading:false,
    msg:"",
    row:{itemData:{} as Item,itemCartData:{} as itemCart},
    rows:[],
    rowsSel:[],
    showForm:false,
    total_rows:0,
    sendToCart:false,
};


const reducer:Reducer<PedidoItensState> = (state=INITIAL_STATE,action) => {
    switch (action.type) {
        case PedidoItensActionsType.READ_PEDIDO_ITENS:
            return {...state,loading:true, error:false, msg:"",sendToCart:false};
        case PedidoItensActionsType.READ_PEDIDO_ITENS_SUCCESS:
            return {...state, loading:false, rows:action.payload.rows, rowsSel:[], total_rows:action.payload.rows.length};
        case PedidoItensActionsType.READ_PEDIDO_ITENS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case PedidoItensActionsType.SELECT_PEDIDO_ITEM:
            let rowsSel = state.rows;
            let rowFindSel = rowsSel.filter(item=>{
                if (item.itemCartData._id === action.payload._id) {
                    item.selected = !item.selected
                }
                if (item.selected) {
                    return item;
                }
            });

            return {...state, rows: rowsSel, rowsSel:rowFindSel}
        
        case PedidoItensActionsType.SELECT_ALL_ITENS_PEDIDO:
            let rowsSelAll = state.rows;
            let rowFindSellAll = rowsSelAll.filter(item=>{
                if(item.selected !== action.payload.selected){
                    item.selected = action.payload.selected;
                }    
                if (item.selected) {
                    return item;
                }
            });
            return {...state, rows:rowsSelAll, rowsSel:rowFindSellAll}
        
        case PedidoItensActionsType.REPEAT_ITENS_PEDIDO:
            return {...state, loading:true, error:false, msg:"", sendToCart:false}
        case PedidoItensActionsType.REPEAT_ITENS_PEDIDO_SUCCESS:
            return {...state, loading:false, sendToCart:true }
        case PedidoItensActionsType.READ_PEDIDO_ITENS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}

        case PedidoItensActionsType.SET_SEND_TO_CART:
            return {...state, sendToCart:action.payload.parms};

        default:
            return state;
    }
}

export default reducer;