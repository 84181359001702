import { action } from "typesafe-actions";
import { CategoriaActionsTypes,Categoria } from "../provider/types";

//actions CREATE
export const createCategoria= () => action(CategoriaActionsTypes.CREATE_CATEGORIA)
export const createCategoriaSuccess = (row:Categoria) => action(CategoriaActionsTypes.CREATE_CATEGORIA_SUCCESS, { row })
export const createCategoriaFailure = (msg:string) => action(CategoriaActionsTypes.CREATE_CATEGORIA_FAILURE, {msg})

//acttions READ
export const readAllCategorias= () => action(CategoriaActionsTypes.READ_ALL_CATEGORIA)
export const readAllSuccess = (rows:Categoria[]) => action(CategoriaActionsTypes.READ_ALL_CATEGORIA_SUCCESS, { rows })
export const readAllFailure = (msg:string) => action(CategoriaActionsTypes.READ_ALL_CATEGORIA_FAILURE, {msg})

//actions UPDATE
export const updateCategoria= (row:Categoria) => action(CategoriaActionsTypes.UPDATE_CATEGORIA, {row} )
export const updateCategoriaSuccess = (row:Categoria) => action(CategoriaActionsTypes.UPDATE_CATEGORIA_SUCCESS, { row })
export const updateCategoriaFailure = (msg:string) => action(CategoriaActionsTypes.CREATE_CATEGORIA_FAILURE, {msg})

//actions DELETE
export const delCategoria= (row:Categoria) => action(CategoriaActionsTypes.DELETE_CATEGORIA, {row} )
export const delCategoriaSuccess = () => action(CategoriaActionsTypes.DELETE_CATEGORIA_SUCCESS)
export const delCategoriaFailure = (msg:string) => action(CategoriaActionsTypes.DELETE_CATEGORIA_FAILURE, {msg})

//selected

export const addCatSelected = (row:Categoria) => action(CategoriaActionsTypes.ADD_CAT_SELECTED, {row});

export const delSelected = (idx:number) => action(CategoriaActionsTypes.DELETE_CAT_SELECTED, {idx})

//other actions

export const setRowCategoria = (row:Categoria) => action(CategoriaActionsTypes.SET_ROW_CATEGORIA, {row})

export const setShowForm = () => action(CategoriaActionsTypes.SET_SHOW_FORM)

export const clearErroCategoria = () => action(CategoriaActionsTypes.CLEAR_ERROR_CATEGORIA)