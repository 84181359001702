import axios from "axios";
interface PropsFunctionUpdate {
    url:string;
    user:string;
    pass:string;
    functionUpCouchName:string;
}
interface PropsResponse {
    data:any
}
export default abstract class ApiHttp {
    async CouchDbFunctionUpdate (props:PropsFunctionUpdate):Promise<PropsResponse> {
        const {url,user,pass,functionUpCouchName} = props;
        let _design = "/_design/func/_update/"+functionUpCouchName;
        let resp = await axios({
          method:'PUT',
          url:url+_design,
          auth:{
              username:user,
              password:pass,
          }
        });
        return {data:resp.data};
    }
}

export const ApiGestor = axios.create({
    baseURL:"https://akisuafeira.com.br/ws/v1",
    //headers:{'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', }
});