import React from "react";
import { BrowserRouter, Switch, Route, useRouteMatch } from "react-router-dom";

import Start from "../view/Start";
import Main from "../view/Main";
import Catalogo from "../view/Catalogo";
import Cart from "../view/Cart";
import History from "../view/History";
import Perfil from "../view/Perfil";
import Confirmar from "../view/SteppsDelivery";
import PedidosAtuais from "../view/PedidoAtual";
import PedidoEnviado from "../view/PedidoEnviado";
import Cookies from "../view/Cookies"
import Loja from "../view/Loja"
import ViewRecuperarEmail from "../modulos/resetPwd/sendEmail"
import EmailSentView from "../modulos/resetPwd/emailSent"
import validarLink from "../modulos/resetPwd/validarLink";
import GroupOfertaView from "../modulos/kitpromocao/view/grupOfertas"

export function MainRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Start}></Route>
        <Route path="/catalogo" component={Main}></Route>
        <Route path="/andamento" component={PedidosAtuais}></Route>
        <Route path="/enviado" component={PedidoEnviado}></Route>
        <Route path="/aceitacookies" component={Cookies}></Route>
        <Route path={"/sendEmailRecSenha"} component={ViewRecuperarEmail}></Route>        
        <Route path={"/sentEmailSucess"} component={EmailSentView}></Route>        
        <Route path={"/confirmarResetSenha/:idUser"} component={validarLink}></Route>        
        <Route path={"/grupowhatsappofertas"} component={GroupOfertaView}></Route>        
      </Switch>
    </BrowserRouter>
  );
}

export function Routes() {
  const { url, path } = useRouteMatch(); 
  return (
    <Switch>
        <Route exact path={`${path}`} component={()=><div>Seja bem vindo</div>} ></Route>
        <Route exact path={`${url}/itens`} component={Loja} ></Route>
        <Route exact path={`${url}/cart`} component={Cart}></Route>
        <Route path={`${url}/historico`} component={History}></Route>
        <Route path={`${url}/perfil`} component={Perfil}></Route>
        <Route exact path={`${url}/confirmar`} component={Confirmar}></Route>
    </Switch>  
  );
}

