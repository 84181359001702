import {Reducer} from "redux";
import { EstoqueState,EstoqueActionsType,Estoque } from "../provider/types";
import { excluirRegistroArrayRow } from "../../../utilsJs"

const INITIAL_STATE : EstoqueState = {
    row: {} as Estoque,
    rows:[],
    error:false,
    loading:false,
    msg:"",
    showForm:false,
    total_rows:0,
};

const reducer:Reducer<EstoqueState> = (state=INITIAL_STATE,action) => {
    switch (action.type) {
        //CREATE
        case EstoqueActionsType.CREATE:
            return { ...state, loading: true, error: false }
        case EstoqueActionsType.CREATE_SUCCESS:
            return { ...state, loading: false, row: action.payload.row }
        case EstoqueActionsType.CREATE_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        //READ
        case EstoqueActionsType.READ_ALL:
            return {...state, loading:true, error:false, msg:""}
        case EstoqueActionsType.READ_ALL_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
        case EstoqueActionsType.READ_ALL_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg }

        //UPDATE
        case EstoqueActionsType.UPDATE:
            return { ...state, loading: true, error: false }
        case EstoqueActionsType.UPDATE_SUCCESS:
            let updateRows = state.rows;
            updateRows.push(action.payload.row);
            return { ...state, loading: false, error: false, row: action.payload.row, rows:updateRows,total_rows:updateRows.length }
        case EstoqueActionsType.UPDATE_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        //DELETE
        case EstoqueActionsType.DELETE:
            return { ...state, loading: true, error: false }
        case EstoqueActionsType.DELETE_SUCCESS:
            let deleteRows = state.rows;
            excluirRegistroArrayRow(deleteRows,action.payload.row)
            return { ...state, loading: false, error: false, rows:deleteRows, total_rows:deleteRows.length }
        case EstoqueActionsType.DELETE_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        case EstoqueActionsType.FILTRAR:
            return {...state, loading:true, error:false} 
        case EstoqueActionsType.FILTRAR_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
            
        case EstoqueActionsType.SET_SHOW_FORM:
            return {...state, showForm:!state.showForm };

        case EstoqueActionsType.SET_ROW:
            return {...state, showForm:!state.showForm, row:action.payload.row };

        case EstoqueActionsType.CLEAR_ERROR:
            return {...state, error:false,msg:"" }
        default:
            return { ...state};
    }
}

export default reducer;