import { GlobalStates, GlobalResult } from "../../services/globalTypes";
import { Usuario } from "../usuario/types";
import { showDataItemCart } from "../itemCart/types";
export enum SessaoID {
    ID = "sessao"
}
export interface Lgpd{
    tipo: "lgpd",
    _id: any, 
    _rev: any,
    datahora:string;
    plataforma:string;
    aceitaCookie:boolean
}
export interface Sessao {
    _id:SessaoID|undefined;
    _rev:any;
    user:Usuario;
    cart:showDataItemCart[];
    appIsInstall:boolean
    lgpd:Lgpd
}

export enum SessaoActionsTypes {
    
    CREATE_SESSAO = "@createSessao/CREATE_SESSAO",
    CREATE_SESSAO_SUCCESS = "@createSessaoSuccess/CREATE_SESSAO_SUCCESS",
    CREATE_SESSAO_FAILURE = "@createSessaoFailure/CREATE_SESSAO_FAILURE",

    GET_SESSAO = "@getSessao/GET_SESSAO",
    GET_SESSAO_SUCCESS = "@getSessaoSuccess/GET_SESSAO_SUCCESS",
    GET_SESSAO_FAILURE = "@getSessaoSuccessFailure/GET_SESSAO_FAILURE",

    UPDATE_SESSAO = "@updateSessao/UPDATE_SESSAO",
    UPDATE_SESSAO_SUCCESS = "@updateSessaoSuccess/UPDATE_SESSAO_SUCCESS",
    UPDATE_SESSAO_FAILURE = "@updateSessaoFailure/UPDATE_SESSAO_FAILURE",

    DEL_SESSAO = "@delSessao/DEL_SESSAO",
    DEL_SESSAO_SUCCESS = "@delSessaoSuccess/DEL_SESSAO_SUCCESS",
    DEL_SESSAO_FAILURE = "@delSessaoFailure/DEL_SESSAO_FAILURE",

    SET_USER_SESSAO = "@setUserSessao/SET_USER_SESSAO",
    SET_USER_SESSAO_SUCCESS = "@setUserSessaoSuccess/SET_USER_SESSAO_SUCCESS",
    SET_USER_SESSAO_FAILURE = "@setUserSessaoFailure/SET_USER_SESSAO_FAILURE",

    CLEAR_USER_SESSAO = "@clearUserSessao/CLEAR_USER_SESSAO",
    CLEAR_USER_SESSAO_SUCCESS = "@clearUserSessaoSuccess/CLEAR_USER_SESSAO_SUCCESS",
    CLEAR_USER_SESSAO_FAILURE = "@clearUserSessaoFailure/CLEAR_USER_SESSAO_FAILURE",

    CLEAR_CART_SESSAO = "@clarCartSessao/CLEAR_CART_SESSAO",
    CLEAR_CART_SESSAO_SUCCESS = "@clarCartSessaoSuccess/CLEAR_CART_SESSAO_SUCCESS",
    CLEAR_CART_SESSAO_FAILURE = "@clarCartSessaoSuccessFailure/CLEAR_CART_SESSAO_FAILURE",

    SESSAO_STARTED = "@sessaoStarted/SESSAO_STARTED",
    SET_SESSAO_START = "@setSessaoStart/SET_SESSAO_START",
    SET_NOT_SESSAO_STARTED = "@setNotSessaoStarted/SET_NOT_SESSAO_STARTED",

    SET_INSTALL_APP_SESSAO = "@setInstallAppSessao/SET_INSTALL_APP_SESSAO",
    SET_UNINSTALL_APP_SESSAO = "@setUninstallAppSessao/SET_UNINSTALL_APP_SESSAO",

    CREATE_LGPD = "@createLgpd/CREATE_LGPD",
    CREATE_LGPD_SUCCESS = "@createLgpdSuccess/CREATE_LGPD_SUCCESS",
    CREATE_LGPD_FAILURE = "@createLgpdFailure/CREATE_LGPD_FAILURE",

}

export interface SessaoResult extends GlobalResult {
    row:Sessao
}

export interface SessaoState extends GlobalStates<Sessao> {
    readonly row:Sessao
    sessaoStarted:boolean
}

export interface SessaoDispatch {
    createSessao():void;
    updateSessao(row:Sessao):void;
    getSessao():void;
    delSessao():void;
    setUserSessao(user:Usuario):void;
    clearUserSessao():void;
    clearCartSessao():void;
    checkSessaoStarted():void;
    setInstallAppSessao():void;
    setUninstallAppSessao():void;
    createRegistroLGPD():void;
}

export interface SessaoProps {
    sessaoState:SessaoState;
    sessaoActions:SessaoDispatch;
}
