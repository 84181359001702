import { action } from "typesafe-actions";
import { showDataItemCart, ItemCartActionsType } from "../../../provider/itemCart/types";
import { Item } from "../../../provider/item/types";


export const createItemCart = (item:Item) => action(ItemCartActionsType.CREATE_ITEMCART, { item })
export const createItemCartSuccess = (row:showDataItemCart) => action(ItemCartActionsType.CREATE_ITEMCART_SUCCESS, { row });
export const createItemCartFailure = (msg:string) => action(ItemCartActionsType.CREATE_ITEMCART_FAILURE, { msg });

export const addItemCart = (row:showDataItemCart) => action(ItemCartActionsType.ADD_ITEMCART_ROWS, { row });
export const addItemCartSuccess = (rows:showDataItemCart[]) => action(ItemCartActionsType.ADD_ITEMCART_ROWS_SUCCESS, { rows });
export const addItemCartFailure = (msg:string) => action(ItemCartActionsType.ADD_ITEMCART_ROWS_FAILURE, { msg });

export const addMultItemCart = (rows:showDataItemCart[]) => action(ItemCartActionsType.ADD_MULT_ITEMCART_ROWS, { rows });
export const addMultItemCartSuccess = (rows:showDataItemCart[]) => action(ItemCartActionsType.ADD_MULT_ITEMCART_ROWS_SUCCESS, { rows });
export const addMultItemCartFailure = (msg:string) => action(ItemCartActionsType.ADD_MULT_ITEMCART_ROWS_FAILURE, { msg});

export const readAllItensCart = () => action(ItemCartActionsType.READ_ALL_ITENSCART);
export const readAllItensCartSuccess = (rows:showDataItemCart[]) => action(ItemCartActionsType.READ_ALL_ITENSCART_SUCCESS, { rows });
export const readAllItensCartFailure = (msg:string) => action(ItemCartActionsType.READ_ALL_ITENSCART_FAILURE, { msg })

export const remItemCart = (index:number) => action(ItemCartActionsType.REM_ITEMCART_ROWS, { index })
export const remItemCartSuccess = (rows:showDataItemCart[]) => action(ItemCartActionsType.REM_ITEMCART_ROWS_SUCCESS, { rows })
export const remItemCartFailure = (msg:string) => action(ItemCartActionsType.REM_ITEMCART_ROWS_FAILURE,{ msg })

export const setItemUpdate = ( itemCart:showDataItemCart ) => action(ItemCartActionsType.SET_ITEMCART_UPDATE, { itemCart });
/* export const setItemUpdateSuccess = ( row:showDataItemCart ) => action(ItemCartActionsType.SET_ITEMCART_UPDATE_SUCCESS, { row });
export const setItemUpdateFailure = ( msg:string ) => action(ItemCartActionsType.SET_ITEMCART_UPDATE_FAILURE, { msg });
 */
export const updateCart = (itemCart:showDataItemCart) => action(ItemCartActionsType.UPDATE_CART,{itemCart});
export const updateCartSuccess = (rows:showDataItemCart[]) => action(ItemCartActionsType.UPDATE_CART_SUCCESS, {rows});
export const updateCartFailure = (msg:string) => action(ItemCartActionsType.UPDATE_CART_FAILURE, {msg});

export const confirmarCart = (itensCart:showDataItemCart[])=>action(ItemCartActionsType.CONFIRMA_CART, {itensCart});
export const confirmarCartSuccess = ()=>action(ItemCartActionsType.CONFIRMA_CART_SUCCESS);
export const confirmarCartFailure = (msg:string)=>action(ItemCartActionsType.CONFIRMA_CART_FAILURE, {msg});
export const confirmaCartCancel = () => action(ItemCartActionsType.CONFIRMA_CART_CANCEL)

export const clearError = () => action(ItemCartActionsType.CLEAR_ERROR);