import React from "react";
import { Dialog, Transitions, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface FullModalInterface {
    open:boolean;
    handleClose(parm?:any):void;
    component:JSX.Element;
}

export default function FullModal(props:FullModalInterface) {
    const { open, handleClose, component } = props;

    return (
        <Dialog disableEnforceFocus disableAutoFocus fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            {component}
        </Dialog>
    )
}
