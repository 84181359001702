import { call, put } from "redux-saga/effects";
import * as ActionsItemCart from "./actions";
import * as SessaoActions from "../start/actions";
import itemCartProvider from "../../../provider/itemCart";
import { ItemCartResult, showDataItemCart } from "../../../provider/itemCart/types";
import { SessaoResult } from "../../../provider/start/types";
import { getItensEstoque } from "../../../store/data/catalogo/async"
import { ItemResult } from "../../../provider/item/types";
import { setFechamentoPedido } from "../../data/pedido/actions";



export function* createItemCart(actions:any){
    try {
        let resp:ItemCartResult = yield call(itemCartProvider.createItemCart, actions.payload.item);
        if(resp.status === "ok") {
            yield put(ActionsItemCart.createItemCartSuccess(resp.row as showDataItemCart))
        } else {
            yield put(ActionsItemCart.createItemCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.createItemCartFailure("Falha geral no async actions createItemCart. Detalhes: "+JSON.stringify(error)));
    }
}

export function* addItemCartRow(actions:any){
    try {
        let resp:SessaoResult = yield call(itemCartProvider.addItemCart, actions.payload.row);
        if (resp.status === "ok") {
            yield put(SessaoActions.updateSessao(resp.row))
            yield put(ActionsItemCart.addItemCartSuccess(resp.row.cart));
        } else {
            yield put(ActionsItemCart.addItemCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.addItemCartFailure("Falha geral no async actions addItemCartRow. Detalhes: "+JSON.stringify(error)));
    }
}

export function* addMultItemCart(actions:any){
    try {
        let resp:SessaoResult = yield call(itemCartProvider.addMultItemCart, actions.payload.rows);
        if (resp.status === "ok") {
            console.log("addMultItemCart ok", resp.row);
            yield put(SessaoActions.updateSessao(resp.row))
            yield put(ActionsItemCart.addMultItemCartSuccess(actions.payload.rows));
        } else {
            yield put(ActionsItemCart.addMultItemCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.addMultItemCartFailure("Falha geral no async actions addMultItemCart. Detalhes: "+JSON.stringify(error)));
    }
}


export function* readAllItensCart(){
    try {
        let resp:ItemCartResult = yield call(itemCartProvider.readAllItensCart);
        if(resp.status === "ok"){
            yield put(ActionsItemCart.readAllItensCartSuccess(resp.rows as showDataItemCart[]));
        } else {
            yield put(ActionsItemCart.readAllItensCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.readAllItensCartFailure("Falha geral no async actions readAllItensCart. Detalhes: "+JSON.stringify(error))); 
    }
}

export function* remItemCart(action){
    try {
        let resp:SessaoResult = yield call(itemCartProvider.remItemCart, action.payload.index);
        if(resp.status === "ok"){
            yield put(SessaoActions.updateSessao(resp.row));
            yield put(ActionsItemCart.remItemCartSuccess(resp.row.cart as showDataItemCart[]));
        } else {
            yield put(ActionsItemCart.remItemCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.remItemCartFailure("Falha geral no async actions remItemCart. Detalhes: "+JSON.stringify(error)));
    }
}

export function* updateCart(action){
    try {
        let resp:SessaoResult = yield call(itemCartProvider.updateCart, action.payload.itemCart);
        if (resp.status === "ok") {
            yield put(SessaoActions.updateSessao(resp.row));
            yield put(ActionsItemCart.updateCartSuccess(resp.row.cart));
        } else {
            yield put(ActionsItemCart.updateCartFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(ActionsItemCart.updateCartFailure("Falha geral no async actions updateCart. Detalhes: "+JSON.stringify(error)));
    }
}

export function* confirmarCart(action){
    /* coferir se todos os itens tem em estoque */
    try {
        console.log("chamou confirmarCart",action)
        let descItensIndisponivel:string[] = [];
        let itensCart:showDataItemCart[] = action.payload.itensCart;
        //recarregar os itens do catalogo
        console.log("atribuio aos itens",itensCart)
        let respEstoque:ItemResult = yield call(getItensEstoque,true);
        console.log("resposta do getItensEstoque",respEstoque)
        if(respEstoque.status === "ok"){
            //fazer a busca de cada item do carrinho
            console.log("respEstoque",respEstoque)
            itensCart.map((itemCart, i)=> {
                let findItemEstoque = respEstoque.rows?.find(itemEstoque=>itemEstoque._id === itemCart.itemData._id);
                if(findItemEstoque != undefined){
                    //fazer a verificacao do estoque
                    let estoqueDisponivel = findItemEstoque.qt_estoque >= itemCart.itemCartData.qt
                    if(!estoqueDisponivel)
                        descItensIndisponivel.push(itemCart.itemData.descricao);
                } else {
                    //não encontrou, deve estar inativo ou nao faz mais parte do estoque
                    descItensIndisponivel.push(itemCart.itemData.descricao);
                }       
            })
            console.log("descItensIndisponivel",descItensIndisponivel)
            if(descItensIndisponivel.length === 0){
                yield put(ActionsItemCart.confirmarCartSuccess())
            } else {
                let msg = "";
                if(descItensIndisponivel.length>1){
                    msg = "Os itens a seguir : "+descItensIndisponivel.toString()+" não estão mais com a quantidade de estoque que você está solicitando. Para poder continuar altere a quantidade desses itens."
                } else {
                    msg = "O item a seguir : "+descItensIndisponivel.toString()+" não está mais com a quantidade de estoque que você está solicitando. Para poder continuar altere a quantidade do item.";
                }
                yield put(ActionsItemCart.confirmarCartFailure(msg))
            }
        } else {
            yield put(ActionsItemCart.confirmarCartFailure(respEstoque.mensagem as string))
        }
    } catch (error) {
        console.log("error estrutura",error)
        yield put(ActionsItemCart.confirmarCartFailure("Ocorreu uma falha na estrutura da função confirmarCart, detalhes: "+JSON.stringify(error)))
    }
}