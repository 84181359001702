import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import { PedidoItensProps, ShowDataPedidoItens } from "../../provider/pedidoItens/types";
import { PedidoData } from "../../provider/pedido/types"
import * as PedidoItensActions from "../../store/data/pedidoItens/actions";

import { AppBar, Toolbar, Typography, IconButton, makeStyles, createStyles, LinearProgress, List, Divider, Switch, Button } from "@material-ui/core";
import { MdClose, MdList, MdArrowBack, MdSelectAll, MdAddShoppingCart } from "react-icons/md";
import ItemList from "../Item/itemList";
import { showDataItemCart } from "../../provider/itemCart/types";


const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
        height: '100%',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    buttom:{
        position:'absolute',
        bottom:5,
        display:"flex",
        alignSelf:'center',
    }
}))

interface Props extends PedidoItensProps  {
    handleClose(): void;
    pedido: PedidoData;
}

function PedidoItens(props: Props) {
    const { handleClose, pedido, pedidoItensState, pedidoItensActions } = props;
    const { rows, loading, error, total_rows, rowsSel, sendToCart } = pedidoItensState;
    const [showSelectItem, setShowSelectItem] = useState(false);
    const [itensSelected, setItensSelected] = useState<ShowDataPedidoItens[]>([])
    const classes = useStyles();

    //console.log("pedido itens state",props);

    useEffect(() => {
        pedidoItensActions.readPedidoItens(pedido._id);
    }, [total_rows])

    useEffect(()=>{
        if (sendToCart) {
            handleClose()
        }
    },[sendToCart]);

    const selectItem = (id: string) => {
        pedidoItensActions.selectPedidoItem(id);
    }

    const selectAllItens = (selected: boolean) => {
        pedidoItensActions.selectAllItens(selected);
        setShowSelectItem(selected)
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={handleClose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Itens do Pedido {pedido.codigo}
                        </Typography>
                        <Switch
                            edge="end"
                            color="default"
                            value={showSelectItem}
                            onChange={(event => selectAllItens(event.target.checked))}
                            checked={showSelectItem}
                        />
                    </Toolbar>
                </AppBar>
                {loading && (
                    <LinearProgress
                        style={{ margin: 3, minWidth: "max-content" }}
                        color="primary"
                    ></LinearProgress>
                )}
                {!loading && (
                    <>
                    <List style={{ maxHeight: "max-content", overflowY: "scroll" }}>
                        {rows.map((item, idx) => (
                            <div key={idx}>
                                <ItemList
                                    handleClickItem={() => selectItem(item.itemCartData._id)}
                                    item={item}
                                    removeItem={false}
                                    showCheckbox={true}
                                />
                                <Divider />
                            </div>
                        ))}
                    </List>
                    {rowsSel.length > 0 && (
                        <Button
                            variant={
                                "contained"
                            }
                            startIcon
                            className={classes.buttom}
                            color={"secondary"}
                            fullWidth={true}
                            onClick={() => pedidoItensActions.repeatItensPedido(rowsSel) }

                        >
                            <MdAddShoppingCart
                                size={24}
                            />
                        Colocar no carrinho
                        </Button>
                    )}
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    pedidoItensState: state.pedidoItens
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    pedidoItensActions: bindActionCreators(PedidoItensActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PedidoItens);