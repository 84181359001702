/**
 * Aqui fica as actions do saga-effects ações assincronas
 * importar o call e put do redux-saga/effects
 * criar as funções com o mesmo nome do dispatch types
 * 
 */
import { call, put } from "redux-saga/effects";
import * as UserActions from "./actions";
import * as SessaoActions from "../start/actions";
import { UsuarioResult, Usuario} from "../../../provider/usuario/types";
import usuarioProvider from "../../../provider/usuario";

export function* login(actions:any){
    try {
        //console.log("chamou async login",actions)
        let resp:UsuarioResult = yield call(usuarioProvider.login, actions.payload.prmLogin);
        if(resp.status==="ok"){
            //yield put(SessaoActions.setUserSessao(resp.row as Usuario))
            yield put(UserActions.loginFound(resp.row as Usuario))
        } else {
            yield put(UserActions.loginNotFound(resp.mensagem as string));
        }
    } catch (error) {
        yield put(UserActions.loginNotFound("Falha geral no async login do usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* loginValidate(actions:any){
    try {
        let validate:boolean = yield call(usuarioProvider.loginValidate,actions.payload.pwLogin,actions.payload.row);
        if(validate){
            yield put(SessaoActions.setUserSessao(actions.payload.row as Usuario));
            yield put(UserActions.loginSuccess(actions.payload.row as Usuario));
        } else {
            yield put(UserActions.loginFailure("Senha inválida!"));
        }
    } catch (error) {
        yield put(UserActions.loginFailure("Falha geral no async validarLogin do usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* readUser(actions:any){
    try {
        let resp:UsuarioResult = yield call(usuarioProvider.readUser);
        if(resp.status==="ok"){
            yield put(UserActions.readUserSuccess(resp.row as Usuario))
        } else {
            yield put(UserActions.readUserFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(UserActions.readUserFailure("Falha geral no async readUser do usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* getUser(actions:any){
    try {
        let resp:UsuarioResult = yield call(usuarioProvider.getUser, actions.payload.id);
        if(resp.status==="ok"){
            yield put(UserActions.getUserSuccess(resp.row as Usuario))
        } else {
            yield put(UserActions.getUserFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(UserActions.getUserFailure("Falha geral no async getUser do usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* createUser(actions:any){
    try {
        let resp:UsuarioResult = yield call(usuarioProvider.createUser);
        if(resp.status==="ok"){
            yield put(UserActions.createUserSuccess(resp.row as Usuario))
        } else {
            yield put(UserActions.createuserFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(UserActions.createuserFailure("Falha geral no async createUser do usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* salvar(actions:any){
    try {
        
        let resp:UsuarioResult;
        if(actions.payload.row._rev){
            resp = yield call(usuarioProvider.salvar, actions.payload.row);
        } else {
            resp = yield call(usuarioProvider.incluirNovoUsuario, actions.payload.row);
        }
         
        if(resp.status === "ok"){
            yield put(UserActions.salvaSuccess(resp.row as Usuario));
            yield put(SessaoActions.setUserSessao(resp.row as Usuario))
        } else {
            yield put(UserActions.salvarFailure(resp.mensagem as string));
        }
    } catch (error) {
        yield put(UserActions.salvarFailure("Falha geral no async salvar usuario. Detalhes: "+JSON.stringify(error)));
    }
}

export function* sendEmailRecSenha(actions:any){
    try {
        console.log("sendEmailRecSenha")
        //verificar se o email esta cadastrado 
        let respUser:UsuarioResult = yield call(usuarioProvider.login, actions.payload.email);
        if(respUser.status === "ok"){
            console.log("Achou o usuario",respUser.row);
            let user:Usuario = respUser.row as Usuario;
            user.resetsenhadt = new Date().toISOString();
            let respSaveUser:UsuarioResult = yield call(usuarioProvider.salvar, user);
            if(respSaveUser.status === "ok"){
                let link = "https://app.akisuafeira.com.br/confirmarResetSenha/"+respUser.row?._id;
                let email=actions.payload.email;
                let assunto = "Recuperação de senha";
                let msg = `
                    <h2>Assistente para reset de senha.</h2>
                    <p>Esse email foi enviado pelo aplicativo akisuafeira para recuperação da senha de acecsso</p>
                    <p>Para redefinir a sua senha, basta clicar no link abaixo.</p>
                    <p><b>Observaçãop</b> Ao clicar no link, o sistema irá limpar a sua senha permitindo que tenha acesso a sua conta somente com o email ou com o numero de celular cadastrado.</p>
                    <p><a href="${link}">Click aqui para reset de senha</a></p>
                `;
                let opt = {
                    email:email,
                    assunto:assunto,
                    msg:msg
                }
                let resp = yield call(usuarioProvider.sendEmail,opt);
                if(resp.status === "ok"){
                    console.log("enviou o email",resp);
                    yield put(UserActions.sendEmailRecSenhaSuccess())
                } else {
                    console.log("nao enviou o email",resp);
                    yield put(UserActions.sendEmailRecSenhaFailure(resp.data.msg))
                }  
            } else {
                yield put(UserActions.sendEmailRecSenhaFailure(JSON.stringify(respSaveUser.mensagem)))
            }
       } else {
            console.log("Achou o usuario",respUser.row);
            yield put(UserActions.sendEmailRecSenhaFailure("O email "+actions.payload.email+" não foi encontrado nos cadastros. Verifique se digitou corretamente"))
        }
    } catch (error) {
        console.log("falha geral",error);
        yield put(UserActions.sendEmailRecSenhaFailure("Falha geral na função sendEmailRecSenha em async. Detalhes:"+JSON.stringify(error)))
    }
}
