import { GlobalStates, GlobalResult } from "../../services/globalTypes";
import { Item } from "../item/types";

export interface itemCart {
    readonly _id:any;
    readonly _rev:any;
    tipo:'item-mov';
    mov_id:string;
    data:string;
    item_id:string;
    qt:number;
    qt_eq:number;//quantidade equivalente ao qt se for kg
    custo:number;
    preco:number;
    subtotal:number;
    obs:string;
    insumos:Array<any>;
    direcao_estoque:any;    
}

export interface showDataItemCart {
    itemCartData:itemCart;
    itemData:Item;
    selected?:boolean;
}

export interface ItemCartResult extends GlobalResult {
    rows?:showDataItemCart[],
    row?:showDataItemCart
}

/* Types Actions */

export enum ItemCartActionsType {
    CREATE_ITEMCART = "@createItemCart/SET_ROW_ITEMCART",
    CREATE_ITEMCART_SUCCESS = "@createItemCartSuccess/SET_ROW_ITEMCART_SUCCESS",
    CREATE_ITEMCART_FAILURE = "@createItemCartFailure/SET_ROW_ITEMCART_FAILURE",

    READ_ALL_ITENSCART = "@readAllItensCart/READ_ALL_ITENSCART",
    READ_ALL_ITENSCART_SUCCESS = "@readAllItensCartSuccess/READ_ALL_ITENSCART_SUCCESS",
    READ_ALL_ITENSCART_FAILURE = "@readAllItensCartFailure/READ_ALL_ITENSCART_FAILURE",

    READ_ITEMCART = "@readItemCart/READ_ITEMCART",
    READ_ITEMCART_SUCCESS = "@readItemCartSuccess/READ_ITEMCART_SUCCESS",
    READ_ITEMCART_FAILURE = "@readItemCartFailure/READ_ITEMCART_FAILURE",

    ADD_ITEMCART_ROWS = "@addItemCartRows/ADD_ITEMCART_ROWS",
    ADD_ITEMCART_ROWS_SUCCESS = "@addItemCartRowsSuccess/ADD_ITEMCART_ROWS_SUCCESS",
    ADD_ITEMCART_ROWS_FAILURE = "@addItemCartRowsFailure/ADD_ITEMCART_ROWS_FAILURE",

    ADD_MULT_ITEMCART_ROWS = "addMultItemCartRows/ADD_MULT_ITEMCART_ROWS",
    ADD_MULT_ITEMCART_ROWS_SUCCESS = "addMultItemCartRowsSuccess/ADD_MULT_ITEMCART_ROWS_SUCCESS",
    ADD_MULT_ITEMCART_ROWS_FAILURE = "addMultItemCartRowsFailure/ADD_MULT_ITEMCART_ROWS_FAILURE",

    REM_ITEMCART_ROWS = "@remItemCartRows/REM_ITEMCART_ROWS",
    REM_ITEMCART_ROWS_SUCCESS = "@remItemCartRowsSuccess/REM_ITEMCART_ROWS_SUCCESS",
    REM_ITEMCART_ROWS_FAILURE = "@remItemCartRowsFailure/REM_ITEMCART_ROWS_FAILURE",

    SET_ITEMCART_UPDATE = "@setItemCartUpdate/SET_ITEMCART_UPDATE",
    SET_ITEMCART_UPDATE_SUCCESS = "@setItemCartUpdateSuccess/SET_ITEMCART_UPDATE_SUCCESS",
    SET_ITEMCART_UPDATE_FAILURE = "@setItemCartUpdateFailure/SET_ITEMCART_UPDATE_FAILURE",

    UPDATE_CART = "@updateCart/UPDATE_CART",
    UPDATE_CART_SUCCESS = "@updateCartSuccess/UPDATE_CART_SUCCESS",
    UPDATE_CART_FAILURE = "@updateCartFailure/UPDATE_CART_FAILURE",

    CONFIRMA_CART = "@confirmarCart/CONFIRMA_CART",
    CONFIRMA_CART_SUCCESS = "@confirmarCart/CONFIRMA_CART_SUCCESS",
    CONFIRMA_CART_FAILURE = "@confirmarCart/CONFIRMA_CART_FAILURE",
    CONFIRMA_CART_CANCEL = "@confirmarCart/CONFIRMA_CART_CANCEL",

    CLEAR_ERROR = "@itemCart/CLEAR_ERROR",
}

export interface ItemCartState extends GlobalStates<showDataItemCart> {
    readonly row:showDataItemCart,
    readonly rows:showDataItemCart[],
    readonly total:number,
    readonly cartConfirm:boolean
}


/**
 * View Types
 */


export interface ItemCartDispatch {
    createItemCart(item:Item):void
    addItemCart(itemCart:showDataItemCart):void
    addMultItemCart(itensCart:showDataItemCart[]):void
    readAllItensCart():void
    remItemCart(index:number):void
    setItemUpdate(itemCart:showDataItemCart):void
    updateCart(itemCart:showDataItemCart):void
    confirmarCart(itensCart:showDataItemCart[]):void
    confirmaCartCancel():void
    clearError():void;
    /* readItemCart(id:string):void
    */
} 

interface ItemCartProps {
    itemCartState: ItemCartState
    itemCartActions:ItemCartDispatch
}

export type Props = ItemCartProps 