
import { GlobalStates, GlobalResult,GlobalDispatch, GlobalDoc } from "../../../services/globalTypes";

export interface EntregaPedido extends GlobalDoc {
    tipo:"entrega",
    descricao:string;
    detalhes:string;
    ativo:boolean;
    enabled:boolean;
    custo:number;//valor da entrega
    personalizado:boolean;
    data_hora_entrega:DiaHoraEntrega[];
    valor_min:number;
}

export interface DiaHoraEntrega {
    day:number;//dia da semana do objeto Date();0 a 6 sendo que o 0 é domingo
    dia:string;//dia é a descricao do dia tipo Segunda-feira
    hora:string;
}

export interface TipoEntregaResult extends GlobalResult {
    rows?:EntregaPedido[];
    row?:EntregaPedido;
}

export enum TipoEntregaActionsType {

    //READ
    READ_ALL = "@tipoEntrega/READ_ALL",
    READ_ALL_SUCCESS = "@tipoEntrega/READ_ALL_SUCCESS",
    READ_ALL_FAILURE = "@tipoEntrega/READ_ALL_FAILURE",


    FILTRAR = "@tipoEntrega/FILTRAR",
    FILTRAR_SUCCESS = "@tipoEntrega/FILTRAR_SUCCESS",

    SET_ROW = "@tipoEntrega/SET_ROW",

    SET_SHOW_FORM = "@tipoEntrega/SET_SHOW_FORM",

    CLEAR_ERROR = "@tipoEntrega/CLEAR_ERROR",
}

export interface TipoEntregaResult extends GlobalResult {}
export interface TipoEntregaState extends GlobalStates<EntregaPedido> {}
export interface TipoEntregaActions extends GlobalDispatch<EntregaPedido>{}