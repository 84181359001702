import { Reducer } from "redux";
import { ItemCartActionsType, ItemCartState, showDataItemCart } from "../../../provider/itemCart/types";
import ItemCartProvider from "../../../provider/itemCart";
import { Item } from "../../../provider/item/types";
import { trackSingleCustom } from "react-facebook-pixel";

const INITIAL_STATE:ItemCartState = {
    row:{itemData:{} as Item, itemCartData:ItemCartProvider.data},
    rows:[],
    loading:false,
    error:false,
    msg:"",
    showForm:false,
    total_rows:0,
    total:0,
    cartConfirm:false,
};

const somaTotal = (rows:showDataItemCart[]) => {
    let total: number = 0;
    if (rows.length > 0) {
        total = rows
            .map((item) =>
                item.itemCartData.subtotal
            )
            .reduce((acc, cur) => acc + cur);
    }
    return total;
};

const reducer:Reducer<ItemCartState> = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        
        case ItemCartActionsType.CREATE_ITEMCART:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.CREATE_ITEMCART_SUCCESS:
            return {...state, loading:false, error:false, row:action.payload.row};
        case ItemCartActionsType.CREATE_ITEMCART_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};
        
        case ItemCartActionsType.ADD_ITEMCART_ROWS:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.ADD_ITEMCART_ROWS_SUCCESS:
            return {...state, loading:false, error:false, rows:action.payload.rows, total_rows:action.payload.rows.length, total:somaTotal(action.payload.rows)};
        case ItemCartActionsType.ADD_ITEMCART_ROWS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case ItemCartActionsType.ADD_MULT_ITEMCART_ROWS:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.ADD_MULT_ITEMCART_ROWS_SUCCESS:
            return {...state, loading:false, error:false, rows: state.rows.concat(action.payload.rows), total_rows:state.total_rows + action.payload.rows.length, total:somaTotal(state.rows)+somaTotal(action.payload.rows) };
        case ItemCartActionsType.ADD_MULT_ITEMCART_ROWS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};            
    
        case ItemCartActionsType.READ_ALL_ITENSCART:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.READ_ALL_ITENSCART_SUCCESS:
            return {...state, loading:false, error:false, rows:action.payload.rows, total_rows:action.payload.rows.length, total:somaTotal(action.payload.rows)};
        case ItemCartActionsType.READ_ALL_ITENSCART_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}

        case ItemCartActionsType.REM_ITEMCART_ROWS:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.REM_ITEMCART_ROWS_SUCCESS:
            return {...state, loading:false, error:false, rows:action.payload.rows, total_rows:action.payload.rows.length, total:somaTotal(action.payload.rows)};
        case ItemCartActionsType.REM_ITEMCART_ROWS_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg}
        
        case ItemCartActionsType.SET_ITEMCART_UPDATE:
            return {...state, row:action.payload.itemCart};

        case ItemCartActionsType.UPDATE_CART:
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.UPDATE_CART_SUCCESS:
            return {...state, loading:false, rows:action.payload.rows, total:somaTotal(action.payload.rows)};
        case ItemCartActionsType.UPDATE_CART_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg};

        case ItemCartActionsType.CONFIRMA_CART:
            console.log("CONFIRMA_CART")
            return {...state, loading:true, error:false, msg:""};
        case ItemCartActionsType.CONFIRMA_CART_SUCCESS:
            console.log("CONFIRMA_CART_SUCCESS")
            return {...state, loading:false, error:false, cartConfirm:true};
        case ItemCartActionsType.CONFIRMA_CART_FAILURE:
            console.log("CONFIRMA_CART_FAILURE",action)
            return {...state, loading:false, error:true, msg:action.payload.msg}
        case ItemCartActionsType.CONFIRMA_CART_CANCEL:
            return {...state, cartConfirm:false}

        case ItemCartActionsType.CLEAR_ERROR:
            return {...state, error:false, msg:""}

        default:
            return state
    }
}

export default reducer;