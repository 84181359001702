import React, { useState, useEffect } from "react";
import {
    makeStyles,
    createStyles,
    AppBar,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    ListItemSecondaryAction,
    ListItemIcon,
    IconButton,
    Toolbar,
    Container,
    Button,
} from "@material-ui/core";
import { IoMdTrash, IoMdArrowForward } from "react-icons/io";
import { MdArrowBack, MdEdit, MdMonetizationOn, MdSend } from "react-icons/md";

import {useHistory} from "react-router-dom";
import { existePropriedade, formatMoney } from "../../utilsJs"
import Alerta from "../../components/alerts/Alerts";

import { PedidoProps, ShowDataPedido, PedidoDispatch, PedidoState } from "../../provider/pedido/types";
import { ViewSelecioarTipoEntregaModal } from "../../modulos/tipoEntregas/view/selecionar"
import { DiaHoraEntrega, EntregaPedido, TipoEntregaActions, TipoEntregaState } from "../../modulos/tipoEntregas/provider/types";

import {ViewObsPedidoModal} from "./obspedido"


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            height: "100%",
        },
        content: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
        },
        title: {
            flexGrow: 1,
        },        
        containerListTex:{
            display:"flex",
            flexDirection:"column",
            flex:1,
        },
        containerListTextEnd:{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            flex:1,
        },
        containerListEndereco:{
            display:"flex",
            alignItems:"baseline",
            justifyContent:"space-between",
            marginRight:5,
            overflow:"hidden",
        },
        containerContent:{
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"space-between",
            marginBottom:16,
        },
    })
);

interface PropsViewEntrega {
    pedidoState:PedidoState
    pedidoActions:PedidoDispatch
    tipoentregaState:TipoEntregaState,
    tipoentregaActions:TipoEntregaActions  
    handleClose:()=>void;  
    handleShowViewPagamento:()=>void
}

export default function ViewEntrega(props:PropsViewEntrega){
    const { pedidoActions,pedidoState,tipoentregaActions,tipoentregaState,handleClose,handleShowViewPagamento } = props
    const { row } = pedidoState;
    const { cliente,pedido } = row;
    const {  setTipoEntrega, setObsPedido } = pedidoActions;
    const classes = useStyles();
    const routerHistory = useHistory();
    const [showSelecionarTipoEntrega, setShowSelecionarTipoEntrega] = useState(false);
    const [showViewObsPedido, setShowViewObsPedido] = useState(false);
    const [showBlockEntrega,setShowBlockEntrega] = useState(false);
    const [disableMeioPag,setDisableMeioPag] = useState(false);
    const [totalPedido,setTotalPedido] = useState(0)

    useEffect(()=>{
        handleSelecionarTipoEntrega();
        verificaBlockEntrega();
    },[pedido])

    const handleSelecionarTipoEntrega = () => {
        if(existePropriedade(row,["pedido","entrega","dia"])){
            console.log("chamou ",row)
            if(!row.pedido.entrega.dia){
                setShowSelecionarTipoEntrega(true);
            } 
            setTotalPedido(row.pedido.fechamento.subtotal)
        }        
    }

    const setValueEntrega = (tipoEntrega:EntregaPedido,IdxDia:number,diaHoraPerson?:DiaHoraEntrega) => {

        let entrega:any = {
            descricao:tipoEntrega.descricao,
            detalhes:tipoEntrega.detalhes,
            custo:tipoEntrega.custo,
            dia:tipoEntrega.personalizado?diaHoraPerson?.dia:tipoEntrega.data_hora_entrega[IdxDia].dia,
            hora:tipoEntrega.personalizado?diaHoraPerson?.hora:tipoEntrega.data_hora_entrega[IdxDia].hora,
            valor_min:tipoEntrega.valor_min,
        }
        setTipoEntrega(entrega);
    }

    const verificaBlockEntrega = () => {
        if(existePropriedade(row,["pedido","entrega","dia"])){
            if(row.pedido.entrega.valor_min > row.pedido.fechamento.subtotal){
                setShowBlockEntrega(true);
                setDisableMeioPag(true)
            } else {
                setShowBlockEntrega(false)
                setDisableMeioPag(false)
            }
        }
    }
      
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <ViewSelecioarTipoEntregaModal 
                    handleClose={()=>setShowSelecionarTipoEntrega(false)} 
                    open={showSelecionarTipoEntrega} 
                    tipoEntregaActions={tipoentregaActions}
                    tipoEntregaState={tipoentregaState}
                    setValue={setValueEntrega}
                    totalPedido={totalPedido}
                />
                <ViewObsPedidoModal 
                    handleClose={()=>setShowViewObsPedido(false)}
                    open={showViewObsPedido}
                    rowPedido={row}
                    setValue={(values)=>setObsPedido(values.pedido.obs)}
                />
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={handleClose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Local da Entrega
                        </Typography>
                    </Toolbar>
                </AppBar> 

                {pedido && (
                    <Container className={classes.containerContent}>
                        <Alerta show={showBlockEntrega} menssage={"Para fazer esse tipo de entrega o valor mínimo para o pedido é de "+ formatMoney(row.pedido.entrega.valor_min)} tipoAlerta="error" handleClose={()=>setShowBlockEntrega(false)}/>
                        <List dense={true}>
                            <ListItem
                                button
                            >
                                <ListItemIcon>
                                    <MdEdit size={25}/>
                                </ListItemIcon>

                                <ListItemText
                                    onClick={()=>setShowSelecionarTipoEntrega(true)}
                                    primary={
                                        row.pedido.entrega.dia?(
                                            <div className={classes.containerListTex}> 
                                            <Typography
                                                variant={"body1"}
                                                color={"textPrimary"}
                                            >
                                                Dia Entrega: {row.pedido.entrega.dia} a partir das: {row.pedido.entrega.hora}
                                            </Typography>
                                            <Typography
                                                variant={"body2"}
                                                color={"textPrimary"}
                                            >
                                                Tipo: {row.pedido.entrega.descricao }
                                            </Typography>                                
                                        </div>
                                        ):(
                                            <Typography
                                                variant={"body1"}
                                                color={"error"}
                                            >
                                                Informe o tipo de sua entrega!
                                            </Typography>
                                        )
                                    }
                                    secondary={
                                        <Typography
                                            variant={"caption"}
                                            color={"textSecondary"}
                                        >
                                            {row.pedido.entrega.detalhes}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction 
                                    className={classes.containerListTextEnd}
                                    onClick={()=>setShowSelecionarTipoEntrega(true)}
                                >
                                        <Typography
                                            variant={"body1"}
                                            color={"textPrimary"}
                                        >
                                            {row.pedido.entrega.custo>0?formatMoney(row.pedido.entrega.custo):null}
                                        </Typography>

                                        <IoMdArrowForward size={25} />

                                </ListItemSecondaryAction>
                            </ListItem>
                            
                            <ListSubheader >Endereço para entrega:</ListSubheader>
                            <Divider></Divider>

                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <div className={classes.containerListEndereco}>
                                            <Typography variant={"caption"} color={"textSecondary"}>Rua</Typography>
                                            <Typography variant={"body1"} color={"textPrimary"}>{cliente.endereco.rua}</Typography>
                                        </div>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant={"body1"} color={"textPrimary"}>
                                        N° {cliente.endereco.numero}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Divider></Divider>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <div className={classes.containerListEndereco}>
                                            <Typography variant={"caption"} color={"textSecondary"}>Bairro:</Typography>
                                            <Typography variant={"body1"} color={"textPrimary"}>{cliente.endereco.bairro}</Typography>
                                        </div>
                                    }
                                />
                            </ListItem>

                            <Divider></Divider>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <div className={classes.containerListEndereco}>
                                            <Typography variant={"caption"} color={"textSecondary"}>Cidade:</Typography>
                                            <Typography variant={"body1"} color={"textPrimary"}>{cliente.endereco.cidade}</Typography>
                                        </div>
                                    }
                                />
                            </ListItem>

                            <Divider></Divider>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <div className={classes.containerListEndereco}>
                                            <Typography variant={"caption"} color={"textSecondary"}>Ponto de referência:</Typography>
                                            <Typography variant={"body1"} color={"textPrimary"}>{cliente.endereco.complemento}</Typography>
                                        </div>
                                    }
                                />
                            </ListItem>


                            <Divider></Divider>
                            <ListItem button onClick={()=>routerHistory.push(`/catalogo/perfil`)} >
                                <ListItemIcon>
                                    <MdEdit size={25}/>
                                </ListItemIcon>
                                <ListItemText primary="Alterar Endereço" />
                                <ListItemSecondaryAction
                                    onClick={()=>routerHistory.push(`/catalogo/perfil`)}
                                >
                                    <IoMdArrowForward size={25} />
                                </ListItemSecondaryAction>                        
                            </ListItem>
                            <Divider></Divider>

                            <ListItem button onClick={()=>setShowViewObsPedido(true)} >
                                <ListItemIcon>
                                    <MdEdit size={25}/>
                                </ListItemIcon>
                                <ListItemText 
                                    primary={
                                        !pedido.obs?(
                                            <Typography>Colocar observações no pedido.</Typography>
                                        ):(
                                            <div className={classes.containerListEndereco}>
                                                <Typography variant={"caption"} color={"textSecondary"} noWrap>Obs</Typography>
                                                <Typography variant={"body2"} color={"textPrimary"} noWrap style={{maxWidth:200}}>{pedido.obs}</Typography>
                                            </div>
                                        )
                                    }
                                />
                                <ListItemSecondaryAction
                                    onClick={()=>setShowViewObsPedido(true)}
                                >
                                    <IoMdArrowForward size={25} />
                                </ListItemSecondaryAction>                        
                            </ListItem>


                        </List>


                        <Button
                            variant={"contained"}
                            startIcon
                            color={"secondary"}
                            fullWidth={true}
                            onClick={handleShowViewPagamento}
                            disabled={!pedido.entrega.dia||disableMeioPag}
                        >
                            <MdMonetizationOn
                                size={24}
                            />
                                Informar Pagamento
                        </Button>                              
                    </Container>                                                   
                )}
            </div>
        </div>
    )
}

