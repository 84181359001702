import {Reducer} from "redux";
import { MeioPagState, MeioPagActionsTypes } from "../provider/types";
import meioPagProvider from "../provider";

const INITIAL_STATE : MeioPagState = {
    row:meioPagProvider.newDataMeioPag,
    rows:[],
    error:false,
    loading:false,
    msg:"",
    showForm:false,
    total_rows:0,
};

const reducer:Reducer<MeioPagState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //CREATE
        case MeioPagActionsTypes.CREATE_MEIO_PAG:
            return { ...state, loading: true, error: false }
        case MeioPagActionsTypes.CREATE_MEIO_PAG_SUCCESS:
            return { ...state, loading: false, row: action.payload.row }
        case MeioPagActionsTypes.CREATE_MEIO_PAG_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }
        
        //READ
        case MeioPagActionsTypes.READ_ALL_MEIO_PAG:
            return {...state, loading:true, error:false, msg:""}
        case MeioPagActionsTypes.READ_ALL_MEIO_PAG_SUCCESS:
            return {...state, loading:false, rows: action.payload.rows, total_rows: action.payload.rows.length}
        case MeioPagActionsTypes.READ_ALL_MEIO_PAG_FAILURE:
            return {...state, loading:false, error:true, msg:action.payload.msg }
        
        //UPDATE
        case MeioPagActionsTypes.UPDATE_MEIO_PAG:
            return { ...state, loading: true, error: false }
        case MeioPagActionsTypes.UPDATE_MEIO_PAG_SUCCESS:
            return { ...state, loading: false, error: false, row: action.payload.row }
        case MeioPagActionsTypes.UPDATE_MEIO_PAG_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        //DELETE
        case MeioPagActionsTypes.DELETE_MEIO_PAG:
            return { ...state, loading: true, error: false }
        case MeioPagActionsTypes.DELETE_MEIO_PAG_SUCCESS:
            return { ...state, loading: false, error: false }
        case MeioPagActionsTypes.DELETE_MEIO_PAG_FAILURE:
            return { ...state, loading: false, error: true, msg: action.payload.msg }

        case MeioPagActionsTypes.SET_SHOW_FORM:
            return {...state, showForm:!state.showForm };

        case MeioPagActionsTypes.SET_ROW_MEIO_PAG:
            return {...state, showForm:!state.showForm, row:action.payload.row };
        default:
            return state
    }
}
export default reducer;