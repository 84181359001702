import React, {useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import * as UserActions from "../../store/data/user/actions";
import { UsuarioState, UsuarioDispatch } from "../../provider/usuario/types";

import { makeStyles, createStyles, AppBar, IconButton, Typography, Toolbar, Container } from "@material-ui/core";
import { MdArrowBack } from "react-icons/md";
import Formulario from "../Perfil/formulario";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            height: "100%",
        },
        content: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginBottom: 50,
            marginTop: 20,
        },
        title: {
            flexGrow: 1,
        },

    })
);


interface Props{
    usuarioActions:UsuarioDispatch,
    usuarioState:UsuarioState,
    onclose(): void;
}

function Cadastro({ usuarioActions, usuarioState, onclose  }: Props) {
    //console.log(usuarioState);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={onclose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Fazer o seu Cadastro
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                    {usuarioState&&(
                        <Formulario usuarioActions={usuarioActions} usuarioState={usuarioState} />
                    )}
                    
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    usuarioState: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    usuarioActions: bindActionCreators(UserActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cadastro)