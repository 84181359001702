import { Item } from "../../../provider/item/types";
import Provider from "../../../services/provider";
import { ItemPromocao, KitPromocao } from "./types";

const newDataKitPromocao:KitPromocao = {
    _id:undefined,
    _rev:undefined,
    tipo:"kit-promocao",
    desconto:0,
    descricao:"",
    detalhes:"",
    img:"",
    ativo:false,
    itens:[],
}

class KitPromocaoProvider extends Provider<KitPromocao>{

}

export const kitPromocaoProvider = new KitPromocaoProvider(
    "kit-promocao",
    newDataKitPromocao,
    [
        {title:"Data Cadastro",field0:"data",type:"date"},
        {title:"Titular",field0:"titular",field1:"nome",type:"text"},
        {title:"Vencimento",field0:"vencimento",type:"date"},
        {title:"Valor",field0:"valor",type:"currency"},
    ]
)
