import React, {useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "../../store";
import * as ItemCartActions from "../../store/data/itemCart/actions";
import {
    List,
    Theme,
    makeStyles,
    createStyles,
    Typography,
    LinearProgress,
    Divider,
    Grid,
    AppBar,
    Toolbar,
    Button,
    ListItemSecondaryAction,
    CircularProgress,
    Container
    } from "@material-ui/core";
import { MdShoppingCart, MdCheck, MdMonetizationOn, MdDeliveryDining } from "react-icons/md";
import { IoMdCart,IoMdPin,IoMdPerson } from "react-icons/io";
import { ItemCartDispatch, ItemCartState, Props, showDataItemCart } from "../../provider/itemCart/types";
import Modal from "../../components/Modal"
import Confirmar from "../../view/SteppsDelivery";
import ItemList from "../Item/itemList";
import ItemCart, { ActionItem } from "../Item/index";
import Alerta from "../../components/alerts/Alerts";
import Util from "../../services/utils";
import { SessaoState } from "../../provider/start/types";
import { retry } from "redux-saga/effects";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
          display:"flex",
          flexGrow:1,
          height: '100%',
        },
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginBottom:40,
        },
        container:{
            display:"flex",
            flexDirection:"column",
            flex:1,
            overflowY:'scroll',
        },        
        title: {
            flexGrow: 1,
        },
        buttomConfirmar:{
            position:'absolute',
            bottom:56,
            display:"flex",
            alignSelf:'center',
        }         

    })
);

const util = new Util();

interface CartProps {
    itemCartState: ItemCartState
    itemCartActions:ItemCartDispatch      
    sessaoState:SessaoState,  
}
function Cart(props: CartProps) {
    const { itemCartActions,itemCartState,sessaoState } = props;
    const {
        error,
        loading,
        msg,
        row,
        rows,
        showForm,
        total_rows,
        total,
        cartConfirm
    } = props.itemCartState;
    const { remItemCart,confirmarCart,confirmaCartCancel,clearError } = props.itemCartActions;
    const classes = useStyles();
    const [showFormItem, setShowFormItem] =React.useState(false);

    console.log("cart",props.itemCartState)
    const exibirDetalhe = () =>{
        setShowFormItem(!showFormItem)
    }

    const showFormItemView = (itemSelected:showDataItemCart) => {
        const { setItemUpdate } = props.itemCartActions;
        setItemUpdate(itemSelected);
        setShowFormItem(true);
    }    

    const somaTotal = () => {
        let total: number = 0;
        if (rows.length > 0) {
            total = rows
                .map((item) =>
                    !util.isEmpty(item.itemCartData)
                        ? item.itemCartData.subtotal
                        : 0
                )
                .reduce((acc, cur) => acc + cur);
        }
        return total;
    };

    const CaptionButtonConfirmar = () => {
        console.log("sessaoState ",sessaoState)
        if(loading){
            return (
                <>
                    Aguarde...
                    <CircularProgress color="inherit" />
                </>
            )
        } else if(sessaoState.row.user._id){
            return (
                <>
                    Informe o Tipo e Horário da Entrega
                    <MdDeliveryDining size={24}/>
                </>
            )
        } else {
            return (
                <>
                    Identifique-se para enviar
                    <IoMdPerson size={24} />
                </>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Alerta show={error} menssage={msg} tipoAlerta="error" handleClose={clearError} />
            <Modal 
                component={<Confirmar handleClose={confirmaCartCancel} />}
                open={cartConfirm} 
                handleClose={confirmaCartCancel} 
            />
            <div className={classes.content}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <MdShoppingCart size={25} />
                        <Typography variant="h6" className={classes.title}>
                            {" Total " + util.formatMoney(total)}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={classes.container}>                

                {loading && (
                    <LinearProgress
                        style={{ margin: 3, minWidth: "max-content" }}
                        color="primary"
                    ></LinearProgress>
                )}
                {rows.length === 0 && (
                  <div style={{display:"flex", flexGrow:1, flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <IoMdCart size={40} style={{color:"silver"}} />
                    <Typography variant={"h6"} color={"textSecondary"}>
                      Seu carrinho está vazio!
                    </Typography>
                  </div>
                )

                }
                <List style={{flex:1,paddingBottom:60}}>
                    {rows.map((item, idx) => (
                        <div key={idx}>
                            <ItemList 
                                handleClickItem={()=>showFormItemView(item)}
                                item={item}
                                removeItem={true}
                                handleRemoveItem={()=>remItemCart(idx)}
                                showCheckbox={false}
                            />
                            <Divider />
                        </div>
                    ))}
                </List>
                <Button
                    variant={"contained"}
                    endIcon
                    className={classes.buttomConfirmar}
                    color={"secondary"}
                    fullWidth={true}
                    onClick={() => confirmarCart(rows) }
                    disabled={rows.length === 0||loading}
                >
                    <CaptionButtonConfirmar />                   
                </Button>                
                </Container>

            </div>
            <Modal 
                component={ItemCart({
                    row:row,
                    showView:exibirDetalhe, 
                    itemCartActions:props.itemCartActions,
                    actionItem:ActionItem.update
                })}
                handleClose={exibirDetalhe}
                open={showFormItem}
            />              
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    itemCartState: state.itemCart,
    sessaoState:state.start,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    itemCartActions: bindActionCreators(ItemCartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
