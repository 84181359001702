import React from 'react';
import { Provider } from "react-redux";
import { MainRoute } from "./services/routes";
import store from "./store";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3e490f",
    },
  },
})

function App() {
  return (

    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MainRoute />
      </Provider>
    </ThemeProvider> 

  );
}

export default App;
