import React, { useState, useEffect } from "react";

//frameworks
import { connect  } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import ReactPixel from "react-facebook-pixel";

//componentes externos
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    StepIconProps,
    Toolbar,
    AppBar,
    Button,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import {
    IoMdPerson,
    IoMdCard,
    IoMdSend,
    IoMdArrowBack,
    IoMdArrowForward,
} from "react-icons/io";
import {MdMonetizationOn } from "react-icons/md";


//types
import pedidoProvider from "../../provider/pedido"
import * as PedidoActions from "../../store/data/pedido/actions";
import * as SessaoActions from "../../store/data/start/actions";
import * as UsuarioActions from "../../store/data/user/actions";
import * as ActionsTipoEntrega from "../../modulos/tipoEntregas/store/actions"
import { PedidoProps, ShowDataPedido, PedidoDispatch, PedidoState } from "../../provider/pedido/types";
import { SessaoProps } from "../../provider/start/types"
import { Usuario, Props as LoginProps } from "../../provider/usuario/types";
import { TipoEntregaState,TipoEntregaActions } from "../../modulos/tipoEntregas/provider/types"

//componentes interno
import Modal from "../../components/Modal";


//views
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Login from "../Login";
import ViewEntrega from "./entrega";
import "./index.css";

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(170, 201, 36) 0%,rgb(108, 128, 24) 50%,rgb(62, 73, 15) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(170, 201, 36) 0%,rgb(108, 128, 24) 50%,rgb(62, 73, 15) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);
interface GetStepContentProps {
    pedidoState:PedidoState;
    pedidoActions:PedidoDispatch
    tipoentregaState:TipoEntregaState,
    tipoentregaActions:TipoEntregaActions    
    step: number;
    handleCancelStep(): void;
    handleShowViewPagamento():void;
    handleBackView():void;
    handleNextView():void;
}
function getStepContent(props: GetStepContentProps) {
    const { step, handleCancelStep, pedidoState, pedidoActions, tipoentregaActions, tipoentregaState,handleShowViewPagamento,handleBackView,handleNextView } = props;
    switch (step) {
        case 0:
            return <ViewEntrega 
                        pedidoState={pedidoState} 
                        pedidoActions={pedidoActions}
                        tipoentregaActions={tipoentregaActions}
                        tipoentregaState={tipoentregaState}
                        handleClose={handleCancelStep}
                        handleShowViewPagamento={handleShowViewPagamento}
                    />;
        case 1:
            return <Step2 
                        pedido={pedidoState.row} 
                        setValorPedido={pedidoActions.setValorFechamento} 
                        setFechamentoPedido={pedidoActions.setFechamentoPedido}
                        addMeioPag={pedidoActions.addMeioPag}
                        remMeioPag={pedidoActions.remMeioPag}
                        backView={handleBackView}
                        nextView={handleNextView}
                    />;
        case 2:
            return <Step3 pedidoActions={pedidoActions} pedidoState={pedidoState} backView={handleBackView} />;
    }
}
const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage:
            "linear-gradient( 136deg, rgb(170, 201, 36) 0%, rgb(108, 128, 24) 50%, rgb(62, 73, 15) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage:
            "linear-gradient( 136deg, rgb(170, 201, 36) 0%, rgb(108, 128, 24) 50%, rgb(62, 73, 15) 100%)",
    },
});

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <IoMdPerson size={25} />,
        2: <MdMonetizationOn size={25} />,
        3: <IoMdSend size={25} />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

interface LoginModalProps {
    handleClose(): void;
    open: boolean;
    loginProps?: LoginProps;
}

function ShowLoginModal(props: LoginModalProps) {
    const { handleClose, open, loginProps } = props;

    return (
        <Modal
            component={
                <Login handleClose={handleClose} userProps={loginProps} />
            }
            handleClose={handleClose}
            open={open}
        />
    );
}
interface UsuarioProps {
    loginProps:LoginProps;
}
interface TipoEntregaProps{
    tipoentregaState:TipoEntregaState,
    tipoentregaActions:TipoEntregaActions

}
interface Props extends PedidoProps, SessaoProps, LoginProps, TipoEntregaProps {
    handleClose(): void;
}

function SteppsDeliveryView (props:Props) {
    const {handleClose, pedidoActions, pedidoState, sessaoActions, sessaoState,tipoentregaActions,tipoentregaState } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [closeLoginModal, setCloseLoginModal] = useState(false);

    const handleCloseLoginModal = () => {
        setOpenLoginModal(false);
        setCloseLoginModal(true);
        if (!sessaoState.row.user._id) {
            handleClose();  
        }
    };

    useEffect(()=>{
        if (!sessaoState.sessaoStarted) {
            sessaoActions.checkSessaoStarted()
        } else {
            if (sessaoState.row.user._id) {
                console.log("criando pedido")
                pedidoActions.createPedido(sessaoState.row.user,sessaoState.row.cart);    
                ReactPixel.track("InitiateCheckout");
            } else {
                setOpenLoginModal(true);
            }
            
        }
    },[sessaoState.row.user]);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const disabledBtAvancar = ():boolean => {
        const { pedido, itens } = pedidoState.row;
        let result:boolean = false;
        if(activeStep === 2){
            result = true;
        } else {
            if((!pedidoProvider.isEmpty(pedido))&&(activeStep===1)){
                if(pedido.fechamento.meios_pag.length === 0 ) {
                    result = true;
                } else if( pedido.fechamento.meios_pag.map(pay=>pay.valor).reduce((acc,cur)=>acc+cur)< 
                 pedido.fechamento.total){
                     result = true
                 }
            }
        }
        return result;
    }

    return (
            <div className="root">
                <div className="content">
                    <ShowLoginModal
                        handleClose={handleCloseLoginModal}
                        open={openLoginModal}
                        loginProps={props}
                    />
{/*                     <AppBar position="sticky" color="primary">
                        <Toolbar>
                            {activeStep === 0 && (
                                <Button
                                    variant={"contained"}
                                    startIcon
                                    color={"default"}
                                    size={"small"}
                                    onClick={() => handleClose()}
                                >
                                    Carrinho
                                </Button>
                            )}
                            {activeStep > 0 && (
                                <Button
                                    variant={"contained"}
                                    startIcon
                                    color={"default"}
                                    size={"small"}
                                    onClick={() => handleBack()}
                                >
                                    <IoMdArrowBack size={24} />
                                    Voltar
                                </Button>
                            )}
                            <Typography variant={"h6"} className="title">
                                {activeStep === 0 ? 
                                "Local de Entrega" :
                                activeStep === 1 ?
                                "Forma de Pagamento":
                                "Finalizar Pedido"
                                }
                            </Typography>
                            {activeStep < 2 && (
                                <Button
                                variant={"contained"}
                                endIcon
                                color={"default"}
                                size={"small"}
                                disabled={disabledBtAvancar()}
                                onClick={() => handleNext()}
                                >
                                    Avançar
                                    <IoMdArrowForward size={24} />
                                </Button>
                            )}

                        </Toolbar>
                    </AppBar> */}

                    <div className="content">
                        {getStepContent({
                            step: activeStep,
                            handleCancelStep: handleClose,
                            pedidoState:pedidoState,
                            pedidoActions:pedidoActions,
                            tipoentregaActions:tipoentregaActions,
                            tipoentregaState:tipoentregaState,
                            handleShowViewPagamento:handleNext,
                            handleBackView:handleBack,
                            handleNextView:handleNext
                        })}
                    </div>
{/*                     <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        className="steper"
                        connector={<ColorlibConnector />}
                    >
                        <Step>
                            <StepLabel StepIconComponent={ColorlibStepIcon} />
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={ColorlibStepIcon} />
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={ColorlibStepIcon} />
                        </Step>
                    </Stepper> */}
                </div>
            </div>
    );
}

const mapStateToProps = (state:ApplicationState) => ({
    pedidoState:state.pedido,
    sessaoState:state.start,
    usuarioState:state.user,
    tipoentregaState:state.tipoentrega,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
    pedidoActions:bindActionCreators(PedidoActions, dispatch),
    sessaoActions:bindActionCreators(SessaoActions, dispatch),
    usuarioActions:bindActionCreators(UsuarioActions, dispatch),
    tipoentregaActions:bindActionCreators(ActionsTipoEntrega, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SteppsDeliveryView);
