import { Item } from "../../../provider/item/types";
import Provider from "../../../services/provider";
import { Estoque } from "./types";

const newDataEstoque:Estoque = {
    _id:undefined,
    _rev:undefined,
    tipo:"estoque",
    data:"",
    mov_id:"",
    itens_estoque:[{item_id:"",qt:0}]    
}

class EstoqueProvider extends Provider<Estoque>{
    temEstoque = (item:Item) => {
        let estoque = this.rows[0]['value'].itens_estoque;
        let itemFind = estoque.find(itEst=>itEst.item_id === item._id);
        if(itemFind !== undefined){
            if(!isNaN(item.qt_min)){
                return itemFind.qt > item.qt_min
            } else {
                return false
            }
        } else {
            return false
        }
    }
}

export const estoqueProvider = new EstoqueProvider(
    "estoque",
    newDataEstoque,
    [
        {title:"Data Cadastro",field0:"data",type:"date"},
        {title:"Titular",field0:"titular",field1:"nome",type:"text"},
        {title:"Vencimento",field0:"vencimento",type:"date"},
        {title:"Valor",field0:"valor",type:"currency"},
    ]
)
