import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { MdAccountCircle } from "react-icons/md";

import { ApplicationState } from "../../store";
import * as UserActions from "../../store/data/user/actions";
import * as SessaoActions from "../../store/data/start/actions";
import {
    Props,
    UsuarioState,
    UsuarioDispatch,
    Usuario
} from "../../provider/usuario/types";
import { SessaoProps } from "../../provider/start/types";

import Modal from "../../components/Modal";
import Formulario from "./formulario";
import Login from "../Login";
import {
    Typography,
    AppBar,
    Toolbar,
    Container,
    Button,
    makeStyles,
    createStyles,
    IconButton,
} from "@material-ui/core";

import { MdSave, MdExitToApp, MdLock } from "react-icons/md";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            height: "100%",
        },
        content: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginBottom: 50,
        },
        title: {
            flexGrow: 1,
        },

    })
);

type OwnProps = Props & SessaoProps;
interface LoginModalProps {
    handleClose(): void;
    open: boolean;
    loginProps?: Props;
}
function ShowLoginModal(props: LoginModalProps) {
    const { handleClose, open, loginProps } = props;

    return (
        <Modal
            component={
                <Login handleClose={handleClose} userProps={loginProps} />
            }
            handleClose={handleClose}
            open={open}
        />
    );
}

function Perfil(props?: OwnProps) {
    const usuarioState = props?.usuarioState;
    const usuarioActions = props?.usuarioActions;
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [closeLoginModal, setCloseLoginModal] = useState(false);
    const classes = useStyles();
    const handleCloseLoginModal = () => {
        setOpenLoginModal(false);
        setCloseLoginModal(true);
    };
     console.log('props em dado cliente',props);



    useEffect(() => {
        console.log("chamou effects", usuarioState);
        if (!usuarioState?.row._id) {
            setOpenLoginModal(true);
        }
    }, [usuarioState?.row._id]);

    const logOff = () => {
        props?.sessaoActions.clearUserSessao();
    }

    return (
        <div className={classes.root}>
            <ShowLoginModal
                handleClose={handleCloseLoginModal}
                open={openLoginModal}
                loginProps={props}
            />
            <div className={classes.content}>
                <AppBar position="static" color={"primary"}>
                    <Toolbar>
                        <Typography className={classes.title} variant="h6">Dados do Cliente</Typography>
                        <IconButton
                            title={"Sair do Sistema"}
                            onClick={() => logOff()}
                            color="inherit"
                            edge="end"
                        >
                            <MdExitToApp size={25} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ display: "flex", flexGrow: 1, overflow: 'scroll', }}>
                    <Container
                        style={{ display: "flex", flexGrow: 1, marginTop: 10, }}
                    >
                        {!usuarioState?.row._id && (
                            <div
                                style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <MdAccountCircle
                                    size={120}
                                    style={{ color: "silver", marginBottom: 30 }}
                                />
                                <Typography variant="body1">
                                    Usuário não identificado
                            </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenLoginModal(true)}
                                >
                                    Realizar Login
                            </Button>
                            </div>
                        )}
                        {usuarioState?.row._id && (
                            <Formulario usuarioActions={usuarioActions as UsuarioDispatch} usuarioState={usuarioState} />
                        )}
                    </Container>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    usuarioState: state.user,
    sessaoState: state.start
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    usuarioActions: bindActionCreators(UserActions, dispatch),
    sessaoActions: bindActionCreators(SessaoActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Perfil);
