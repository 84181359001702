import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store"

import * as ItemCartActions from "../../store/data/itemCart/actions";
import * as SessaoActions from "../../store/data/start/actions";
import { ItemCartState, ItemCartDispatch } from "../../provider/itemCart/types"
import { SessaoState,SessaoDispatch } from "../../provider/start/types"
import { UsuarioState } from "../../provider/usuario/types"
import {
    BottomNavigation,
    BottomNavigationAction,
    makeStyles,
    withStyles,
    Badge, 
    createStyles,
    Fab,
    Theme
} from "@material-ui/core";
import {
    MdShoppingCart,
    MdAccountCircle,
    MdHistory,
    MdHome,
    MdChat
} from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io"
import {
    useHistory,
    useRouteMatch,
    BrowserRouterProps,
} from "react-router-dom";
import { Routes } from "../../services/routes";
import ReactPixel from "react-facebook-pixel";

var _LOAD_ITEM = false
// import { Container } from './styles';
const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    navigtion: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor:"#f5f5f5"
    },
    fab:{
        position: 'fixed',
        bottom: theme.spacing(10),
        right: theme.spacing(4),
    }
}));

const BadgeTotal = withStyles((theme)=>createStyles({
    badge:{
        right: -15,
        top: 13,
        boder: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}),
)(Badge);

interface MainProps {
    itemCartState:ItemCartState
    itemCartActions:ItemCartDispatch
    sessaoState:SessaoState
    sessaoActions:SessaoDispatch
    usuarioState: UsuarioState
}

function Main({itemCartActions,itemCartState,sessaoState,sessaoActions, usuarioState }:MainProps) {

    //console.log(sessaoState);
    //const { itemCartState,itemCartActions  } = props;
    //const { sessaoState } = sessaoProps;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const routerHistory = useHistory();
    const { url } = useRouteMatch();
    //console.log("chamou Main");
    React.useEffect(() => {
        //loadViewItem();
        //carregar os itens do carrinho
        itemCartActions.readAllItensCart();
        if(!sessaoState.sessaoStarted){
            //sessaoActions.checkSessaoStarted();
            routerHistory.push("/");
        }
        ReactPixel.init('772526869537500',undefined,{debug:false, autoConfig:false});
        ReactPixel.pageView();     
    },[_LOAD_ITEM]);

    const loadViewItem = () => {
        //readAllItensCart();
        routerHistory.push(`${url}/itens`);
        _LOAD_ITEM = true;
    }
    const handleNavigationChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                routerHistory.push(`${url}/itens`);
                break;
            case 1:
                routerHistory.push(`${url}/cart`);
                break;
            case 2:
                routerHistory.push(`${url}/historico`);
                break;
            case 3:
                routerHistory.push(`${url}/perfil`);
                break;
        }
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <Routes />
            {/*<Fab 
                color="primary" 
                size="small"
                title="Pedir pelo WhatsApp"
                aria-haspopup="true"
                href="https://wa.me/557591182584"                    
                className={classes.fab}
            >
                <IoLogoWhatsapp size={25}/>
            </Fab>*/}                
            <BottomNavigation
                value={value}
                onChange={handleNavigationChange}
                className={classes.navigtion}
                
            >
                <BottomNavigationAction
                    label="Catalogo"
                    icon={<MdHome size={25} />}
                />
                <BottomNavigationAction
                    label="Carrinho"
                    icon={
                        <BadgeTotal badgeContent={<div style={{display:'flex', flexDirection:'row'}}> <small style={{fontSize:8, paddingRight:3}}>R$ </small> {itemCartState.total.toFixed(2)} </div>} color="secondary" invisible={itemCartState.total === 0} >
                            <Badge badgeContent={itemCartState.total_rows} color="secondary" anchorOrigin={{vertical:"top",horizontal:"left"}}>
                                {" "}
                                <MdShoppingCart size={25} />{" "}
                            </Badge>
                        </BadgeTotal>
                    }
                />
                {sessaoState.sessaoStarted && sessaoState.row.user._id &&(
                    <BottomNavigationAction
                        label="Hitórico"
                        icon={<MdHistory size={25} />}
                    />
                )}

                <BottomNavigationAction
                    label="Perfil"
                    icon={
                        <Badge color="error" variant="dot" invisible={!sessaoState.row.user || usuarioState.row.senha !== ""}>
                            <MdAccountCircle size={25} />
                        </Badge>
                    }
                />               
            </BottomNavigation>
            
        </div>
    );
}
const mapStateToProps = (state:ApplicationState)=>({
    itemCartState:state.itemCart,
    sessaoState:state.start,
    usuarioState:state.user
})
const mapDispatchToProps = (dispatch:Dispatch)=>({
    itemCartActions:bindActionCreators(ItemCartActions,dispatch),
    sessaoActions:bindActionCreators(SessaoActions,dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(Main);
