import React, { useEffect, useState, useContext } from "react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../../store";

import * as PedidoItensActions from "../../../store/data/pedidoItens/actions";
import { PedidoItensDispatch,PedidoItensState } from "../../../provider/pedidoItens/types";

import { AppBar, Toolbar, Typography, IconButton, makeStyles, createStyles, LinearProgress, List, Divider, Switch, Button, Container, CircularProgress } from "@material-ui/core";
import { MdClose, MdList, MdArrowBack, MdSelectAll, MdAddShoppingCart } from "react-icons/md";
import ItemList from "../../../view/Item/itemList";
import { KitPromocaoState } from "../provider/types";
import { showDataItemCart } from "../../../provider/itemCart/types";
import Modal from "../../../components/Modal";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
        height: '100%',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    container:{
        display:"flex",
        flexDirection:"column",
        flex:1,
        overflowY:'scroll',
    },
    buttom:{
        position:'absolute',
        bottom:5,
        display:"flex",
        alignSelf:'center',
    }    
}))

interface PedidoItensModalProps {
    handleClose(): void;
    open: boolean;
    kitpromocaoState:KitPromocaoState
    pedidoItensActions:PedidoItensDispatch;
    pedidoItensState:PedidoItensState
  }
export function ShowPedidoItensModal(props: PedidoItensModalProps) {
    const { handleClose, open, kitpromocaoState } = props;
  
    return (
      <Modal
        component={
          <ListItensPromocaoView handleClose={handleClose} kitpromocaoState={kitpromocaoState} pedidoItensActions={props.pedidoItensActions} pedidoItensState={props.pedidoItensState} />
        }
        handleClose={handleClose}
        open={open}
      />
    );
  }

interface PropsListItensPromocaoView {
    pedidoItensState:PedidoItensState
    pedidoItensActions:PedidoItensDispatch;
    handleClose(): void;
    kitpromocaoState:KitPromocaoState
}

export default function ListItensPromocaoView(props: PropsListItensPromocaoView) {
    const { handleClose } = props;
    const { row } =props.kitpromocaoState;
    const { sendToCart } = props.pedidoItensState
    const [showSelectItem, setShowSelectItem] = useState(false);
    const [itensCart, setItensCart] = useState<showDataItemCart[]>([])
    const classes = useStyles();

    useEffect(()=>{
        if(itensCart.length === 0){
            montarItensCart();
        }
    },[itensCart])

    useEffect(()=>{
        if (sendToCart) {
            handleClose()
        }
    },[sendToCart]);    

    const montarItensCart = () => {
        console.log("chamou montarItensCart")
        let itensCartTemp:showDataItemCart[]=[];
        let itemCart:showDataItemCart;
        row.itens.map(item=>{
            itemCart = {
                itemData:item.item,
                itemCartData:{
                    item_id:item._id,
                    custo:item.item.custo,
                    preco:item.valor,
                    qt:item.qt,
                    subtotal:Number((item.valor*item.qt).toFixed(2)),
                    tipo:'item-mov',
                    mov_id:"",
                    data:"",
                    direcao_estoque:"S",
                    insumos:[],
                    obs:"",
                    qt_eq:0,
                    _id:undefined,
                    _rev:undefined
                }
            }
            itensCartTemp.push(itemCart)       
        });
        setItensCart(itensCartTemp);
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            onClick={handleClose}
                        >
                            <MdArrowBack />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {row.descricao}
                        </Typography>
                    </Toolbar>
                </AppBar>
                    <Container className={classes.container}>
                        <Alert severity="info">Você poderá alterar a quantidade, remover ou adicionar itens quando estiver no carrinho.</Alert>
                    <List >
                        {itensCart.map((item, idx) => (
                            <div key={idx}>
                                <ItemList
                                    handleClickItem={() => {}}
                                    item={item}
                                    removeItem={false}
                                    showCheckbox={false}
                                />
                                <Divider />
                            </div>
                        ))}
                    </List>

                    <Button
                        variant={
                            "contained"
                        }
                        startIcon
                        className={classes.buttom}
                        color={"secondary"}
                        fullWidth={true}
                        onClick={() => props.pedidoItensActions.repeatItensPedido(itensCart,true) }
                        disabled={props.pedidoItensState.loading}
                    >
                        {props.pedidoItensState.loading?(
                            <>
                            <CircularProgress color="inherit" />
                            Aguarde...
                            </>
                        ):(
                            <>
                            <MdAddShoppingCart
                                size={24}
                            />
                            Colocar no carrinho
                            </>
                        )}
                    
                    </Button>
                </Container>

            </div>
        </div>
    )
}
