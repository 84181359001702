import { createStore, applyMiddleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./data/rootReducer";
import rootSaga from "./data/rootSaga";

/**Types State dos modulos */
import { ItemState } from "../provider/item/types";
import { ItemCartState } from "../provider/itemCart/types";
import { UsuarioState } from "../provider/usuario/types";
import { SessaoState } from "../provider/start/types";
import { PedidoState } from "../provider/pedido/types";
import { PedidoItensState } from "../provider/pedidoItens/types";
import { MeioPagState } from "../modulos/meioPag/provider/types";
import { CategoriaState } from "../modulos/categorias/provider/types";
import { EstoqueState } from "../modulos/estoque/provider/types";
import { KitPromocaoState } from "../modulos/kitpromocao/provider/types";
import { TipoEntregaState } from "../modulos/tipoEntregas/provider/types";


/**State geral para exibir os types na view */
export interface ApplicationState {
    catalogo:ItemState,
    itemCart:ItemCartState,
    user:UsuarioState,
    start:SessaoState,
    pedido:PedidoState,
    pedidoItens:PedidoItensState,
    meioPag:MeioPagState,
    categoria:CategoriaState,
    estoque:EstoqueState,
    kitpromocao:KitPromocaoState,
    tipoentrega:TipoEntregaState
}

const sagaMiddleware = createSagaMiddleware()

const store:Store <ApplicationState> = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;