import React from "react";

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Menu,
    MenuItem,
} from "@material-ui/core";
import {
    IoMdSearch,
    IoMdAddCircleOutline,
    IoMdFunnel,
    IoMdMore,
    IoMdClose,
    IoLogoWhatsapp,
    IoMdHelp
} from "react-icons/io";
import Utils from "../../services/utils";
import useStyles from "./styles";
interface ButtonToobarList {
    title: string;
    label: string;
    handle(): void;
}
interface TypesToolbarList {
    title: string;
    showInputSearch: boolean;
    showButtonNovo: boolean;
    showButtonSearch: boolean;
    showButtonMenu: boolean;
    showButtonClose: boolean;
    showButtonWhats: boolean;
    buttonNovo?: ButtonToobarList;
    buttonSearch?: ButtonToobarList;
    buttonClose?: ButtonToobarList;
    buttonMenu?: {
        title: string;
        label: string;
        menuItens: MenuItemToobarList[];
    };
    inputSearch?: {
        handleOnChange(value: any): void;
        placeholder: string;
    };
}
interface MenuItemToobarList {
    title: string;
    handle(): void;
}
const util = new Utils();

export default function ToolbarList(props: TypesToolbarList) {
    const {
        title,
        showInputSearch,
        showButtonNovo,
        showButtonMenu,
        showButtonSearch,
        showButtonClose,
        showButtonWhats,
        buttonNovo,
        buttonSearch,
        buttonMenu,
        buttonClose,
        inputSearch,
    } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItens = buttonMenu?.menuItens as Array<MenuItemToobarList>;
    const renderMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isOpenMenu}
            onClose={handleMenuClose}
        >
            {menuItens.map((item, i) => (
                <MenuItem
                    key={i}
                    onClick={() => {
                        item.handle();
                        handleMenuClose();
                    }}
                >
                    {item.title}
                </MenuItem>
            ))}
        </Menu>
    );
    return (
        <AppBar position="static" color="primary" title={title}>
            <Toolbar>
                <img
                    src={"../branding.png"}
                    alt="AkiSuaFeira"
                    className={classes.brand}
                />
                <Typography
                    variant="h6"
                    noWrap
                    color="inherit"
                    className={classes.title}
                >
                    {title}
                </Typography>
                {showInputSearch && (
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <IoMdSearch />
                        </div>
                        <InputBase
                            onChange={(event) =>
                                inputSearch?.handleOnChange(event.target.value)
                            }
                            placeholder={inputSearch?.placeholder}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                )}
                <div className={classes.containerButtons}>
                {showButtonNovo && (
                    <IconButton
                        aria-label={buttonNovo?.label}
                        title={buttonNovo?.title as string}
                        aria-haspopup="true"
                        onClick={() => buttonNovo?.handle()}
                        color="inherit"
                        edge="end"
                    >
                        <IoMdAddCircleOutline size={25} />
                    </IconButton>
                )}
                {showButtonSearch && (
                    <IconButton
                        aria-label={buttonSearch?.label}
                        title={buttonSearch?.title as string}
                        aria-haspopup="true"
                        onClick={() => buttonSearch?.handle()}
                        color="inherit"
                        edge="end"
                    >
                        <IoMdFunnel size={25} />
                    </IconButton>
                )}


                {showButtonWhats && (
                    <IconButton
                        aria-label="WhatsApp"
                        title="Pedir pelo WhatsApp"
                        aria-haspopup="true"
                        href="https://wa.me/557591182584"
                        color="inherit"
                        edge="end"
                    >
                        <IoLogoWhatsapp size={25} />
                    </IconButton>
                )}

                {showButtonMenu && (
                    <>
                        <IconButton
                            aria-label={buttonMenu?.label}
                            title={buttonMenu?.title as string}
                            aria-haspopup="true"
                            onClick={handleMenuOpen}
                            color="inherit"
                            edge="end"
                        >
                            <IoMdMore size={25} />
                        </IconButton>
                        {renderMenu()}
                    </>
                )}
                {showButtonClose && (
                    <IconButton
                        aria-label={buttonClose?.label}
                        title={buttonClose?.title as string}
                        aria-haspopup="true"
                        onClick={() => buttonClose?.handle()}
                        color="inherit"
                        edge="end"
                    >
                        <IoMdClose size={25} />
                    </IconButton>
                )}
               
                </div>
            </Toolbar>
        </AppBar>
    );
}
