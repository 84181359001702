import React, { useCallback } from "react";
import { Typography, Paper, AppBar, Toolbar, Container, makeStyles, createStyles, IconButton, Grid, TextField, Button, FormControl, FilledInput, InputAdornment, InputLabel } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { MdArrowBack, MdCheck } from "react-icons/md";
import { Usuario, UsuarioState, UsuarioDispatch } from "../../provider/usuario/types";
import TextPassword from "../../components/textPassword";
import Backdrop from "../../components/backdrop/backdrop";
import Utils from "../../services/utils";
const utils = new Utils();
const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
        height: "100%",
    },
    content: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    title: {
        flexGrow: 1,
    }

}))

interface PassWordProps {
    onClose(): void,
    usuarioState: UsuarioState,
    usuarioActions: UsuarioDispatch
}
interface State {
    data: {
        atualPassword: string;
        password: string;
        newPassword: string;
        repeatPassword: string;
    };
    msgError: string;
    error: boolean;
}
function PassWordView(props: PassWordProps) {
    const { onClose, usuarioActions, usuarioState } = props;
    const [alteradoSenha, setAlteradoSenha] = React.useState(false);
    const [state, setState] = React.useState<State>({
        data: {
            atualPassword: usuarioState.row.senha,
            password: "",
            newPassword: "",
            repeatPassword: "",
        },
        error: false,
        msgError: "",
    })
    const classes = useStyles();


    React.useEffect(() => {
        //se alterou a senha de um usuario ja existente
        //a revisao será alterada o o efect sera chamado
        if (alteradoSenha)
            onClose();
    }, [usuarioState.row._rev])

    React.useEffect(() => {
        if (alteradoSenha) {
            //console.log("alterado a senha", usuarioState.row);
            /*se usuario ja existir, salva
            se nao, fecha a janela*/
            if (usuarioState.row._id) {
                usuarioActions.salvar(usuarioState.row);
            } else {
                onClose();
            }
        }
    }, [usuarioState.row.senha]);

    const validarSenha = (values: State) => {
        if (usuarioState.row.senha === "") {
            let senha = utils.getHashSha256(values.data.newPassword);
            usuarioActions.setPassword(senha);
            setAlteradoSenha(true);
        } else {
            let oldSenha = utils.getHashSha256(values.data.password);
            if (oldSenha === usuarioState.row.senha) {
                let newSenha = utils.getHashSha256(values.data.newPassword);
                usuarioActions.setPassword(newSenha);
                setAlteradoSenha(true);
            } else {
                setState(state => ({ ...state, msgError: "Senha inválida!", error: true }));
            }
        }
    }

    const validate = (values: any) => {
        console.log('validacao',values);
        const errors: any = {data:{}};
        if (values.data.atualPassword !== "") {
            if (values.data.password === "") {
                errors.data.password = "Informe sua senha atual.";
            }
        }
        if (values.data.newPassword === "") {
            errors.data.newPassord = "Informe a sua nova senha."
        }
        if (values.data.newPassword !== "" && (values.data.repeatPassword !== values.data.newPassword)) {
            errors.data.repeatPassword = "A confirmação da senha não confere.";
        }
        return errors;
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Backdrop show={usuarioState.loading ? true : false} />
                <AppBar position="static" color={"primary"}>
                    <Toolbar>
                        <IconButton
                            onClick={onClose}
                            color="inherit"
                            edge="end"
                        >
                            <MdArrowBack size={25} />
                        </IconButton>

                        <Typography className={classes.title} variant="h6">Alterar Senha</Typography>
                    </Toolbar>
                </AppBar>
                <Container style={{ paddingTop: '5px' }}>


                    <Form
                        onSubmit={validarSenha}
                        initialValues={state}
                        validate={validate}
                    >
                        {({
                            handleSubmit,
                            pristine,
                            submitting,
                            values,
                            form,
                            errors,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid
                                    container
                                    justify={"space-between"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}

                                >
                                    <Grid item xs={12}>
                                        {usuarioState.row.senha !== "" && (
                                            <Field
                                                name="data.password"
                                            >
                                                {props => (
                                                    <TextPassword
                                                        label="Senha atual"
                                                        value={props.input.value}
                                                        handleChange={props.input.onChange}
                                                        error={props.meta.error && props.meta.touched}
                                                        helperText={props.meta.touched ? props.meta.error : null}
                                                    />
                                                )}

                                            </Field>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="data.newPassword"
                                        >
                                            {props => (
                                                <TextPassword
                                                    label="Nova Senha"
                                                    value={props.input.value}
                                                    handleChange={props.input.onChange}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field name="data.repeatPassword">
                                            {props => (
                                                <TextPassword
                                                    label="Confirmar Senha"
                                                    value={props.input.value}
                                                    handleChange={props.input.onChange}
                                                    error={props.meta.error && props.meta.touched}
                                                    helperText={props.meta.error}
                                                />

                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant={
                                                "contained"
                                            }
                                            startIcon
                                            color={"primary"}
                                            fullWidth={true}
                                            type="submit"
                                            disabled={
                                                submitting || pristine || errors.data
                                            }
                                        >
                                            <MdCheck
                                                size={24}
                                            />
                                    Confirma Alterar Senha
                                </Button>
                                    </Grid>
                                </Grid>
                                {console.log('errors',errors)}
                            </form>
                        )}
                    </Form>







                </Container>
            </div>
        </div>
    )
}

export default PassWordView